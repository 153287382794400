import React, { useEffect, useState } from "react";
import { Col, Row, Table, Tag } from "antd";

import moment from "moment";
import { FormatNumber, MaskFormat } from "../../ValueObjects";

export default function TabDetalheRelacaoPedido({ dadosPedido }) {
    const [listaPedidos, setListaPedidos] = useState([]);

    useEffect(() => {
        if (dadosPedido) {
            let listaTemp = dadosPedido.map((item, index) => ({
                ...item,
                key: index
            }));

            setListaPedidos(listaTemp);
        }
    })

    return (
        <div>
            <Row>
                <Col>
                    {listaPedidos.length > 0 ? <b>Nota emitida a partir dos Pedidos:</b> : <b>Nota emitida a partir do Pedido:</b>}
                </Col>
            </Row>
            <div className="tabela">
                <Table dataSource={listaPedidos} columns={
                    [
                        {
                            title: 'Status',
                            align: 'center',
                            width: 125,
                            render: ({ stp_descricao, stp_corstatus }) => (
                                <div>
                                    <Tag color={stp_corstatus} className="w-100">
                                        <b>{stp_descricao}</b>
                                    </Tag>
                                </div>
                            )
                        },
                        {
                            title: 'Número',
                            align: 'center',
                            width: 100,
                            render: ({ ped_numero }) => (
                                <Tag color="processing" className="w-75">
                                    <b>{ped_numero}</b>
                                </Tag>
                            )
                        },
                        {
                            title: 'Data Emissão',
                            align: 'center',
                            width: 100,
                            render: ({ ped_datainclusao }) => (
                                <div>
                                    <b>{moment(ped_datainclusao).format('DD/MM/YYYY')}</b>
                                </div>
                            )
                        },
                        {
                            title: 'Operação',
                            align: 'center',
                            width: 100,
                            render: ({ ope_descricao }) => (
                                <div>
                                    <b>{ope_descricao}</b>
                                </div>
                            )
                        },
                        {
                            title: 'Cliente',
                            width: 400,
                            render: ({ pes_nome }) => (
                                <div>
                                    <b>{pes_nome}</b>
                                </div>
                            )
                        },
                        {
                            title: 'CPF/CNPJ',
                            width: 160,
                            render: ({ pef_cpf, pej_cnpj }) => (
                                <div>
                                    <b>{!!pef_cpf ? MaskFormat(pef_cpf, 'Não Informado', true) : MaskFormat(pej_cnpj, 'Não Informado', true)}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Vendedor',
                            width: 400,
                            render: ({ ven_nome }) => (
                                <div>
                                    <b>{ven_nome || "Não Informado"}</b>
                                </div>
                            )
                        },
                        {
                            title: "Total (R$)",
                            align: 'right',
                            width: 100,
                            render: ({ ped_valortotal }) => (
                                <div>
                                    <b>{FormatNumber(ped_valortotal, true)}</b>
                                </div>
                            )
                        },
                    ]
                }
                    expandable={{
                        expandedRowRender: (record) => (
                            <div>
                                <Row gutter={[8, 0]}>
                                    <Col><b>Endereço do Cliente: </b></Col>
                                    {record.pes_endereco}, {record.pes_nroestabelecimento}, {record.pes_bairro}
                                    <Col style={{ marginTop: '-1px' }}><b> | </b></Col>
                                    {record.pes_cep}, {record.pes_cidade} - {record.pes_uf}
                                </Row>
                            </div>
                        ),
                    }} 
                />
            </div>
        </div>
    )
}