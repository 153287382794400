import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Select, notification, Modal,Checkbox } from "antd";
import { ExclamationCircleOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import { InputPhone, SelectPaginacao } from "../../../components";
import SelectPaginado from "../../selectPaginado";
import { selectPaginadoActions } from "../../../actions";
import { useStateValue } from "../../../state";
import { retornaSoNumeros } from "../../../services/funcoes";

export default function ModalTelefone({ form, listaTelefones, exibirModalTelefone, fecharModalTelefone, isEstrangeiro }) {
    const [dispatch] = useStateValue();
    const [numTelefone, setNumTelefone] = useState('');

    const handleInputChange = (e) => {
        var valorAnterior = numTelefone;
        var valorNovo = e.target.value;
        //var isValid = /^(\+)?\d*$/.test(valorNovo);//verifica se numero possui '+' somente no inicio e o restante são numeros
        var isValid = /^\d*$/.test(valorNovo);//verifica se possui somente numeros
        if (isValid === true) {
            form.setFieldsValue({ psc_numero: valorNovo }); // Atualizar o valor quando válido
            setNumTelefone(valorNovo);
        } else {
            // Disparar a notificação
            notification.warn({ message: 'Somente "+" no início e números são permitidos.' });
            // Retorna ao estado anterior ao digito não permitido
            form.setFieldsValue({ psc_numero: valorAnterior });
            setNumTelefone(valorAnterior);
        }
    };

    useEffect(() => {
        if (exibirModalTelefone && !!listaTelefones) {
            var lista = [];
            if (!!listaTelefones.tpt_id) {
                lista.push({ name: "tpt_id", campo: "Codigo", value: listaTelefones.tpt_id });
            }
            if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
        }
    }, [exibirModalTelefone]);

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                form.resetFields();
                fecharModalTelefone();
            }
        });
    };

    const onFinish = async values => {
        let indice = form.getFieldValue().indice;
        let lista = [...listaTelefones.dadosTelefone];

        let listaTpTelefone = await listarTpTelefone(form.getFieldValue().tpt_id);
        values.tpt_descricao = listaTpTelefone[0].tpt_descricao;
        values.psc_ativo = true;
        values.psc_numero = retornaSoNumeros(values.psc_numero);
        if (!!indice) {
            values.psc_principal = lista.filter((psc) => (psc.indice === indice))[0].psc_principal;
        }
        values.tpt = listaTpTelefone[0];
        if (indice >= 0) {
            lista[indice] = values;
        } else {
            let verificaOcupacaoAdd = lista.filter(o => o.psc_numero === form.getFieldValue().psc_numero);
            if (verificaOcupacaoAdd.length > 0) {
                fecharModalTelefone();
                notification.info({ message: 'Telefone já adicionado!' });
            } else {
                if (lista.length > 0) {
                    values.psc_principal = false;
                } else {
                    values.psc_principal = true;
                }
                lista.push(values);
            }
        }
        listaTelefones.setDadosTelefone(lista);
        form.resetFields();
        setNumTelefone();
        fecharModalTelefone();
    };

    async function listarTpTelefone(tpt_id) {
        let req = await api.get(`TipoTelefone/Listar?Codigo=${tpt_id}`);
        if (req.status === 200 && !!req.data) {
            return req.data.items;
        }
        return {};
    }

    const inputRef = React.createRef();
    useEffect(() => {
        setTimeout(() => {
            if (exibirModalTelefone && !!inputRef.current) {
                inputRef.current.focus({ cursor: 'end', })
            };
        }, 500);
    }, [exibirModalTelefone])

    return (
        <Modal centered
            title="Cadastro de Telefones"
            visible={exibirModalTelefone}
            onCancel={() => {
                onCloseModal();
            }}
            onOk={() => form.submit()}
            okText={
                <>
                    <SaveOutlined /> Salvar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
            destroyOnClose={true}
        >
            <Form layout="vertical" name="formTelefone" form={form} onFinish={onFinish}>
                <Row gutter={[8, 0]}>
                    <Form.Item name="psc_id" hidden />
                    <Form.Item name="psc_ativo" hidden />
                    <Form.Item name="psc_principal" hidden />
                    {!isEstrangeiro && <Col xs={24} sm={7} md={8} lg={8} xl={8}>
                        <InputPhone label="Número" name="psc_numero" rules={[{ required: true, message: 'Informe o Número do Telefone' }]} />
                    </Col>}
                    {isEstrangeiro && <Col xs={24} sm={7} md={8} lg={8} xl={8}>
                        <Form.Item label="Número" name="psc_numero" rules={[{ required: true, message: 'Informe o Número do Telefone' }]}>
                            <Input placeholder="Informe o Número do Telefone"
                                onChange={handleInputChange}
                            />
                        </Form.Item>
                    </Col>}
                    <Col xs={24} sm={10} md={8} lg={8} xl={8}>
                        <Form.Item label="Tipo de Telefone" name="tpt_id" rules={[{ required: true, message: 'Selecione um Tipo' }]}>
                            <SelectPaginacao url="TipoTelefone/Listar" placeholder="Selecione um tipo de telefone" form={form} nameValue="tpt_id" nameLabel="tpt_descricao"
                                conteudo={
                                    tp => (<Select.Option value={tp.tpt_id} key={tp.tpt_id}>{tp.tpt_descricao}</Select.Option>)
                                } />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={7} md={8} lg={8} xl={8}>
                        <Form.Item label="Ramal" name="psc_ramal">
                            <Input placeholder="Informe o ramal" />
                        </Form.Item>
                    </Col>
                    {/* <Col xs={24} sm={10} md={7} lg={7} xl={7}>
                        <Form.Item name="psc_principal" valuePropName="checked">
                            <Checkbox>Principal?</Checkbox>
                        </Form.Item>
                    </Col> */}
                    {/* <Col xs={24} sm={10} md={7} lg={7} xl={7}>
                        <Form.Item name="psc_ativo" valuePropName="checked">
                            <Checkbox>Ativo?</Checkbox>
                        </Form.Item>
                    </Col> */}
                    <Col xs={24} sm={10} md={7} lg={7} xl={7}>
                        <Form.Item name="psc_whatsapp" valuePropName="checked">
                            <Checkbox>Enviar Whatsapp?</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Observação" name="psc_observacao">
                            <Input.TextArea placeholder="Informe uma observação" />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item name="indice" hidden />
            </Form>
        </Modal>
    );
}