import { CloseOutlined, ExclamationCircleOutlined, MinusOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Col, Form, Modal, Row } from "antd";
import { drawerActions, listagemActions } from "../../actions";
import { DrawerNav } from "../../components";
import ManutencaoComponentesFrete from "./manutencao";
import { useStateValue } from "../../state";
import { useState } from "react";

export default function DrawerComponentesFrete({ editando }) {

    const [form] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);

    if (manutencao.dados !== null && editando.editando) {
        form.setFieldsValue(manutencao.dados);
    }

    function fecharDrawer() {
        form.resetFields();
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: '', filtro: '', ordem: '-cmf_id' } })

        editando.setEditando(false);
    }


    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    return (

        <DrawerNav title="Componentes de Frete"
            width="70%"
            visible={ui.showDrawer}
            limparAoEditar={true}
            fecharDrawer={() => { fecharDrawer() }}
            closeIcon={<MinusOutlined />}

            footer={
                <div>
                    <Row align='middle' justify='end' gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size='large' htmlType="button">
                                Cancelar
                            </Button>
                        </Col>

                        <Col>
                            <Button onClick={() => form.submit()} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <ManutencaoComponentesFrete formulario={form} carregando={setCarregando} aoSalvar={fecharDrawer} editando={editando} />
        </DrawerNav>
    )
}