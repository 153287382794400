import React, { useEffect, useState } from "react";
import { Col, Descriptions, Divider, Form, Row, Table, Tabs } from "antd";

import api from "../../services/api";
import { TabDetalheAutorCte, TabDetalheDocsCte } from './tabPage';
import { FormatNumber } from "../../ValueObjects";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export default function DetalheCte({ dadosModal, listaTipoCte, listaTomador, listaModal }) {
    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [listaTipoServico, setListaTipoServico] = useState([]);
    const [listaSerieFiscal, setListaSerieFiscal] = useState([]);
    const [listaUnidade, setListaUnidade] = useState([]);
    const [listaComponentes, setListaComponentes] = useState([]);

    useEffect(() => {
        carregarDados();
    }, [])

    function carregarDados() {
        api.get(`SerieFiscal/Listar?SomenteAtivos=true&pageSize=1000`).then(
            res => {
                let retorno = res.data.items;
                setListaSerieFiscal(retorno);
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )

        api.get(`ComponentesFrete/Listar`).then(
            (res) => {
                if (res.status === 200) {
                    setListaComponentes(res.data.items);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )

        api.get('Enum/Listar?nome=tpServ').then(
            (res) => {
                if (res.status === 200) {
                    setListaTipoServico(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )

        api.get(`Enum/Listar?nome=cUnid`).then(
            (res) => {
                if (res.status === 200) {
                    setListaUnidade(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )
    }

    function retornaDescricaoTipoCte(cte_tipocte) {
        if (!!listaTipoCte) {
            let tipo = listaTipoCte.filter((tipo) => (tipo.key === cte_tipocte))[0];
            return !!tipo ? tipo.value : "";
        }
    }

    function retornaDescricaoTomador(cte_tomador) {
        if (!!listaTomador) {
            let tomador = listaTomador.filter((toma) => (toma.key === cte_tomador))[0];
            return !!tomador ? tomador.value : "";
        }
    }

    function retornaDescricaoModal(cte_modal) {
        if (!!listaModal) {
            let modal = listaModal.filter((modal) => (modal.key === cte_modal))[0];
            return !!modal ? modal.value : "";
        }
    }

    function retornaDescricaoTipoServico(cte_tiposervico) {
        if (!!listaTipoServico) {
            let servico = listaTipoServico.filter((serv) => (serv.key === cte_tiposervico))[0];
            return !!servico ? servico.value : "";
        }
    }

    function retornaUnidade(cic_cunit) {
        if (!!listaUnidade) {
            let unid = listaUnidade.filter((unid) => (unid.key === cic_cunit))[0];
            return !!unid ? unid.value : "";
        }
    }

    function retornaComponente(cmf_id) {
        if (!!listaComponentes) {
            let comp = listaComponentes.filter((comp) => (comp.cmf_id === cmf_id))[0];
            return !!comp ? comp.cmf_descricao : "";
        }
    }

    return (
        <div>
            <Row align="middle">
                <Col>
                    <Descriptions size="small">
                        <Descriptions.Item label="Tipo CT-e">
                            <b> {retornaDescricaoTipoCte(dadosModal.cte_tipocte)} </b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Tipo Serviço">
                            <b> {retornaDescricaoTipoServico(dadosModal.cte_tiposervico)} </b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Modal">
                            <b> {retornaDescricaoModal(dadosModal.cte_modal)} </b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row align="middle">
                <Col>
                    <Descriptions size="small">
                        <Descriptions.Item label="CT-e Globalizado">
                            <b> {dadosModal.cte_indglobalizado === 1 ? 'Sim' : 'Não'} </b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Tomador">
                            <b> {retornaDescricaoTomador(dadosModal.cte_tomador)} </b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Produto Predominante">
                            <b> {dadosModal.cte_prodpredominante} </b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row align="middle">
                <Col>
                    <Descriptions size="small">
                        <Descriptions.Item label="Mun. Inicial">
                            <b> {dadosModal.descricao_muninicio + " - " + dadosModal.sigla_ufinicio} </b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Mun. Envio">
                            <b> {dadosModal.descricao_munenvio + " - " + dadosModal.sigla_ufenvio} </b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Mun. Final">
                            <b> {dadosModal.descricao_munfinal + " - " + dadosModal.sigla_uffinal} </b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row align="middle">
                <Col>
                    <Descriptions size="small">
                        <Descriptions.Item label="Observação">
                            <b> {dadosModal.cte_observacao ?? "Não Informado"} </b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <div className="m-t-8">
                <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                    <Tabs.TabPane tab="Documentos" key="1">
                        <TabDetalheDocsCte docs={dadosModal.infodocumentos} listaSerieFiscal={listaSerieFiscal} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Autores" key="2">
                        <TabDetalheAutorCte autores={dadosModal.autores} listaAutor={listaTomador} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Informações de Carga" key="3">
                        <div className="tabela">
                            <Table dataSource={dadosModal.infocarga} columns={
                                [
                                    {
                                        title: 'Unidade',
                                        align: 'center',
                                        render: ({ cic_cunit }) => (
                                            <b>{retornaUnidade(cic_cunit)}</b>
                                        )
                                    },
                                    {
                                        title: 'Tipo de Medida',
                                        align: 'center',
                                        render: ({ cic_tipomedida }) => (
                                            <b>{cic_tipomedida}</b>
                                        )
                                    },
                                    {
                                        title: 'Quantidade',
                                        align: 'center',
                                        render: ({ cic_qntcarga }) => (
                                            <b>{cic_qntcarga}</b>
                                        )
                                    },
                                ]
                            } pagination={false}
                                locale={{
                                    emptyText: (
                                        <Row>
                                            <Col span={24}>
                                                <Divider orientation="center">
                                                    <ExclamationCircleOutlined /> Não há Dados
                                                </Divider>
                                            </Col>
                                        </Row>
                                    )
                                }}
                            />
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Componentes de Frete" key="4">
                        <div className="tabela">
                            <Table dataSource={dadosModal.componentesfrete} columns={
                                [
                                    {
                                        title: 'Tipo de Componente',
                                        align: 'center',
                                        render: ({ cmf_id }) => (
                                            <b>{retornaComponente(cmf_id)}</b>
                                        )
                                    },
                                    {
                                        title: 'Valor (R$)',
                                        align: 'center',
                                        render: ({ ccf_valor }) => (
                                            <b>R$ {FormatNumber(ccf_valor, true)}</b>
                                        )
                                    }
                                ]
                            } pagination={false}
                                locale={{
                                    emptyText: (
                                        <Row>
                                            <Col span={24}>
                                                <Divider orientation="center">
                                                    <ExclamationCircleOutlined /> Não há Dados
                                                </Divider>
                                            </Col>
                                        </Row>
                                    )
                                }}
                            />
                        </div>
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div>
    )
}