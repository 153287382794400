import React, { useEffect, useState } from "react";
import { Row, Col, Form, Typography, Divider, Select, Descriptions } from "antd";
import moment from 'moment';

import SelectPaginado from '../../components/selectPaginado';
import { Data, InputPreco } from "../../components";
import { FormatNumber } from "../../ValueObjects";
import { formatarCnpj, formatarCpf, isObjetoDiffVazio } from "../../services/funcoes";
import { selectPaginadoActions } from "../../actions";
import { useStateValue } from "../../state";

export default function TabNotaFiscal({ form, dadosNota, dadosRemetente }) {

    const [{ manutencao }, dispatch] = useStateValue();

    useEffect(() => {
        if (isObjetoDiffVazio(dadosNota)) {
            form.setFieldsValue(dadosNota);
            form.setFieldsValue({ ntf_dataentrada: moment(new Date()) });
        }
    }, [dadosNota]);

    useEffect(() => {
        if (!!dadosRemetente?.for_id) {
            let lista = [];

            if (!!dadosRemetente?.for_id)
                lista.push({ name: "orc_id", campo: "IdFornecedor", value: dadosRemetente?.for_id });

            if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: {itens: lista} });

        }
    }, [dadosRemetente])
    
    return (
        <div>
            <Row gutter={[8, 0]}>
                <Col xs={24} sm={5} md={4} lg={4} xl={4} xxl={3}>
                    <Data label="Data de Entrada" name="ntf_dataentrada" rules={[{ required: true, message: 'Informe a Data de Entrada' }]} />
                </Col>
                <Col xs={24} sm={13} md={14} lg={14} xl={14} xxl={15}>
                    <Form.Item label="Operação Fiscal" name="ope_id" rules={[{ required: true, message: 'Informe a Operação' }]}>
                        <SelectPaginado url="OperacaoFiscal/ListarOperacaoFiscal?EntradaSaida=0&SomenteAtivo=true" placeholder="Selecione a Operação Fiscal" form={form} name="ope_id" conteudo={
                            op => (<Select.Option value={op.ope_id} key={op.key}>{op.ope_descricao}</Select.Option>)
                        } />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <Form.Item label="Ordem de Compra">
                        <SelectPaginado url={`OrdemCompra/Listar`} placeholder="Selecione uma Ordem de Compra" form={form} name="orc_id" conteudo={
                            oc => (<Select.Option value={oc.orc_id} key={oc.orc_id}>{oc.pes_nome}</Select.Option>)
                        } />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 0]} align="middle" className="m-t-16">
                <Col>
                    Data Emissão: <b>{moment(dadosNota.find(obj => obj.nome === 'ntf_dataemissao')?.valor).format('DD/MM/YYYY')}</b>
                </Col>
                <Col>
                    Chave NF-e: <b>{dadosNota.find(obj => obj.nome === 'ntf_chavenfe')?.valor}</b>
                </Col>
                <Col>
                    Nº da Nota: <b>{dadosNota.find(obj => obj.nome === 'ntf_numero')?.valor}</b>
                </Col>
                <Col>
                    Série: <b>{dadosNota.find(obj => obj.nome === 'ser_numero')?.valor}</b>
                </Col>
            </Row>
            <Row gutter={[8, 16]} className="m-t-16">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-car.png").default} alt="Fornecedor" /> Fornecedor
                    </Typography.Title>
                    <Divider orientation="left" />
                </Col>
                <Col span={24}>
                    <Row gutter={[16, 0]}>
                        <Col>
                            Nome: <b>{dadosRemetente?.[0]?.find(obj => obj.nome === 'pes_nome')?.valor}</b>
                        </Col>
                        <Col>
                            CPF/CNPJ: <b>{(!!dadosRemetente?.[0]?.find(obj => obj.nome === 'cnpjcpf') && dadosRemetente?.[0]?.find(obj => obj.nome === 'cnpjcpf')?.valor.length > 11 ? 
                            formatarCnpj(dadosRemetente?.[0]?.find(obj => obj.nome === 'cnpjcpf')) : formatarCpf(dadosNota.find(obj => obj.nome === 'cnpjcpf')?.valor))}</b>
                        </Col>
                        <Col>
                            Inscrição Estadual: <b>{dadosRemetente?.[0]?.find(obj => obj.nome === 'pej_inscricaoestadual')?.valor}</b>
                        </Col>
                        <Col>
                            Endereço: <b>{dadosRemetente?.[0]?.find(obj => obj.nome === 'log_logradouro')?.valor}, {dadosRemetente?.[0]?.find(obj => obj.nome === 'pee_numero')?.valor},
                            {dadosRemetente?.[0]?.find(obj => obj.nome === 'bai_bairro')?.valor}, {dadosRemetente?.[0]?.find(obj => obj.nome === 'cid_descricao')?.valor} - 
                            {dadosRemetente?.[0]?.find(obj => obj.nome === 'est_sigla')?.valor}</b>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={[8, 16]} className="m-t-16">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-bill.png").default} alt="Impostos" /> Impostos
                    </Typography.Title>
                    <Divider orientation="left" />
                </Col>
                <Col span={24}>
                    <Row gutter={[16, 0]}>
                        <Col>
                            Base de Cálculo ICMS: <b>{dadosNota.find(objeto => objeto.nome === 'vBaseCalculoIcms')?.valor}</b>
                        </Col>
                        <Col>
                            Valor ICMS (R$): <b>{FormatNumber(dadosNota.find(objeto => objeto.nome === 'vValorIcms')?.valor, true)}</b>
                        </Col>
                        <Col>
                            Base de Cálculo ICMSST: <b>{dadosNota.find(objeto => objeto.nome === 'vBaseCalculoIcmsSt')?.valor}</b>
                        </Col>
                        <Col>
                            Valor ICMS-ST (R$): <b>{FormatNumber(dadosNota.find(objeto => objeto.nome === 'vValorIcmsSt')?.valor, true)}</b>
                        </Col>
                        <Col>
                            Valor PIS (R$): <b>{FormatNumber(dadosNota.find(objeto => objeto.nome === 'vValorPis')?.valor, true)}</b>
                        </Col>
                        <Col>
                            Valor COFINS (R$): <b>{FormatNumber(dadosNota.find(objeto => objeto.nome === 'vValorCofins')?.valor, true)}</b>
                        </Col>
                        <Col>
                            Valor IPI (R$): <b>{FormatNumber(dadosNota.find(objeto => objeto.nome === 'vValorIpi')?.valor, true)}</b>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={[8, 16]} className="m-t-16">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-saida.png").default} alt="Totais da Nota" /> Totais da Nota
                    </Typography.Title>
                    <Divider orientation="left" />
                </Col>
                <Col span={24}>
                    <Row gutter={[16, 0]}>
                        <Col>
                            Valor Total dos Produtos (R$): <b>{FormatNumber(dadosNota.find(objeto => objeto.nome === 'vValorTotalProdutos')?.valor, true)}</b>
                        </Col>
                        <Col>
                            Valor do Frete (R$): <b>{FormatNumber(dadosNota.find(objeto => objeto.nome === 'vValorFrete')?.valor, true)}</b>
                        </Col>
                        <Col>
                            Valor do Seguro (R$): <b>{FormatNumber(dadosNota.find(objeto => objeto.nome === 'vValorSeguro')?.valor, true)}</b>
                        </Col>
                        <Col>
                            Descontos (R$): <b>{FormatNumber(dadosNota.find(objeto => objeto.nome === 'vDesconto')?.valor, true)}</b>
                        </Col>
                        <Col>
                            Outras Despesas (R$): <b>{FormatNumber(dadosNota.find(objeto => objeto.nome === 'vOutrasDespesas')?.valor, true)}</b>
                        </Col>
                        <Col>
                            Valor Total da NF-e (R$): <b>{FormatNumber(dadosNota.find(objeto => objeto.nome === 'vValorTotalNfe')?.valor, true)}</b>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={[8, 16]} className="m-t-16">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-dados.png").default} alt="Informações Fora da Nota" /> Informações Fora da Nota
                    </Typography.Title>
                    <Divider orientation="left" />
                </Col>
                <Col span={24}>
                    <Row align="middle" gutter={[8, 0]}>
                        <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={5}>
                            <InputPreco name={"freteForaNota"} label={"Valor do Frete Fora da Nota (R$)"} />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={5}>
                            <InputPreco name={"icmsstForaNota"} label={"Valor do ICMS ST Fora da Nota (R$)"} />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={5}>
                            <InputPreco name={"outrasDespesasForaNota"} label={"Outras Despesas Fora da Nota (R$)"} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}