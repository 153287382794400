import { useEffect, useState } from "react";
import { BreadcrumbIncluirDados, Editar, Excluir, PesquisaOrdenacao, TabelaDados } from "../../components";
import { Col, Form, Modal, notification, Row } from "antd";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import api from "../../services/api";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useStateValue } from "../../state";
import DrawerComponentesFrete from "./drawer";
import roundValue from "../../ValueObjects/roundValue";

export default function ComponentesFrete() {

    const [{ ui, manutencao, listagem }, dispatch] = useStateValue();
    const [editando, setEditando] = useState(false);

    useEffect(() => dispatch({ type: listagemActions.CHANGE, data: { ordem: '+cmf_id' } }), [listagemActions]);

    function excluirRegistro(dados) {
        api.delete(`ComponentesFrete/Excluir?id=${dados.cmf_id}`).then(
            (res) => {
                notification.success({ message: `Sucesso!`, description: `Componente de Frete excluído com sucesso!` });
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
            }).catch(
                (erro) => {
                    notification.error({ message: "erro", description: `${erro}` })
                }
            ).finally(
                () => {
                    dispatch({ type: listagemActions.CHANGE, data: { ordem: '-cmf_id' } });
                });
    }

    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir o componente de Frete?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(dados);
            }
        });
    };

    function editandoConfig(dados) {
        setEditando(true);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    };


    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <PesquisaOrdenacao opcoesOrdenacao={[
                { label: "Nome A - Z", value: "+cmf_descricao" },
                { label: "Nome Z - A", value: "-cmf_descricao" },
                { label: "Código Crescente", value: "+cmf_id" },
                { label: "Código Decrescente", value: "-cmf_id" }
            ]} />
            <div className="tabela">
                <TabelaDados url="ComponentesFrete/Listar"
                    colunas={[
                        {
                            title: 'Descrição Componente',
                            width: 300,
                            render: ({ cmf_descricao }) => (
                                <div>
                                    <b>{cmf_descricao}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Percentual do componente',
                            width: 100,
                            render: ({ cmf_percentual }) => (
                                <div>
                                    <b>{roundValue(cmf_percentual)}%</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Componente principal',
                            width: 100,
                            render: ({ cmf_principal }) => (
                                <div>
                                    <b>{cmf_principal ? 'Sim' : 'Não'}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            key: 'x',
                            align: 'center',
                            width: 45,
                            fixed: 'right',
                            render: (dados) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            < Editar
                                                onClick={() => {
                                                    editandoConfig(dados);
                                                }}
                                                icon={<EditOutlined />}
                                            />
                                        </Col>
                                        <Col>
                                            < Excluir icon={<DeleteOutlined />} onClick={() => { modalExcluir(dados) }}/>
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]} />
            </div>
            <DrawerComponentesFrete teste={true} editando={{ editando, setEditando }} />
        </div>
    );
}

