import React, { useEffect, useState } from "react";
import { Form, Col, Input, Select, notification, Upload, Button, message, Tooltip, InputNumber } from "antd";

import api from '../../services/api';
import { Editar, InputPercentual, SelectPaginacao } from "../../components";
import { useStateValue } from '../../state';
import { listagemActions, manutencaoActions, selectPaginadoActions } from "../../actions";
import { LoadingOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import ImgCrop from "antd-img-crop";
import { DeleteOutlined } from "@material-ui/icons";

export default function ManutencaoCidade({ formulario, carregando, aoSalvar, editando }) {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [imageUrl, setImageUrl] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [listaProvedor, setListaProvedor] = useState([]);
    const [listaVersao, setListaVersao] = useState([]);
    const [hover, setHover] = useState(false);



    useEffect(() => {
        api.get(`Enum/Listar?nome=NFSeProvedor`).then(res => {
            if (res.status === 200) {
                setListaProvedor(res.data);
            }
        }).catch((erro) => console.error(erro));

        api.get(`Enum/Listar?nome=VersaoNFSe`).then(res => {
            if (res.status === 200) {
                setListaVersao(res.data);
            }
        }).catch((erro) => console.error(erro));

    }, []);

    useEffect(() => {

        if (ui.showDrawer && !!manutencao.dados && editando.editando) {
            let lista = [];
            if (!!manutencao.dados.cid_id) {
                lista.push({ name: "cid_id", campo: "IdCidade", value: manutencao.dados.cid_id });
            }
            if (lista.length > 0) {
                dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
                editando.setEditando(false);
            }
            if (!!manutencao.dados.cnf_brasaocidade) {
                setImageUrl("data:image/jpeg;base64," + manutencao.dados.cnf_brasaocidade);
            } else { setImageUrl(null) };
        }

    }, [ui.showDrawer, manutencao.dados]);

    const salvarManutencao = (values) => {
        carregando(true);
        let retorno;
        

        var logo = (imageUrl == null ? "" : imageUrl);
        if (!!logo) {
            values.cnf_brasaocidade = (logo.split(",", 2)[1]);
        } else {
            values.cnf_brasaocidade = "";
        }

        let enderecoUrl = 'CidadeConfigNfse/';

        if (values.cnf_id) {
            enderecoUrl += `Editar`;
            api.put(enderecoUrl, values).then(res => {
                if (res.status === 200) {
                    notification.success({ message: 'Operação concluída', description: "As novas configurações foram salvas com sucesso!" });
                    retorno = res.data;
                }
            }, err => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
            }).finally(
                () => {
                    carregando(false);
                    aoSalvar(retorno);
                    setFileList([]);
                })
        } else {
            enderecoUrl += `Incluir`;
            api.post(enderecoUrl, values).then(res => {
                if (res.status === 200) {
                    notification.success({ message: 'Operação concluída', description: "As configurações foram incluidas com sucesso!" });
                    retorno = res.data;
                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: res.data } });
                }
            }, err => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
            }).finally(
                () => {
                    carregando(false);
                    aoSalvar(retorno);
                    dispatch({ type: listagemActions.CHANGE, data: { ordem: '+cnf_id' } });
                }
            );
        }

    }

    const base64 = (imagem, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(imagem);
    }


    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        base64(info.file.originFileObj, (imageUrl) => {
            setLoading(false);
            setImageUrl(imageUrl);
        });
    };



    const borderColor = hover ? "#004b97" : "#d9d9d9";
    const mouseEnter = () => { setHover(true); }
    const mouseLeave = () => { setHover(false); }
    const removerImagem = () => { setImageUrl(null); }

    const uploadButton = (
        <div className="buttonUpload" style={{ border: `1px dashed ${borderColor}`, cursor: hover ? "pointer" : "auto" }} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} >
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div className="ant-upload-text"> {loading ? 'Carregando...' : 'Selecionar Imagem'}</div>
        </div>
    );

    return (
        <Form layout="vertical" form={formulario} name="ManutencaoCidade" onFinish={salvarManutencao}>
            <Form.Item name="cnf_id" hidden={true}> <Input />
            </Form.Item>

            <Col xs={10} sm={10} md={10} lg={10} xl={10}>

                <Form.Item label="Descrição Cidade" name="cid_id" >
                    <SelectPaginacao url="Cidade/Listar" placeholder="Selecione a Cidade" nameLabel="cid_descricao" nameValue="cid_id" form={formulario} selecionarRegUnico="cid_id"
                        conteudo={
                            cid => (<Select.Option value={cid.cid_id} key={cid.key}>{cid.cid_id} - {cid.cid_descricao}</Select.Option>)
                        } />
                </Form.Item>
            </Col>

            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <Form.Item label="Provedor de NFSE" name="cnf_provedornfse" rules={[{ required: true }]}>
                    <Select showSearch options={listaProvedor.map((item) => {
                        return { label: item.value, value: item.key, key: item.key }
                    })} filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0} placeholder="Selecione o provedor de NFSE" />
                </Form.Item>
            </Col>

            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <Form.Item label="Versão para emissão" name="cnf_versaonfse" rules={[{ required: true }]}>
                    <Select showSearch options={listaVersao.map((item) => {
                        return { label: item.value, value: item.key, key: item.key }
                    })} filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0} placeholder="Selecione a versão para emissão" />
                </Form.Item>
            </Col>

            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <Form.Item label="% Aliquota de ISSQN" name="cnf_percentualissqn" >
                    <InputNumber
                        min={0}
                        max={100}
                        controls={false}
                        defaultValue={0}
                        formatter={value => `${value}%`}
                        parser={value => value.replace('%', '')}
                        placeholder="% Aliquota de ISSQN" />
                </Form.Item>
            </Col>

            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <Form.Item label="Quantidade de serviços por NFSE" name="cnf_qtdeserviconfse" rules={[
                ]}>
                    <InputNumber placeholder="Quantidade de serviços por NFSE" />
                </Form.Item>
            </Col>

            <Col xs={15} sm={10} md={10} lg={10} xl={10}>
                <Form.Item label="Imagem Brasão cidade (logo)" name="cnf_brasaocidade" rules={[
                ]}>
                    <Col xs={24} sm={8} md={7} lg={7} xl={7} align="center">
                        <ImgCrop
                            rotate
                            modalTitle="Ajuste sua imagem"
                            modalOk="Salvar">
                            <Upload

                                name="cnf_brasaocidade"
                                showUploadList={false}
                                onChange={handleChange}
                                onRemove={() => setImageUrl(null)}
                                maxCount={1}
                                disabled={imageUrl}
                            >
                                {imageUrl ?
                                    <img src={imageUrl} alt="teste" name="cnf_brasaocidade" />
                                    : uploadButton}
                            </Upload>
                        </ImgCrop>
                        {imageUrl &&
                            <Col xs={27} sm={27} md={25} lg={25} xl={25}>
                                <Form.Item style={{ marginTop: "25px" }}>
                                    <Tooltip title="Remover Imagem" placement="bottom">
                                        <Button shape="circle" icon={<DeleteOutlined />} onClick={removerImagem} />
                                    </Tooltip>
                                </Form.Item>
                            </Col>
                        }
                    </Col>
                </Form.Item>
            </Col>

        </Form >
    );
}
