import React from "react";
import { Col, Divider, Row, Table, Tag } from "antd";

import { FormatNumber } from "../../ValueObjects";
import moment from "moment";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export default function TabDetalheDocsCte({ docs, listaSerieFiscal }) {

    function retornaSerieFiscal(cid_serie) {
        if (listaSerieFiscal.length > 0) {
            let serie = listaSerieFiscal.filter((serie) => (serie.ser_numero === parseFloat(cid_serie)))[0];
            return !!serie ? serie.ser_serie : cid_serie;
        } else {
            return cid_serie;
        }
    }

    return (
        <div className="tabela">
            <Table columns={
                [
                    {
                        title: 'Número',
                        align: 'center',
                        width: 80,
                        render: ({ cid_numero }) => (
                            <Tag color="processing" className="w-75">
                                <b>{cid_numero}</b>
                            </Tag>
                        )
                    },
                    {
                        title: 'Série',
                        align: 'center',
                        render: ({ cid_serie }) => (
                            <b>{retornaSerieFiscal(cid_serie)}</b>
                        )
                    },
                    {
                        title: 'Chave',
                        render: ({ cid_chave }) => (
                            <b>{cid_chave}</b>
                        )
                    },
                    {
                        title: 'Emissão',
                        width: 90,
                        render: ({ cid_dataemissao }) => (
                            <div>
                                <b>{moment(cid_dataemissao).format('DD/MM/YYYY HH:mm')}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Modelo',
                        align: 'center',
                        render: ({ cid_modelo }) => (
                            <b>{cid_modelo}</b>
                        )
                    },
                    {
                        title: 'CFOP',
                        align: 'center',
                        render: ({ cid_cfoppred }) => (
                            <div>
                                <b>{cid_cfoppred}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Peso',
                        align: 'center',
                        render: ({ cid_peso }) => (
                            <b>{cid_peso}</b>
                        )
                    },
                    {
                        title: 'Valor (R$)',
                        align: 'right',
                        fixed: 'right',
                        render: ({ cid_valornf }) => (
                            <b>R$ {FormatNumber(cid_valornf, true)}</b>
                        )
                    }
                ]
            } dataSource={docs} pagination={false}
                locale={{
                    emptyText: (
                        <Row>
                            <Col span={24}>
                                <Divider orientation="center">
                                    <ExclamationCircleOutlined /> Não há Dados
                                </Divider>
                            </Col>
                        </Row>
                    )
                }}
            />
        </div>
    )
}
