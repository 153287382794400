import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Form, Select, Input, InputNumber, Modal, Tooltip } from "antd";
import { ExclamationCircleOutlined, PlusOutlined, CloseOutlined, QuestionCircleOutlined } from "@ant-design/icons";

import { InputPercentual } from '../../../components';
import api from '../../../services/api';
import { getRegimeTributario } from "../../../services/auth";
import { isNotNullOrEmpty } from "../../../services/funcoes";
import SelectPaginado from "../../selectPaginado";

export default function ModalTributacaoIcms({ exibirModal, fecharModal, dados, setDados, formulario, listaOrigemDestino }) {

    const [formDados] = Form.useForm();
    const [listaCst, setListaCst] = useState([]);
    const [listaCsosn, setListaCsosn] = useState([]);
    const [listarUfDestino, setListarUfDestino] = useState([]);
    const [regimeTrib, setRegimeTrib] = useState(null);
    const [estadoOrigem, setEstadoOrigem] = useState(null);
    const [mostrarCsoSn, setMostrarCsoSn] = useState(false);
    const [mostrarCstIcms, setMostrarCstIcms] = useState(false);
    const [mostrarDiferimentoIcms, setMostrarDiferimentoIcms] = useState(false);
    const [mostrarAlicotaIcms, setMostrarAlicotaIcms] = useState(false);
    const [mostrarReducaoBC, setMostrarReducaoBC] = useState(false);
    const [obrigatorioDadosICMS, setObrigatorioDadosICMS] = useState(true);

    useEffect(() => {
        let rt = parseInt(getRegimeTributario());
        setRegimeTrib(rt);

        api.get(`cstIcms/Listar?PageNumber=1&PageSize=100&Order=cst_id`).then(
            res => {
                setListaCst(res.data.items);
            }).catch(
                error => {
                    console.log(error);
                }
            )

        api.get(`csosn/Listar?PageNumber=1&PageSize=100&Order=cso_id`).then(
            res => {
                setListaCsosn(res.data.items);
            }).catch(
                error => {
                    console.log(error);
                }
            )

        //Lista estados do Brasil modificado para trib exterior
        api.get(`estado/Listar?IdPais=33&PageNumber=1&PageSize=100&Order=est_descricao`).then(
            res => {
                if (!!listaOrigemDestino) {
                    let list = [...res.data.items];
                    setListarUfDestino(list);
                }
            }).catch(
                error => {
                    console.log(error);
                }
            )

        setEstado();
        validarRegimeEmpresa();
        calculaAliquotaEquivalente();
    }, [exibirModal, dados])

    function setEstado() {
        api.get(`Local/BuscarLocal`).then(res => {
            setEstadoOrigem(res.data.loc_inscricaoestadual.ufEstado);
        })
    }

    function validarRegimeEmpresa() {
        if (regimeTrib === 3) {
            setMostrarCstIcms(true);
            setMostrarCsoSn(false);
            validarCst(dados ? dados.cst_id : formulario.getFieldValue().cst_id);
        } else {
            setMostrarCstIcms(false);
            setMostrarCsoSn(true);
            setMostrarDiferimentoIcms(false);
            validarCsosn(dados ? dados.cso_id : formulario.getFieldValue().cso_id)
        }
    }

    function validarCst(cst_id) {
        let codigoCst;
        if (!!cst_id) {
            codigoCst = cst_id;
        } else {
            codigoCst = formulario.getFieldValue('cst_id');
        }
        if (!!codigoCst) {
            if (codigoCst <= '20' || codigoCst === '90' || codigoCst === '51' || codigoCst === '70') {
                setMostrarAlicotaIcms(true);
            } else {
                setMostrarAlicotaIcms(false);
                formulario.setFieldsValue({ iod_percicms: 0 });
            }

            if (codigoCst === '90' || codigoCst === '20' || codigoCst === '70') {
                setMostrarReducaoBC(true);
                if (codigoCst === '90') {
                    setObrigatorioDadosICMS(false);
                }
            } else {
                setMostrarReducaoBC(false);
                formulario.setFieldsValue({ iod_percicmsreducaobc: 0 });
            }

            if (codigoCst === '90' || codigoCst === '51') {
                setMostrarDiferimentoIcms(true);
                if (codigoCst === '90') {
                    setObrigatorioDadosICMS(false);
                }
            } else {
                setMostrarDiferimentoIcms(false);
                formulario.setFieldsValue({ iod_percdiferimentoicms: 0 });
            }
        } else {
            setMostrarAlicotaIcms(true);
            setMostrarReducaoBC(true);
            setMostrarDiferimentoIcms(true);
        }
    }

    function validarCsosn(valor) {
        let codigoCso;
        if (!!valor) {
            codigoCso = valor;
        } else {
            codigoCso = formulario.getFieldValue().cso_id;
        }
        formulario.setFieldsValue({ iod_percicms: null });
        formulario.setFieldsValue({ iod_percicmsreducaobc: null });
        formulario.setFieldsValue({ iod_percdiferimentoicms: null });

        if (!!codigoCso) {
            if (codigoCso === '201' || codigoCso === '202' || codigoCso === '203' || codigoCso === '900') {
                setMostrarAlicotaIcms(true);
                setMostrarReducaoBC(true);
            } else {
                setMostrarAlicotaIcms(false);
                setMostrarReducaoBC(false);
                formulario.setFieldsValue({ iod_percicms: 0 });
                formulario.setFieldsValue({ iod_percicmsreducaobc: 0 });
                formulario.setFieldsValue({ iod_percdiferimentoicms: 0 });
            }
        }
    }

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                setMostrarCsoSn(false);
                setMostrarCstIcms(false);
                setMostrarDiferimentoIcms(false);
                setMostrarAlicotaIcms(false);
                setMostrarReducaoBC(false);
                setRegimeTrib(null);
                fecharModal();
                formulario.resetFields();
            }
        });
    };
    const salvarDados = values => {
        let percicms = formulario.getFieldValue('iod_percicms');
        let percicmsreducaobc = formulario.getFieldValue('iod_percicmsreducaobc');
        let percdiferimentoicms = formulario.getFieldValue('iod_percdiferimentoicms');
        values.iod_percicms = percicms ? percicms : 0;
        values.iod_percicmsreducaobc = percicmsreducaobc ? percicmsreducaobc : 0;
        values.iod_percdiferimentoicms = percdiferimentoicms ? percdiferimentoicms : 0;
        let listaTemp = [];
        if (!!!values.iod_id) {
            values.iod_uforigem = estadoOrigem;
            let key = 0;
            values.iod_ufdestino.forEach((item) => {
                let dadosTemp = { ...values };
                dadosTemp.iod_ufdestino = item;
                dadosTemp.key = key++;
                listaTemp.push(dadosTemp);

            });
            setDados(listaTemp);
        } else {
            let dadosTemp = { ...values };
            listaTemp.push(dadosTemp);
            setDados(listaTemp);
        }
        fecharModal();
        formulario.resetFields();
    }
    function calculaPercentualReducao() {
        let _percicms = formulario.getFieldValue('iod_percicms');
        let _aliquotaEquivalente = formulario.getFieldValue('aliquotaEquivalente');
        let _calc = parseFloat(100 - ((100 / _percicms) * _aliquotaEquivalente)).toFixed(4);

        formulario.setFieldsValue({ iod_percicmsreducaobc: _calc });
    }

    function calculaAliquotaEquivalente() {
        let _percicms = formulario.getFieldValue('iod_percicms');
        let _percreducao = formulario.getFieldValue('iod_percicmsreducaobc');
        let _calc = parseFloat(_percicms - ((_percicms * _percreducao) / 100)).toFixed(2);

        formulario.setFieldsValue({ aliquotaEquivalente: _calc });
    }

    return (
        <Modal centered
            title="Cadastro de Origem e Destino"
            visible={exibirModal}
            onCancel={onCloseModal}
            onOk={() => {

                formulario.submit();
            }}
            okText={
                <>
                    <PlusOutlined /> Adicionar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
        >
            <div>
                <Row align="middle" gutter={[0, 16]}>
                    <Col span={24}>
                        <Typography.Text>
                            Defina as Informações de Origem e Destino aqui!
                        </Typography.Text>
                    </Col>
                </Row>
                <div>
                    <Form layout="vertical" name="manutencaoOrigemDestino" form={formulario} onFinish={salvarDados}>
                        <Form.Item hidden name="iod_id" />
                        <Form.Item hidden name="iod_novo" />
                        {mostrarCstIcms === true &&
                            <Row align="middle" gutter={[8, 8]}>
                                <Col span={24}>
                                    <Form.Item label="CST" name="cst_id" rules={[{ required: mostrarCstIcms === true ? true : false, message: 'Selecione o CST' }]}>
                                        <Select placeholder="Selecione o CST" onChange={(valor) => { validarCst(valor) }}>
                                            {listaCst.map(item => (
                                                <Select.Option value={item.cst_id} key={item.cst_id}>{item.cst_id} - {item.cst_descricao}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        }
                        {mostrarCsoSn === true &&
                            <Row align="middle" gutter={[8, 8]}>
                                <Col span={24}>
                                    <Form.Item label="CSOSN" name="cso_id" rules={[{ required: mostrarCsoSn === true ? true : false, message: 'Selecione o CSOSN' }]} >
                                        <Select placeholder="Selecione o CSOSN" showSearch optionFilterProp="children" /*onChange={(valor) => validarCsosn(valor)} */>
                                            {listaCsosn.map(item => (
                                                <Select.Option value={item.cso_id} key={item.cso_id}>{item.cso_id} - {item.cso_descricao}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        }
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                <Form.Item label="UF de Origem" name="iod_uforigem">
                                    <Select placeholder="Seleciona a Uf de Origem" defaultValue={estadoOrigem} disabled>
                                        {listarUfDestino.map(est => (
                                            <Select.Option value={est.est_sigla} key={est.est_id}>{est.est_sigla} - {est.est_descricao}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                <Form.Item label="UF de Destino" name="iod_ufdestino" rules={[{ required: true, message: 'Selecione o Estado de Destino' }]}>
                                    <SelectPaginado url="estado/Listar?IdPais=33" multiple="true" placeholder="Selecione a UF de Destino" form={formulario} name="iod_ufdestino" disabled={formulario.getFieldValue('iod_id')} conteudo={
                                        est => <Select.Option value={est.est_sigla} key={est.est_id}>{est.est_sigla} - {est.est_descricao}</Select.Option>
                                    }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        {/* Quando empresa for do Geral */}
                        <Row gutter={[8, 0]}>
                            {mostrarCstIcms === true && mostrarAlicotaIcms === true &&
                                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                    <InputPercentual
                                        label="% do ICMS"
                                        name="iod_percicms"
                                        rules={[{ required: mostrarCstIcms === true && mostrarAlicotaIcms === true && obrigatorioDadosICMS === true ? true : false, message: 'Informe o % do ICMS' }]}
                                        defaultValue={0}
                                        min={0}
                                        max={100}
                                        onChange={() => calculaAliquotaEquivalente()}
                                    />
                                </Col>
                            }
                            {mostrarCstIcms === true && mostrarReducaoBC === true &&
                                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                    <div>
                                        Aliquota Efetiva
                                        <Tooltip title="Campo referente a aliquota equivalente conforme o % de redução de ICMS informado no campo de % Redução da BC">
                                            <QuestionCircleOutlined />
                                        </Tooltip>
                                    </div>
                                    <InputPercentual
                                        name="aliquotaEquivalente"
                                        defaultValue={0}
                                        min={0}
                                        max={100}
                                        precision={2}
                                        onChange={() => calculaPercentualReducao()}
                                    />
                                </Col>
                            }
                            {mostrarCstIcms === true && mostrarReducaoBC === true &&
                                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                    <InputPercentual
                                        rules={[{ required: mostrarCstIcms === true && mostrarReducaoBC === true && obrigatorioDadosICMS === true ? true : false, message: 'Informe o % Redução Base de Cálculo' }]}
                                        label="% Redução Base de Cálculo"
                                        name="iod_percicmsreducaobc"
                                        defaultValue={0}
                                        min={0}
                                        max={100}
                                        precision={4}
                                        onChange={() => calculaAliquotaEquivalente()}
                                    />
                                </Col>
                            }
                            {mostrarCstIcms === true && mostrarDiferimentoIcms === true &&
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item label="% Diferimento do ICMS" name="iod_percdiferimentoicms" rules={[{ required: mostrarCstIcms === true && mostrarDiferimentoIcms === true && obrigatorioDadosICMS === true ? true : false, message: 'Informe o % Diferimento do ICMS' }]}>
                                        <InputNumber
                                            defaultValue={0}
                                            min={0}
                                            max={100}
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                        />
                                    </Form.Item>
                                </Col>
                            }
                            {/* Quando empresa for do simples nacional */}
                            <Row gutter={[8, 0]}>
                                {mostrarCsoSn === true && mostrarAlicotaIcms === true &&
                                    <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                        <InputPercentual
                                            label="% do ICMS"
                                            name="iod_percicms"
                                            rules={[{ required: mostrarCstIcms === true && mostrarAlicotaIcms === true ? true : false, message: 'Informe o % do ICMS' }]}
                                            defaultValue={0}
                                            min={0}
                                            max={100}
                                            onChange={() => calculaAliquotaEquivalente()}
                                        />
                                    </Col>
                                }
                                {mostrarCsoSn === true && mostrarReducaoBC === true &&
                                    <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                        <div>
                                            Aliquota Efetiva
                                            <Tooltip title="Campo referente a aliquota equivalente conforme o % de redução de ICMS informado no campo de % Redução da BC">
                                                <QuestionCircleOutlined />
                                            </Tooltip>
                                        </div>
                                        <InputPercentual
                                            name="aliquotaEquivalente"
                                            defaultValue={0}
                                            min={0}
                                            max={100}
                                            precision={2}
                                            onChange={() => calculaPercentualReducao()}
                                        />
                                    </Col>
                                }
                                {mostrarCsoSn === true && mostrarReducaoBC === true &&
                                    <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                        <InputPercentual
                                            label="% Redução Base de Cálculo"
                                            name="iod_percicmsreducaobc"
                                            defaultValue={0}
                                            min={0}
                                            max={100}
                                            precision={4}
                                            onChange={() => calculaAliquotaEquivalente()}
                                        />
                                    </Col>
                                }
                            </Row>
                        </Row>
                        <Row align="middle" gutter={[8, 8]}>
                            <Col span={24}>
                                <Form.Item label="Observações Complementares a Origem e Destino" name="iod_observacao">
                                    <Input.TextArea placeholder="Informe as Observações Referente a Origem/Destino" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </Modal>
    );
}