import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Divider, Button, Form, Table, Tag, Checkbox, Input, Modal } from "antd";
import { PlusOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from "../../services/api";
import { info } from "../../services/funcoes";
import { FormatNumber } from "../../ValueObjects";
import { ModalEditarItemDevolucao, ModalEditarCFOP } from "../../components/modals/";
import { calculoICMS, calculoICMSST, calculoIPI } from "../../services/calculaImposto";

export default function TabItens({ listaNotaDevolucao, adicionarNotas, dadosNfe }) {

    const [form] = Form.useForm();
    const [openModalEditarItemDevolucao, setOpenModalEditarItemDevolucao] = useState(false);
    const [openModalEditarCFOP, setOpenModalEditarCFOP] = useState(false);
    const [itemEditar, setItemEditar] = useState({});
    const [listaCFOP, setListaCFOP] = useState([]);

    function removeNotaFiscal(ntf_id) {
        Modal.confirm({
            title: 'Atenção!',
            icon: <ExclamationCircleOutlined />,
            content: 'Remover a Nota Fiscal Selecionada?',
            okText: 'Sim, quero remover',
            cancelText: 'Não',
            centered: true,
            onOk() {
                let lista = [...listaNotaDevolucao.listaNotaDevolucao];
                let indice = lista.findIndex(nota => nota.ntf_id === ntf_id);
                lista.splice(indice, 1);
                listaNotaDevolucao.setListaNotaDevolucao(lista);
            }
        });
    };

    useEffect(() => {
        listarCFOP();
    }, []);

    function listarCFOP() {
        api.get(`Cfop/ListarCFOPDevolucao?tipoOperacao=${dadosNfe.dadosNfeDevolucao.ntf_tipooperacao}&ufDest=${dadosNfe.dadosNfeDevolucao.ntf_ufdest}&ufOrigem=${dadosNfe.dadosNfeDevolucao.ntf_ufdest}`).then(
            res => {
                setListaCFOP(res.data);
            }
        ).catch(
            erro => { console.log(erro); }
        );
    };

    function selecionarTodos(checked, ntf_id) {
        let lista = [...listaNotaDevolucao.listaNotaDevolucao];
        let nota = lista.find((nota) => { return nota.ntf_id === ntf_id });
        nota.checked = checked;
        nota.itens.forEach((item) => {
            item.checked = checked;
        });
        listaNotaDevolucao.setListaNotaDevolucao(lista);
    };

    function selecionarItem(nfi_id, ntf_id) {
        let lista = [...listaNotaDevolucao.listaNotaDevolucao];
        let nota = lista.find((nota) => { return nota.ntf_id === ntf_id });
        let item = nota.itens.find((item) => { return item.nfi_id === nfi_id });
        item.checked = !item.checked;
        listaNotaDevolucao.setListaNotaDevolucao(lista);
    };

    function excluirMarcados(ntf_id) {
        let lista = [...listaNotaDevolucao.listaNotaDevolucao];
        let nota = lista.find((nota) => { return nota.ntf_id === ntf_id });
        nota.itens = nota.itens.filter((item) => { return !item.checked });
        listaNotaDevolucao.setListaNotaDevolucao(lista);
    };

    function excluirItem(ntf_id, nfi_id) {
        let lista = [...listaNotaDevolucao.listaNotaDevolucao];
        let nota = lista.find((nota) => { return nota.ntf_id === ntf_id });
        nota.itens = nota.itens.filter((item) => { return item.nfi_id !== nfi_id });
        listaNotaDevolucao.setListaNotaDevolucao(lista);
    };

    async function salvarItemNota() {
        let quantidade = parseFloat(form.getFieldValue().nfi_qtde);
        if (itemEditar.qtdeOriginal < quantidade) {
            info(`Quantidade informada maior que quantidade disponível para devolução (${itemEditar.qtdeOriginal})!`);
            return;
        }
        let lista = [...listaNotaDevolucao.listaNotaDevolucao];
        let nota = lista.find((nota) => { return nota.ntf_id === itemEditar.ntf_id });
        let item = nota.itens.find((item) => { return item.nfi_id === itemEditar.nfi_id });
        item.qtdeRestante = quantidade;

        if (!!item.nfi_valorseguro) {
            item.valorSeguroRateio = (parseFloat(item.nfi_valorseguro) / itemEditar.nfi_qtde) * quantidade;
        }
        if (!!item.nfi_valorfrete) {
            item.valorFreteRateio = (parseFloat(item.nfi_valorfrete) / itemEditar.nfi_qtde) * quantidade;
        }
        if (!!item.nfi_valoroutros) {
            item.valorOutrosRateio = (parseFloat(item.nfi_valoroutros) / itemEditar.nfi_qtde) * quantidade;
        }
        if (!!item.nfi_valordesconto) {
            item.valorDescontoRateio = (parseFloat(item.nfi_valordesconto) / itemEditar.nfi_qtde) * quantidade;
        }

        if (!!item.bcIcms) {
            item.nig_valorbc = (parseFloat(item.bcIcms) / itemEditar.nfi_qtde) * quantidade;
        }
        if (!!item.valorIcms) {
            item.nig_valoricms = (parseFloat(item.valorIcms) / itemEditar.nfi_qtde) * quantidade;
        }
        if (!!item.bcIcmsSt) {
            item.nig_valorbcst = (parseFloat(item.bcIcmsSt) / itemEditar.nfi_qtde) * quantidade;
        }
        if (!!item.valorIcmsSt) {
            item.nig_valoricmsst = (parseFloat(item.valorIcmsSt) / itemEditar.nfi_qtde) * quantidade;
        }
        if (!!item.bcIpi) {
            item.npi_valorbcipi = (parseFloat(item.bcIpi) / itemEditar.nfi_qtde) * quantidade;
        }
        if (!!item.valorIpi) {
            item.npi_valoripi = (parseFloat(item.valorIpi) / itemEditar.nfi_qtde) * quantidade;
        }

        listaNotaDevolucao.setListaNotaDevolucao(lista);
        setOpenModalEditarItemDevolucao(false);
    };

    function pesquisarItem(value, ntf_id) {
        let lista = [...listaNotaDevolucao.listaNotaDevolucao];
        let nota = lista.find((nota) => { return nota.ntf_id === ntf_id });
        nota.filtro = value;
        listaNotaDevolucao.setListaNotaDevolucao(lista);
    };

    function selecionarCFOP(cfo_cfop) {
        if (!!cfo_cfop) {
            let lista = [...listaNotaDevolucao.listaNotaDevolucao];
            let nota = lista.find((nota) => { return nota.ntf_id === itemEditar.ntf_id });
            let item = nota.itens.find((item) => { return item.nfi_id === itemEditar.nfi_id });
            item.nfi_cfop = cfo_cfop;
            listaNotaDevolucao.setListaNotaDevolucao(lista);
            setOpenModalEditarCFOP(false);
        }
    };

    return (
        <div>
            <Row gutter={[8, 16]}>
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-produto.png").default} alt="Itens das Notas Fiscais Referenciadas" /> Itens das Notas Fiscais Referenciadas
                    </Typography.Title>
                    <Divider orientation="left" plain />
                </Col>
            </Row>
            <Row align="middle" justify="end">
                <Col>
                    <div className="i-Position p-relative">
                        <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={() => { adicionarNotas.setAdicionarNotas(true) }} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div className="tabela expandirNfe">
                        <Table dataSource={listaNotaDevolucao.listaNotaDevolucao} showHeader={false} columns={[
                            {
                                title: 'Nº Nota',
                                align: 'center',
                                render: ({ ntf_numero }) => (
                                    <div>
                                        <Row justify="center">
                                            <Col>
                                                <b className="f-18">{ntf_numero}</b>
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            }, {
                                title: 'Outras Informações',
                                render: ({ ser_serie, ntf_dataemissao, ntf_chavenfe }) => (
                                    <div>
                                        <Row align="middle" gutter={[8, 0]} justify="begin">
                                            <Col span={6}>
                                                Série: <b>{(!!ser_serie ? ser_serie : '')}</b>
                                            </Col>
                                            <Col span={6}>
                                                Emissão: <b>{moment(ntf_dataemissao).format('DD/MM/YYYY')}</b>
                                            </Col>
                                        </Row>
                                        <Row align="middle" gutter={[8, 0]}>
                                            {!(!!ntf_chavenfe) && <Col>
                                                <b>NF-e não gerada</b>
                                            </Col>}
                                            {!!ntf_chavenfe && <Col>
                                                NF-e: <b>{ntf_chavenfe}</b>
                                            </Col>}
                                        </Row>
                                    </div>
                                ),
                            },
                            {
                                title: 'Total',
                                width: 220,
                                render: ({ totalNfe }) => (
                                    <div>
                                        <Row justify="center">
                                            <Col>
                                                Total da Nota
                                            </Col>
                                        </Row>
                                        <Row justify="center">
                                            <Col align="right">
                                                <b className="f-18">{FormatNumber(totalNfe, true)}</b>
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                            {
                                dataIndex: '',
                                key: 'x',
                                align: 'center',
                                fixed: 'right',
                                width: 75,
                                render: ({ ntf_id }) => (
                                    <div>
                                        <Row align="middle" justify="center" gutter={[5, 0]}>
                                            <Col>
                                                <Button icon={<DeleteOutlined />} onClick={() => { removeNotaFiscal(ntf_id) }} />
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            }
                        ]} expandable={{
                            expandedRowRender: ({ itens, ntf_id, filtro }) =>
                                <div>
                                    <Row gutter={[8, 8]}>
                                        <Col span={6}>
                                            <Checkbox onClick={(e) => selecionarTodos(e.target.checked, ntf_id)}>Selecionar Todos</Checkbox>
                                        </Col>
                                        <Col span={12} align="center">
                                            <Form.Item>
                                                <Input.Search placeholder="Localizar produto..." enterButton onSearch={(value) => pesquisarItem(value, ntf_id)} allowClear />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} align="right">
                                            <Button icon={<DeleteOutlined />} onClick={() => { excluirMarcados(ntf_id) }}>Deletar Marcados</Button>
                                        </Col>
                                    </Row>
                                    <Row gutter={[8, 8]} className="tabela tabelaNfe tabelaItens">
                                        <Col span={24} align="left">
                                            <Table dataSource={!!filtro ? itens.filter((item) => { return item.pro_descricao.toUpperCase().indexOf((filtro.trim()).toUpperCase()) >= 0 || item.pro_codigo.toString().indexOf((filtro.trim()).toUpperCase()) >= 0 }) : itens} columns={[
                                                {
                                                    title: 'Itens da Nota',
                                                    width: '48%',
                                                    render: ({ checked, pro_codigo, pro_descricao, nfi_ean, grade, nfi_id }) => (
                                                        <div>
                                                            <Row gutter={[8, 2]}>
                                                                <Col>
                                                                    <Checkbox checked={checked} onClick={() => { selecionarItem(nfi_id, ntf_id) }} />
                                                                </Col>
                                                                <Col>
                                                                    <Tag color="processing" className="w-75">
                                                                        <b>{pro_codigo}</b>
                                                                    </Tag>
                                                                </Col>
                                                                <Col>
                                                                    <b>{pro_descricao}</b>
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={[8, 8]}>
                                                                <Col>
                                                                    EAN: <b>{nfi_ean}</b>
                                                                </Col>
                                                                <Col>
                                                                    Grade: <b>{grade}</b>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    ),
                                                }, {
                                                    title: 'CFOP',
                                                    width: '5%',
                                                    align: 'center',
                                                    render: (record) => (
                                                        <div>
                                                            <b>
                                                                <a onClick={() => { setItemEditar(record); setOpenModalEditarCFOP(true); }} >{record.nfi_cfop}</a>
                                                            </b>
                                                        </div>
                                                    ),
                                                }, {
                                                    title: 'CST/CSOSN',//`${record.nis_csosn ? 'CSOSN' : 'CST'}`,
                                                    align: 'center',
                                                    width: '6%',
                                                    render: ({ nig_origemmercadoria, nig_cst, nis_origemmercadoria, nis_csosn }) => (
                                                        <div>
                                                            <Col >
                                                                <b>{nis_csosn ? nis_origemmercadoria + nis_csosn : nig_origemmercadoria + nig_cst}</b>
                                                            </Col>
                                                        </div>
                                                    ),
                                                }, {
                                                    title: 'Qtde',
                                                    width: '7%',
                                                    align: 'right',
                                                    render: ({ qtdeRestante, nfi_unidademedida }) => (
                                                        <div>
                                                            <b>{FormatNumber(qtdeRestante, true, false, 3)} {nfi_unidademedida}</b>
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    title: 'Vlr. Unit.(R$)',
                                                    width: '8%',
                                                    align: 'right',
                                                    render: ({ nfi_valorunitario }) => (
                                                        <div>
                                                            <b>{FormatNumber(nfi_valorunitario, true)}</b>
                                                        </div>
                                                    ),
                                                }, {
                                                    title: 'Desc.(R$)',
                                                    width: '7%',
                                                    align: 'right',
                                                    render: ({ valorDescontoRateio }) => (
                                                        <div>
                                                            <b>{FormatNumber(valorDescontoRateio, true)}</b>
                                                        </div>
                                                    ),
                                                }, {
                                                    title: 'Frete.(R$)',
                                                    width: '7%',
                                                    align: 'right',
                                                    render: ({ nfi_valorfrete }) => (
                                                        <div>
                                                            <b>{FormatNumber(nfi_valorfrete, true)}</b>
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    title: 'Vlr. Total(R$)',
                                                    align: 'right',
                                                    width: '8%',
                                                    render: ({ nfi_valorunitario, qtdeRestante, valorDescontoRateio }) => (
                                                        <div>
                                                            <b>{FormatNumber((nfi_valorunitario * qtdeRestante) - valorDescontoRateio, true)}</b>
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    title: 'Ações',
                                                    dataIndex: '',
                                                    key: 'x',
                                                    align: 'center',
                                                    fixed: 'right',
                                                    width: 65,
                                                    render: (record) => (
                                                        <div>
                                                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                                                <Col>
                                                                    <Button icon={<EditOutlined />} onClick={() => {
                                                                        setItemEditar(record);
                                                                        form.setFieldsValue({ nfi_qtde: record.qtdeRestante });
                                                                        setOpenModalEditarItemDevolucao(true);
                                                                    }} />
                                                                </Col>
                                                                <Col>
                                                                    <Button icon={<DeleteOutlined />} onClick={() => { excluirItem(ntf_id, record.nfi_id) }} />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    ),
                                                },
                                            ]} locale={{
                                                emptyText: (
                                                    <Row>
                                                        <Col span={24}>
                                                            <Divider orientation="center">
                                                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                                            </Divider>
                                                        </Col>
                                                    </Row>
                                                )
                                            }} pagination={false} scroll={{ x: 900 }} />
                                        </Col>
                                    </Row>
                                </div>,
                            defaultExpandAllRows: true,
                            expandIconColumnIndex: -1
                        }} />
                    </div>
                </Col>
            </Row>
            <ModalEditarCFOP exibirModal={openModalEditarCFOP} aoSelecionar={(cfo_cfop) => { selecionarCFOP(cfo_cfop) }} fecharModal={() => setOpenModalEditarCFOP(false)} listaCFOP={{ listaCFOP, setListaCFOP }} />
            <ModalEditarItemDevolucao form={form} exibirModalEditarItemDevolucao={openModalEditarItemDevolucao} aoSalvar={() => { salvarItemNota() }} cancelar={() => setOpenModalEditarItemDevolucao(false)} itemEditar={itemEditar} />
        </div>
    );
}