import React, { useState } from 'react';
import { Row, Col, Button, Modal, Form, Drawer } from "antd";
import { CloseOutlined, SaveOutlined, ExclamationCircleOutlined, MinusOutlined } from "@ant-design/icons";

import ManutencaoCidade from './manutencao';
import { useStateValue } from '../../state';
import { drawerActions, listagemActions, manutencaoActions } from '../../actions';
import { Refresh } from '@material-ui/icons';

export default function DrawerCidade({ editando }) {

    const [formCidade] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);

    if (manutencao.dados !== null && editando.editando) {
        formCidade.setFieldsValue(manutencao.dados)
    }

    function fecharDrawer() {
        formCidade.resetFields();
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: '', filtro: '', ordem: '+cnf_id' }})
        
        editando.setEditando(false);
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    }
    return (
        <Drawer title="Configurações Cidades para emissão de NFSE "
            width="60%"
            visible={ui.showDrawer}
            limparAoEditar={true}
            fecharDrawer={() => { fecharDrawer() }}
            closeIcon={<MinusOutlined />}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formCidade.submit()} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <ManutencaoCidade formulario={formCidade} carregando={setCarregando} aoSalvar={fecharDrawer} editando={editando}/>
        </Drawer>

    )
}
