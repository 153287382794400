import React, { useEffect, useState } from "react";
import { Form, Col, Input, notification, Select, } from "antd";
import { useStateValue } from "../../state";
import { listagemActions, manutencaoActions, selectPaginadoActions } from "../../actions";
import api from "../../services/api";
import { SelectPaginacao } from "../../components";

export default function ManutencaoCorrelacaoCfop({ formulario, carregando, aoSalvar, editando }) {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [fileList, setFileList] = useState([]);
    const [listaTipo, setListaTipo] = useState([]);

    useEffect(() => {
        api.get(`Enum/Listar?nome=TipoNotaCorrelacaoCFOP`).then(res => {
            if (res.status === 200) {
                setListaTipo(res.data);
            }
        }).catch((erro) => console.error(erro));

    }, []);

    useEffect(() => {

        if (ui.showDrawer && !!manutencao.dados && editando.editando) {
            let lista = [];
            if(!!manutencao.dados.cfop_saida){
                lista.push({ name: "cfop_saida", campo: "Cfop", value: manutencao.dados.cfop_saida});
            }
            if (!!manutencao.dados.cfop_entrada) {
                lista.push({ name: "cfop_entrada", campo: "Cfop", value: manutencao.dados.cfop_entrada});
            }
            if (lista.length > 0) {
                dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
                editando.setEditando(false);
            }
        }
    }, [ui.showDrawer, manutencao.dados]);

    const salvarManutencao = (values) => {
        carregando(true);
        let retorno;
        let enderecoUrl = 'CorrelacaoCfop/';

        if (values.crr_id) {
            enderecoUrl += `Editar`;
            api.put(enderecoUrl, values).then(res => {
                if (res.status === 200) {
                    notification.success({ message: 'Operação concluída', description: "As novas configurações foram salvas com sucesso!" });
                    retorno = res.data;
                }
            }, err => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
            }).finally(
                () => {
                    carregando(false);
                    aoSalvar(retorno);
                    setFileList([]);
                })
        } else {
            enderecoUrl += `Incluir`;
            api.post(enderecoUrl, values).then(res => {
                if (res.status === 200) {
                    notification.success({ message: 'Operação concluída', description: "AS configurações foram incluidas com sucesso!" });
                    retorno = res.data;
                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: res.data } });
                }
            }, err => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
            }).finally(
                () => {
                    carregando(false);
                    aoSalvar(retorno);
                    dispatch({ type: listagemActions.CHANGE, data: { ordem: '-crr_id' } });
                }
            );
        }
    }

    return (
        <Form layout="vertical" form={formulario} name="ManutencaoCorrelacaoCfop" onFinish={salvarManutencao}>
            <Form.Item name="crr_id" hidden={true}> <Input />
            </Form.Item>

            <Col xs={24} sm={18} md={20} lg={20} xl={20}>
                <Form.Item label="CFOP Saida" name="cfop_saida">
                    <SelectPaginacao url="Cfop/Buscar" placeholder="Selecione o CFOP de saida" nameLabel="cfo_cfop" nameValue="cfop_saida" form={formulario} selecionarRegUnico="cfo_cfop"
                        conteudo={
                            cfo => (<Select.Option value={cfo.cfo_cfop} key={cfo.key}>{cfo.cfo_cfop} - {cfo.cfo_descricao}</Select.Option>)
                        } />
                </Form.Item>
            </Col>


            <Col xs={24} sm={18} md={20} lg={20} xl={20}>
            <Form.Item label="CFOP Entrada" name="cfop_entrada">
                    <SelectPaginacao url="Cfop/Buscar" placeholder="Selecione o CFOP de saida" nameLabel="cfo_cfop" nameValue="cfop_entrada" form={formulario} selecionarRegUnico="cfo_cfop"
                        conteudo={
                            cfo => (<Select.Option value={cfo.cfo_cfop} key={cfo.key}>{cfo.cfo_cfop} - {cfo.cfo_descricao}</Select.Option>)
                        } />
                </Form.Item>
            </Col>

            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <Form.Item label="Tipo de nota" name="crr_tiponota" rules={[{ required: true }]}>
                    <Select showSearch options={listaTipo.map((item) => {
                        return { label: item.value, value: item.key, key: item.key }
                    })} filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0} placeholder="Selecione Tipo de Nota" />
                </Form.Item>
            </Col>
        </Form>
    );

}
