import DrawerCidade from './drawer';
import api from '../../services/api';
import { useEffect } from 'react';
import { useStateValue } from '../../state';
import { drawerActions, listagemActions, manutencaoActions } from '../../actions';
import { Col, Modal, notification, Popover, Row, Tag } from 'antd';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { BreadcrumbIncluirDados, Duplicar, Editar, Excluir, PesquisaOrdenacao, TabelaDados } from '../../components';
import { useState } from 'react';


export default function Cidade() {

    const [{ ui, manutencao, SelectPaginado }, dispatch] = useStateValue();
    const [editando, setEditando] = useState(false);
    const [listaProvedor, setListaProvedor] = useState([]);
    const [listaVersao, setListaVersao] = useState([]);

    useEffect(() => dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: '', filtro: '', ordem: '+cnf_id' } }), [listagemActions]);

    useEffect(() => {
        api.get(`Enum/Listar?nome=NFSeProvedor`).then(res => {
            if (res.status === 200) {
                setListaProvedor(res.data);
            }
        }).catch((erro) => console.error(erro));

        api.get(`Enum/Listar?nome=VersaoNFSe`).then(res => {
            if (res.status === 200) {
                setListaVersao(res.data);
            }
        }).catch((erro) => console.error(erro));

    }, []);

    function retornaDescricaoProvedor(cnf_provedornfse) {
        if (!!listaProvedor) {
            let provedor = listaProvedor.filter((pro) => (pro.key === cnf_provedornfse))[0];
            return !!provedor ? provedor.value : "";
        }
    };

    function retornaVersaoNfse(cnf_versaonfse) {
        if (!!listaVersao) {
            let versao = listaVersao.filter((pro) => (pro.key === cnf_versaonfse))[0];
            return !!versao ? versao.value : "";
        }
    };


    function excluirRegistro(dados) {
        api.delete(`CidadeConfigNfse/Excluir?id=${dados.cnf_id}`).then(
            (res) => {
                notification.success({ message: `Sucesso!`, description: `Configurações excluidas com sucesso!` });
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
            }).catch(
                (erro) => {
                    notification.error({ message: "erro", description: `${erro}` })
                }
            ).finally(
                () => {
                    dispatch({ type: listagemActions.CHANGE, data: { ordem: '+cnf_id' } });
                });
    }


    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(dados);
            }
        });
    };

    function editandoCidade(dados) {
        setEditando(true);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    }

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <PesquisaOrdenacao opcoesOrdenacao={[
                { label: "Descrição Crescente", value: "+cnf_id" },
                { label: "Descrição Decrescente", value: "-cnf_id" }
            ]} />
            <div className="tabela">
                <TabelaDados url="CidadeConfigNfse/Listar"
                    colunas={[
                        {
                            title: 'Cidades',
                            render: ({ cidade }) => (
                                <div>
                                    <b>{cidade?.cid_descricao}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Provedor de NFSE',
                            render: ({ cnf_provedornfse }) => {
                                return (
                                    <div>
                                        <b>
                                            {retornaDescricaoProvedor(cnf_provedornfse)}
                                        </b>
                                    </div>
                                );
                            },
                        },
                        {
                            title: 'Versão NFSE',
                            render: ({ cnf_versaonfse }) => {
                                return (
                                    <div>
                                        <b>{retornaVersaoNfse(cnf_versaonfse)}</b>
                                    </div>
                                );
                            },
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            key: 'x',
                            align: 'center',
                            width: 95,
                            fixed: 'right',
                            render: (dados) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Editar
                                                onClick={() => {
                                                    editandoCidade(dados);
                                                }} icon={<EditOutlined />}
                                            />
                                        </Col>
                                        <Col>
                                            <Excluir icon={<DeleteOutlined />} onClick={() => { modalExcluir(dados) }} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]} />
            </div>
            <DrawerCidade cadCidade={true} editando={{ editando, setEditando }} />
        </div>
    );
}