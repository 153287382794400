import React, { useState, useEffect } from 'react';
import { Row, Col, Descriptions, Tabs } from "antd";

import { TabDetalheItens, TabDetalheIFormaPagamento, TabDetalheRelacaoNota, TabDetalheHistorico } from './tabPage';
import moment from 'moment';
import { FormatFone, MaskFormat } from '../../ValueObjects/index';
import api from '../../services/api';

export default function DetalhePedido({ dadosModal }) {

    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [pedido, setPedido] = useState({});
    const [dadosNota, setDadosNota] = useState([]);
    const [abaRelacionamento, setAbaRelacionamento] = useState(false);
    const [listaStatusNota, setListaStatusNota] = useState([]);

    useEffect(() => {
        if (!!dadosModal) {
            setPedido(dadosModal);
            retornaStatusNF();
            buscarRelacionamento();
        }
    }, [dadosModal]);

    function retornaStatusNF() {
        api.get('Enum/Listar?nome=StatusNFe').then(
            (res) => {
                if (res.status === 200) {
                    setListaStatusNota(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )
    };

    async function buscarRelacionamento() {
        let retorno = await api.get(`Pedido/ListarRelacionamento?IdPed=${dadosModal.ped_id}`);
        if (retorno.status === 200) {
            if (retorno.data.length > 0) {
                setDadosNota(retorno.data);
                setAbaRelacionamento(true);
            }
        }
    }

    return (
        <div>
            <Row align="middle">
                <Col>
                    <Descriptions size="small">
                        <Descriptions.Item label="Destinatário">
                            <b>{(!!pedido.pes_nome ? pedido.pes_nome : 'NÃO INFORMADO')}</b>
                        </Descriptions.Item>

                    </Descriptions>
                </Col>
            </Row>
            <Row align="middle">
                <Col>
                    <Descriptions column={{ xxl: 3, xl: 3, lg: 3, md: 2, sm: 2, xs: 1 }} size="small">
                    <Descriptions.Item label="CPF/CNPJ">
                            <b>{(!!pedido.pef_cpf ? MaskFormat(pedido.pef_cpf, '', true) : (!!pedido.pej_cnpj ? MaskFormat(pedido.pej_cnpj, '', true) : 'NÃO INFORMADO'))}</b>
                        </Descriptions.Item>
                             <Descriptions.Item label="Nº">
                            <b>{pedido.ped_numero}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="IE">
                            <b>{(!!pedido.ped_iedest ? pedido.ntf_iedest : 'NÃO INFORMADO')}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Vendedor">
                            <b>{(!!pedido.ven_nome ? pedido.ven_nome : 'NÃO INFORMADO')}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Data de Emissão">
                            <b>{!!pedido.ped_datainclusao ? moment(pedido.ped_datainclusao).format('DD/MM/YYYY') : null}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="E-mail">
                            <b>{(!!pedido.pem_email ? pedido.pem_email : 'NÃO INFORMADO')}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Telefone">
                            <b>{!!pedido.pes_telefone ? FormatFone(pedido.pes_telefone) : 'NÃO INFORMADO'}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Operação">
                            <b>{pedido.ope_descricao}</b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row align="middle">
                <Col>
                    <Descriptions size="small">
                        <Descriptions.Item label="Endereço">
                            <b>
                                {(!!pedido.pes_endereco ? pedido.pes_endereco : ' NÃO INFORMADO ')},
                                {(!!pedido.pes_bairro ? pedido.pes_bairro : ' NÃO INFORMADO ')},
                                {(!!pedido.pes_cidade ? pedido.pes_cidade : ' NÃO INFORMADO ')+'-'+(!!pedido.pes_uf ? pedido.pes_uf : ' NÃO INFORMADO ')},
                                {(!!pedido.pes_cep ? MaskFormat(pedido.pes_cep, '00000-000') : ' NÃO INFORMADO')}.
                            </b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row align="middle">
                <Col>
                    <Descriptions size="small">
                        <Descriptions.Item label="Observação Pedido">
                            <b>
                                {!!pedido.ped_observacao ? pedido.ped_observacao : ''}
                            </b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <div className="m-t-8">
                <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                    <Tabs.TabPane tab="Itens" key="1">
                        <TabDetalheItens itensNF={pedido.itensNf} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Formas de Pagamento" key="2">
                        <TabDetalheIFormaPagamento formaPagamento={pedido.formaPgto} />
                    </Tabs.TabPane>
                    {abaRelacionamento && <Tabs.TabPane tab="Notas Fiscais" key="3">
                        <TabDetalheRelacaoNota dadosNota={dadosNota} listaStatusNota={listaStatusNota} />
                    </Tabs.TabPane>}
                    <Tabs.TabPane tab="Histórico" key="4">
                        <TabDetalheHistorico historico={pedido.historico} />
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div>
    );
}