import { Modal, notification, Row } from "antd";
import { func } from "prop-types";
import { getEmpresa, getIdUsuario, getLocal, getToken, linkApi } from "./auth";
import moment from 'moment';
import api from "./api";
import axios from 'axios';

export const calculaDigitoVerificadorEAN = (numeroEan) => {
    var multiplicador = [1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3];
    var somaEAN = 0;
    var divisao = 0;
    var divisaoArredondada = 0;
    var resultado = 0;
    var digitoVerificador;
    var Ean = numeroEan.toString();
    for (var i = 0; i < 12; i++) {
        somaEAN += Ean[i] * multiplicador[i];
    };

    divisao = somaEAN / 10;
    divisaoArredondada = Math.floor(divisao) + 1;
    resultado = divisaoArredondada * 10;
    digitoVerificador = resultado - somaEAN;
    if (digitoVerificador > 9) {
        digitoVerificador = 0;
    }
    return digitoVerificador;
};

export const validaForm = (formulario, listaValidacoes) => {
    let retorno = false;
    if (!!formulario && !!listaValidacoes) {
        let formObj = formulario.getFieldValue();
        let msgRetorno = [];
        listaValidacoes.forEach((validacao) => {
            const valorCampo = formObj[validacao.nome];

            // Verifica se o campo é obrigatório e está vazio, contém apenas espaços ou é inválido
            if (
                validacao.obrigatorio &&
                (
                    valorCampo === undefined || 
                    valorCampo === null || 
                    (typeof valorCampo === 'string' && valorCampo.trim() === '') ||
                    (typeof valorCampo === 'number' && isNaN(valorCampo))
                )
            ) {
                msgRetorno.push('O campo ' + validacao.label + ' é obrigatório!');
            }
        });

        if (msgRetorno.length > 0) {
            msgRetorno.forEach((msg) =>
                notification.warning({ message: 'Aviso', description: msg })
            );
        } else {
            retorno = true;
        }
    }
    return retorno;
};



export const encodeBase64 = (data) => {
    return Buffer.from(data).toString('base64');
}

export const decodeBase64 = (data, type = 'ascii') => {
    return Buffer.from(data, 'base64').toString(type);;
}

export const parseToken = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(decodeBase64(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export function diferencaDatas(dataInicial, dataFinal = new Date()) {
    var d1 = new Date(dataInicial).getTime();
    var d2 = new Date(dataFinal).getTime();
    var df = Math.abs(d1 - d2);
    return Math.round(df / (24 * 60 * 60 * 1000));
};

export function compararDatas(dataInicial, dataFinal, operacao = '>=') {
    dataFinal = (!!dataFinal) ? dataFinal : new Date();
    dataInicial = new Date(dataInicial);
    dataFinal = new Date(dataFinal);
    dataInicial.setHours(0, 0, 0, 0);
    dataFinal.setHours(0, 0, 0, 0);
    dataInicial = dataInicial.getTime();
    dataFinal = dataFinal.getTime();
    let validacao = false;
    switch (operacao) {
        case '=':
            validacao = dataInicial === dataFinal;
            break;
        case '>':
            validacao = dataInicial > dataFinal;
            break;
        case '<':
            validacao = dataInicial < dataFinal;
            break;
        case '>=':
            validacao = dataInicial >= dataFinal;
            break;
        case '<=':
            validacao = dataInicial <= dataFinal;
            break;
    }

    return validacao;
};
export const isDate = (date) => {
    const _regExp = new RegExp('^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$');
    return _regExp.test(date);
};

export function validarNumeros(valor) {
    let numero = 0;

    if (!!valor && !isNaN(parseFloat(valor))) {
        numero = parseFloat(valor);
    }
    return numero;
};

export function validarDadosObj(dados) {
    let retorno = {};
    let valido = false;
    if (!!dados) {
        for (let key in dados) {
            if (!!dados[key]) {
                valido = true;
            }
        }
        if (valido) {
            retorno = dados;
        }
    }
    return retorno;
};

export function validarCpf(cpf) {
    if (cpf !== '') {
        cpf = cpf.replace(/[^\d]+/g, '');

        if (cpf.length !== 11 ||
            cpf === "00000000000" ||
            cpf === "11111111111" ||
            cpf === "22222222222" ||
            cpf === "33333333333" ||
            cpf === "44444444444" ||
            cpf === "55555555555" ||
            cpf === "66666666666" ||
            cpf === "77777777777" ||
            cpf === "88888888888" ||
            cpf === "99999999999") {
            return false;
        }

        let add = 0;
        let i;
        for (i = 0; i < 9; i++)
            add += parseInt(cpf.charAt(i)) * (10 - i);
        let rev = 11 - (add % 11);
        if (rev === 10 || rev === 11)
            rev = 0;
        if (rev !== parseInt(cpf.charAt(9))) {
            return false;
        }

        add = 0;
        for (i = 0; i < 10; i++)
            add += parseInt(cpf.charAt(i)) * (11 - i);
        rev = 11 - (add % 11);
        if (rev === 10 || rev === 11)
            rev = 0;
        if (rev !== parseInt(cpf.charAt(10))) {
            return false;
        }
        return true;
    }
};

export function validarCnpj(cnpj) {
    if (cnpj !== '') {
        cnpj = cnpj.replace(/[^\d]+/g, '');

        if (cnpj.length !== 14) {
            return false;
        }

        // Elimina CNPJs invalidos conhecidos
        if (cnpj === "00000000000000" ||
            cnpj === "11111111111111" ||
            cnpj === "22222222222222" ||
            cnpj === "33333333333333" ||
            cnpj === "44444444444444" ||
            cnpj === "55555555555555" ||
            cnpj === "66666666666666" ||
            cnpj === "77777777777777" ||
            cnpj === "88888888888888" ||
            cnpj === "99999999999999") {
            return false;
        }

        // Valida DVs
        var tamanho = cnpj.length - 2
        var numeros = cnpj.substring(0, tamanho);
        var digitos = cnpj.substring(tamanho);
        var soma = 0;
        var pos = tamanho - 7;
        var i;
        for (i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }

        var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0)) {
            return false;
        }

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }

        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1)) {
            return false;
        }

        return true;
    }
};
export function formatarCpfCnpj(cpfCnpj) {
    if (HasValue(cpfCnpj)) {
        cpfCnpj = cpfCnpj.replace(/[^\d]+/g, '');

        if (cpfCnpj.length === 14)
            return formatarCnpj(cpfCnpj);

        return formatarCpf(cpfCnpj);
    }
    return '';
};
export function formatarCpf(cpf) {
    let valor = '';
    if (!!cpf && cpf !== '') {
        valor = cpf.slice(0, 3) + '.' + cpf.slice(3, 6) + '.' + cpf.slice(6, 9) + '-' + cpf.slice(9, 11);
    }
    return valor;
};

export function formatarCnpj(cnpj) {
    let valor = '';
    if (!!cnpj && cnpj != '') {
        if (cnpj.valor !== undefined) {
            valor = cnpj.valor.slice(0, 2) + '.' + cnpj.valor.slice(2, 5) + '.' + cnpj.valor.slice(5, 8) + '/' + cnpj.valor.slice(8, 12) + '-' + cnpj.valor.slice(12, 15);
        } else {
            valor = cnpj.slice(0, 2) + '.' + cnpj.slice(2, 5) + '.' + cnpj.slice(5, 8) + '/' + cnpj.slice(8, 12) + '-' + cnpj.slice(12, 15);
        }
    }
    return valor;
};

export const getBase64 = file => {
    return new Promise(resolve => {
        let baseURL = "";
        let reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => {
            baseURL = reader.result;
            resolve(baseURL);
        };
    });
};

export function novaAbaNavegador(caminho) {
    return window.open(linkApi + caminho, '_blank');
};

export const openPdfNewTab = async (_url) => {
    try {
        let token = getToken();
        const response = await axios.get(linkApi + _url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: 'arraybuffer',
        });
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
    } catch (error) {
        console.error('Erro ao buscar o PDF:', error);
    }
};

export function isObjetoDiffVazio(obj = {}) {
    let valido = false;

    if (typeof obj === 'object') {
        if (!!obj && JSON.stringify(obj) !== '{}') {
            valido = true;
        }
    }
    return valido;
};

export function retornaValorPorExtenso(valor) {

    var ex = [
        ["zero", "um", "dois", "três", "quatro", "cinco", "seis", "sete", "oito", "nove", "dez", "onze", "doze", "treze", "quatorze", "quinze", "dezesseis", "dezessete", "dezoito", "dezenove"],
        ["dez", "vinte", "trinta", "quarenta", "cinquenta", "sessenta", "setenta", "oitenta", "noventa"],
        ["cem", "cento", "duzentos", "trezentos", "quatrocentos", "quinhentos", "seiscentos", "setecentos", "oitocentos", "novecentos"],
        ["mil", "milhão", "bilhão", "trilhão", "quadrilhão", "quintilhão", "sextilhão", "setilhão", "octilhão", "nonilhão", "decilhão", "undecilhão", "dodecilhão", "tredecilhão", "quatrodecilhão", "quindecilhão", "sedecilhão", "septendecilhão", "octencilhão", "nonencilhão"]
    ];
    valor = valor.toString().replace('.', ',');
    var a, n, v, i, n = valor.toString().replace(valor ? /[^,\d]/g : /\D/g, "").split(","), e = " e ", $ = "real", d = "centavo", sl;
    for (var f = n.length - 1, l, j = -1, r = [], s = [], t = ""; ++j <= f; s = []) {
        j && (n[j] = (("." + n[j]) * 1).toFixed(2).slice(2));
        if (!(a = (v = n[j]).slice((l = v.length) % 3).match(/\d{3}/g), v = l % 3 ? [v.slice(0, l % 3)] : [], v = a ? v.concat(a) : v).length) continue;
        for (a = -1, l = v.length; ++a < l; t = "") {
            if (!(i = v[a] * 1)) continue;
            i % 100 < 20 && (t += ex[0][i % 100]) ||
                i % 100 + 1 && (t += ex[1][(i % 100 / 10 >> 0) - 1] + (i % 10 ? e + ex[0][i % 10] : ""));
            s.push((i < 100 ? t : !(i % 100) ? ex[2][i == 100 ? 0 : i / 100 >> 0] : (ex[2][i / 100 >> 0] + e + t)) +
                ((t = l - a - 2) > -1 ? " " + (i > 1 && t > 0 ? ex[3][t].replace("?o", "?es") : ex[3][t]) : ""));
        }
        a = ((sl = s.length) > 1 ? (a = s.pop(), s.join(" ") + e + a) : s.join("") || ((!j && (n[j + 1] * 1 > 0) || r.length) ? "" : ex[0][0]));
        a && r.push(a + (valor ? (" " + (v.join("") * 1 > 1 ? j ? d + "s" : (/0{6,}$/.test(n[0]) ? "de " : "") + $.replace("l", "is") : j ? d : $)) : ""));
    }
    return r.join(e);
};

export function info(mensagem, titulo = null) {
    Modal.info({
        title: (!!titulo ? titulo : 'Atenção!'),
        content: (
            <div>
                <p>
                    {mensagem}
                </p>
            </div>
        ),
    });
};
export function HasValue(value) {
    if (value === undefined || value === "" || value === null)
        return false
    return true
};
export function substituirCaracterEspecial(str) {
    str = str.replace('ç', 'c');
    str = str.replace('Ç', 'C');
    str = str.replace('á', 'a');
    str = str.replace('Á', 'A');
    str = str.replace('À', 'A');
    str = str.replace('à', 'a');
    str = str.replace('É', 'E');
    str = str.replace('é', 'e');
    str = str.replace('ó', 'o');
    str = str.replace('Ó', 'o');
    str = str.replace('í', 'i');
    str = str.replace('Í', 'i');
    str = str.replace('ñ', 'n');
    str = str.replace('Ñ', 'n');
    str = str.replace('ã', 'a');
    str = str.replace('Ã', 'A');
    str = str.replace('õ', 'o');
    str = str.replace('Õ', 'O');
    str = str.replace('ú', 'u');
    str = str.replace('Ú', 'U');
    str = str.replace('â', 'a');
    str = str.replace('Â', 'A');
    str = str.replace('ê', 'e');
    str = str.replace('Ê', 'E');
    str = str.replace('ô', 'o');
    str = str.replace('Ô', 'O');
    return str;

};

export function retornaObjeto(listaObj) {
    var objRetorno = {};
    for (var i = 0; i < listaObj.length; i++) {
        for (var attrname in listaObj[i]) {
            objRetorno[attrname] = listaObj[i][attrname];
        }
    }
    return objRetorno;
}

export var delay = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();


export function textoAleatorio(tamanho) {
    var aleatorio = '';
    if (!!tamanho) {
        var letras = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        for (var i = 0; i < tamanho; i++) {
            var rnum = Math.floor(Math.random() * letras.length);
            aleatorio += letras.substring(rnum, rnum + 1);
        }
    }
    return aleatorio;
};

export async function consultaCNPJ(cnpj) {
    cnpj = cnpj.replace(/\D/g, '');

    return jsonp(`https://www.receitaws.com.br/v1/cnpj/${encodeURI(cnpj)}`, 60000)
        .then((json) => {
            if (json['status'] === 'ERROR') {
                return Promise.reject(json['message']);
            } else {
                return Promise.resolve(json);
            }
        });
};

export function jsonp(url, timeout) {
    const func = 'jsonp_' + Math.random().toString(36).substr(2, 5);

    return new Promise(function (resolve, reject) {
        let script = document.createElement('script');

        let timer = setTimeout(() => {
            reject('Tempo limite atingido');
            document.body.removeChild(script);
        }, timeout);

        window[func] = (json) => {
            clearTimeout(timer);
            resolve(json);
            document.body.removeChild(script);
            delete window[func];
        };
        script.src = url + '?callback=' + encodeURI(func);
        document.body.appendChild(script);
    });
};

export const retornaSoNumeros = (valor) => {
    if(!HasValue(valor))
        return;
    return valor.replace(/\D/g, "");
};

export const retornaSoLetras = (valor) => {
    return valor.replace(/[^a-zA-Z]/g, '');
};

export const retornaCep = async (cep) => {
    cep = retornaSoNumeros(cep);
    if (!cep) {
        return null;
    }

    if (cep.length === 8) {
        return await api.get(`Cidade/BuscaCidadeCep?cep=${cep}`).then(
            res => {
                if (res.status === 200 && res.data.length > 0) {
                    return res.data[0];
                } else {
                  //  notification.warning({ message: 'Aviso!', description: 'Cep não encontrado' });
                    return null;
                }
            }
        ).catch((error) => {
            if (!!error.response && !!error.response.data) {
               notification.error({ message: 'Aviso', description: error.response.data });
            }
            else {
                notification.warning({ message: 'Atenção!', description: 'Erro ao buscar o CEP' });
            }
            return null;
        })
    } else {
        notification.warning({ message: 'Aviso!', description: 'Informe o Cep' })
        return null;
    }
};

export const trataRetornoFormularioDinamico = (values, elemento) => {
    if (!!Date.parse(values[elemento.cap_nomeelemento]) && typeof values[elemento.cap_nomeelemento] === "object" && values[elemento.cap_nomeelemento].toString().length > 30) {
        values[elemento.cap_nomeelemento] = moment(values[elemento.cap_nomeelemento])
    } else if (typeof values[elemento.cap_nomeelemento] === "object") {
        values[elemento.cap_nomeelemento] = values[elemento.cap_nomeelemento]?.join(',')
    }
    return values;
};

export const trataSetaDadosFormularioDinamico = (formulario, elemento, valor) => {
    switch (elemento.cap_tipoelemento) {
        case 'dataPicker':
            valor = moment(valor, "YYYY/MM/DD");
            break;
        case 'checkbox':
            valor = JSON.parse(valor);
            break;
    };
    formulario.setFieldsValue({ [elemento.cap_nomeelemento]: valor });
    return true;
};

export const parseBool = valor => {
    switch (valor) {
        case true:
        case "true":
        case 1:
        case "1":
        case "on":
        case "yes":
            return true;
        default:
            return false;
    }
};

export const arredonda = (valor, casasDec = 2, retorno) => {
    if (typeof valor === 'number') {
        valor = valor.toFixed(casasDec);
    } else if (typeof valor === 'string') {
        valor = parseFloat(valor).toFixed(casasDec);
    } else {
        console.log('Valor informado não identificado! Favor informar um valor valido (Number,String)');
        return '';
    }
    if (retorno === 'number') {
        valor = parseFloat(valor);
    }

    return valor;
};

export const removerCarcaterEspecial = (valor) => {
    valor = valor?.replaceAll(/[^a-zA-Z 0-9]+/g, '');
    return valor;
};

export const validarImportacao = (dados, entidade) => {
    if (dados.possuiErro) {
        Modal.error({
            title: `${entidade} não importado, favor verificar a planilha!`,
            content: (
                <>
                    {!!dados.campo && <Row justify="center">Coluna: <b>{dados.campo}</b>.</Row>}
                    <Row justify="center">Linha: <b>{dados.linha}</b>.</Row>
                    <Row justify="center"><b>{dados.mensagem}</b></Row>
                </>),
        });
    } else {
        notification.success({ description: `${entidade} importado com sucesso!`, message: 'Aviso' });
    }

};

export const imprimirRelatorioJasper = (idRelatorio, parametros = {}) => {
    api.get(`RelatorioJasper/BuscarRelatorio/${idRelatorio}`).then(
        res => {
            let dadosRel = res.data;
            let filtro = [];
            let params = [
                { "nome": "empresaLogado", "valor": getEmpresa() },
                { "nome": "localLogado", "valor": getLocal() },
                { "nome": "usuarioLogado", "valor": getIdUsuario() }
            ];
            dadosRel.relatorioParametro.forEach((param) => {
                if (!!!parametros[param.rpj_nomeelemento]) {
                    if (!!param.rpj_obrigatorio) {
                        notification.warn({ message: "Parâmetro não preenchido!", description: `O parâmetro ${param.rpj_label} não foi preenchido!` });
                        return;
                    }
                } else {
                    params.push({ "nome": param.rpj_nomeelemento, "valor": parametros[param.rpj_nomeelemento] });
                    filtro.push(`${param.rpj_label}: ${parametros[param.rpj_nomeelemento]}`);
                }
            });
            params.push({ "nome": "filtros", "valor": filtro.join(', ') });
            let dados = {};
            dados.pathRelatorio = dadosRel.rel_nomearquivo;
            dados.parametros = JSON.stringify(params).replaceAll("\"", "'");
            dados.tipo = "PDF";
            dados.nomeRelatorio = dadosRel.rel_descricao;
            api.post(`RelatorioJasper/RetornaDadosRelatorios`, dados).then(
                res => {
                    let byteCharacters = decodeBase64(res.data, 'latin1');
                    let byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    let byteArray = new Uint8Array(byteNumbers);
                    let file = new Blob([byteArray], { type: 'application/pdf;base64' });
                    let fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }
            ).catch(
                erro => {
                    console.error(erro);
                }
            );
        }
    ).catch(
        erro =>
            console.error(erro)
    );
};

export const isNotNullOrEmpty = (registro) => {
    let retorno = false;
    if (!!registro || registro === 0) {
        retorno = true;
    }
    return retorno;
};

export function calculaValorProporcional(valorUnitario, valorRatear, valorTotal) {
    if (valorUnitario > 0 && valorRatear > 0 && valorTotal > 0) {
        return parseFloat(((valorUnitario * valorRatear) / valorTotal).toFixed(2));
    }
    return 0;
};

export function validarEan(eanCodigo) {
    if (!!eanCodigo) {
        let fator = 3;
        let sum = 0;
        let cc = 0;
        let ca = 0;
        let numlen = eanCodigo.length;
        let index = 0;
        if (numlen == 13) {
            for (index = numlen; index > 0; --index) {
                if (index != 13) {
                    sum = sum + eanCodigo.substring(index - 1, index) * fator;
                    fator = 4 - fator;
                }
            }
            cc = ((1000 - sum) % 10);
            ca = eanCodigo.substring(12);
            if (cc == ca) {
                return true;
            }
            else {
                return false;
            }
        }
        if (numlen == 14) {
            for (index = eanCodigo.length; index > 0; --index) {
                if (index != 14) {
                    sum = sum + eanCodigo.substring(index - 1, index) * fator;
                    fator = 4 - fator;
                }
            }
            cc = ((1000 - sum) % 10);
            ca = eanCodigo.substring(13);
            if (cc == ca) {
                return true;
            }
            else {
                return false;
            }
        }
        if (numlen == 8) {
            for (index = eanCodigo.length; index > 0; --index) {
                if (index != 8) {
                    sum = sum + eanCodigo.substring(index - 1, index) * fator;
                    fator = 4 - fator;
                }
            }
            cc = ((1000 - sum) % 10);
            ca = eanCodigo.substring(7);
            if (cc == ca) {
                return true
            }
            else {
                return false;
            }
        }
        if (((numlen != 8) && (numlen != 13) && (numlen != 14) && (numlen != 0))) {
            return false;
        }
    } else {
        return true;
    }


};

// ajustar tamanho e resolução de imagem base64, base64 string da imagem, width e height é a resolução da imagem, e por fim o tamanho maximo da imagem.
export async function ajustarTamanhoImagemBase64(base64String, maxWidth, maxHeight, maxSizeInBytes) {
    const img = new Image();
    img.src = base64String;
    await new Promise((resolve, reject) => {
        img.onload = () => {
            let width = img.width;
            let height = img.height;
            if (width > height) {
                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }
            }
            const canvas = document.createElement('canvas');
            canvas.width = 1200;
            canvas.height = 1200;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 1200, 1200, width, height);
            canvas.toBlob((blob) => {
                resolve(blob);
            }, 'image/jpeg', 1); // Qualidade da compressão (0.8 neste exemplo)
        };
        img.onerror = reject;
    });
    // Verifica o tamanho do blob
    const blob = await fetch(base64String).then((res) => res.blob());
    if (blob.size <= maxSizeInBytes) {
        return base64String; // Retorna se o tamanho já está dentro do limite
    }
    // Se o tamanho ainda for maior que o limite, reduz a qualidade da imagem
    const newQuality = 0.8; // Reduz a qualidade para alcançar o tamanho desejado
    //const newQuality = 0.6; // Reduz a qualidade para alcançar o tamanho desejado
    const compressedBase64 = await new Promise((resolve) => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 1200, 1200);
        canvas.toBlob((blob) => resolve(URL.createObjectURL(blob)), 'image/jpeg', newQuality);
    });
    return compressedBase64;
}

// Função para redimensionar e limitar o tamanho de uma imagem base64 em MB
export function resizeImageToMB(base64String, maxSizeInMB, maxWidth, maxHeight) {
    return new Promise((resolve, reject) => {
        const maxFileSize = maxSizeInMB * 1024 * 1024; // Tamanho máximo em bytes
        const imageQuality = 0.9; // Qualidade da imagem após compressão (0.7 é um valor de exemplo)
        let img = new Image();
        img.src = base64String;
        img.onload = function () {
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            const width = maxWidth;
            const height = maxHeight;
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);
            let compressedBase64 = canvas.toDataURL('image/jpeg', imageQuality);
            const base64FileSize = (compressedBase64.length * 0.75); // Calcula o tamanho em bytes
            if (base64FileSize > maxFileSize) {
                const scaleRatio = Math.sqrt(maxFileSize / base64FileSize);
                const scaledWidth = width * scaleRatio;
                const scaledHeight = height * scaleRatio;
                canvas.width = scaledWidth;
                canvas.height = scaledHeight;
                ctx.drawImage(img, 0, 0, scaledWidth, scaledHeight);
                compressedBase64 = canvas.toDataURL('image/jpeg', imageQuality);
            }
            resolve(compressedBase64); // Retorna a imagem redimensionada e limitada em MB como base64
        };
        img.onerror = function (error) {
            reject(error);
        };
    });
}
export function validarEmail(email) {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return reg.test(email);
};
export function validarTelefone(telefone) {
    const phoneRegex = /^[1-9]{2}9?[0-9]{8}$/; // Exemplo para validar números de telefone no Brasil
    return phoneRegex.test(telefone);
};
export function exibirMensagemException(execao) {
    let msg = "Houve um problema";
    let titulo = "Aviso";
    if (!!execao) {
        if (!!execao.response.data) {
            msg = execao.response.data.detail;
            titulo = execao.response.data.title;
        }
    }
    notification.warn({ message: titulo, description: msg });
}

export const arredondar = (numero, casasDecimais = 2, limiteArredondamento = 5) => {
    const fator = Math.pow(10, casasDecimais);
    const valorArredondado = Math.round(numero * fator) / fator;
    const proximaCasaDecimal = Math.round((numero * fator * 10) % 10);

    // Verifica se a próxima casa decimal é maior que o limite
    if (proximaCasaDecimal >= limiteArredondamento) {
        // Se for maior, arredonda para cima
        return Math.ceil(numero * fator) / fator;
    } else {
        // Se for menor ou igual, arredonda para baixo
        return Math.floor(numero * fator) / fator;
    }
};