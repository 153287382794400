import React, { useEffect, useState } from "react";
import { Row, Col, Descriptions, Form, Input, Modal, notification } from "antd";
import { ExclamationCircleOutlined, CloudUploadOutlined, CloseOutlined } from "@ant-design/icons";
import moment from "moment";

import api from "../../../services/api";
import { HasValue, novaAbaNavegador } from "../../../services/funcoes";
import { MaskFormat, FormatNumber } from "../../../ValueObjects";

export default function ModalReenviarSefaz({ dadosNota, exibirModalReenviarSefaz, fecharModalReenviarSefaz, titleModal, textoBotao }) {
    const [form] = Form.useForm();
    const [carregando, setCarregando] = useState(false);
    const modeloDescricao = { 55: "NF-e", 57: "CT-e", 65: "NFC-e", 98: "NFS-e", };

    const getDescricaoModelo = (codigo) => {
        return modeloDescricao[codigo] || "Modelo desconhecido";
    };

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                form.resetFields();
                fecharModalReenviarSefaz();
            }
        });
    };

    const realizarReenvioSefaz = () => {
        setCarregando(true);
        // Nota de Serviço
        if (dadosNota.ntf_modelo === "98") {
            let _link = `NotaFiscal/ReenviarRps/${dadosNota.ntf_id}`;

            api.post(_link).then(
                (retornoNF) => {
                    if (retornoNF.status === 200) {
                        if (retornoNF.data.ntf_status === 1) {
                            notification.success({ message: 'Sucesso', description: `NF-e Autorizada com sucesso!` });
                            novaAbaNavegador(`${retornoNF.data.controleloterps.clr_linknfse}`);
                            let formaPagItegrado = retornoNF.data.notafiscalformapgtos.filter((item) => (HasValue(item.imp_id)))
                            if (formaPagItegrado.length > 0) {
                                novaAbaNavegador(`Danfe/GerarComprovantePagamentoViaLoja?IdNotaFiscal=${retornoNF.data.ntf_id}`);
                            }
                        } else {
                            notification.error({ description: 'Não foi possível Autorizar nota fiscal!', message: `Aviso` });
                        }
                    } else {
                        notification.error({ description: 'Não foi possível Autorizar nota fiscal!', message: `Aviso` });
                    }
                }
            ).catch(
                (erro) => {
                    if (!!erro.response && !!erro.response.data) {
                        notification.error({ description: erro.response.data, message: `Aviso` });
                    } else {
                        notification.error({ description: 'Não foi possível Autorizar nota fiscal!', message: `Aviso` });
                    }
                    setCarregando(false);
                }
            ).finally(() => {
                setCarregando(false);
                form.resetFields();
                fecharModalReenviarSefaz();
            });
            console.log(dadosNota)
        } else if (dadosNota.ntf_modelo === "57") {
            let _link = `ConhecimentoTransporte/ReenviarCteSefaz/${dadosNota.ntf_id}`;
            api.post(_link).then(
                (retornoNF) => {
                    if (retornoNF.status === 200) {
                        if (retornoNF.data.ntf_status === 1) {
                            notification.success({ message: 'Sucesso', description: `CT-e Autorizado com sucesso!` });
                            novaAbaNavegador(`${retornoNF.data.controleloterps.clr_linknfse}`);
                            let formaPagItegrado = retornoNF.data.notafiscalformapgtos.filter((item) => (HasValue(item.imp_id)))
                            if (formaPagItegrado.length > 0) {
                                novaAbaNavegador(`Danfe/GerarComprovantePagamentoViaLoja?IdNotaFiscal=${retornoNF.data.ntf_id}`);
                            }
                        } else {
                            notification.error({ description: 'Não foi possível Autorizar o Conhecimento de Transporte Eletrônico!', message: `Aviso` });
                        }
                    } else {
                        notification.error({ description: 'Não foi possível Autorizar o Conhecimento de Transporte Eletrônico!' + retornoNF.data.ntf_xmotivo, message: `Aviso` });
                    }
                }
            ).catch(
                (erro) => {
                    if (!!erro.response && !!erro.response.data) {
                        notification.error({ description: erro.response.data, message: `Aviso` });
                    } else {
                        notification.error({ description: 'Não foi possível Autorizar o Conhecimento de Transporte Eletrônico!', message: `Aviso` });
                    }
                    setCarregando(false);
                }
            ).finally(() => {
                setCarregando(false);
                form.resetFields();
                fecharModalReenviarSefaz();
            });
            console.log(dadosNota)
        } else {
            let _link = `NotaFiscal/ReenviarNotaSefaz/${dadosNota.ntf_id}`;
            if (dadosNota.ntf_tiponota == 3 && dadosNota.ntf_finalidade === 3)// Nota ajuste
                _link = `NotaFiscalDebitoCredito/ReenviarNotaSefaz/${dadosNota.ntf_id}`;

            api.post(_link).then(
                (retornoNF) => {
                    if (retornoNF.status === 200) {
                        if (retornoNF.data.ntf_status === 1 && !!retornoNF.data.ntf_chavenfe) {
                            notification.success({ message: 'Sucesso', description: `NF-e Autorizada com sucesso!` });
                            novaAbaNavegador(`Danfe/GerarDanfe?chaveNFe=${retornoNF.data.ntf_chavenfe}`);
                            let formaPagItegrado = retornoNF.data.notafiscalformapgtos.filter((item) => (HasValue(item.imp_id)))
                            if (formaPagItegrado.length > 0) {
                                novaAbaNavegador(`Danfe/GerarComprovantePagamentoViaLoja?IdNotaFiscal=${retornoNF.data.ntf_id}`);
                            }
                        } else {
                            notification.error({ description: 'Não foi possível Autorizar nota fiscal! ' + retornoNF.data.ntf_xmotivo, message: `Aviso` });
                        }
                    } else {
                        notification.error({ description: 'Não foi possível Autorizar nota fiscal!', message: `Aviso` });
                    }
                }
            ).catch(
                (erro) => {
                    if (!!erro.response && !!erro.response.data) {
                        notification.error({ description: erro.response.data, message: `Aviso` });
                    } else {
                        notification.error({ description: 'Não foi possível Autorizar nota fiscal!', message: `Aviso` });
                    }
                    setCarregando(false);
                }
            ).finally(() => {
                setCarregando(false);
                form.resetFields();
                fecharModalReenviarSefaz();
            });
            console.log(dadosNota)
        }
    }
    return (
        <Modal centered
            title={titleModal}
            visible={exibirModalReenviarSefaz}
            onCancel={onCloseModal}
            onOk={realizarReenvioSefaz}
            confirmLoading={carregando}
            okText={
                <>
                    <CloudUploadOutlined /> Reenviar {getDescricaoModelo(dadosNota.ntf_modelo)} Sefaz
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
            destroyOnClose
        >
            <Form layout="vertical" form={form}>
                <Descriptions size="small">
                    <Descriptions.Item label={`Chave da ${getDescricaoModelo(dadosNota.ntf_modelo)}`}>
                        <b> {dadosNota.ntf_chavenfe} </b>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions size="small">
                    <Descriptions.Item label="Destinatário">
                        <b> {dadosNota.ntf_nomedest}</b>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                    <Descriptions.Item label="CPF/CNPJ">
                        <b>{MaskFormat(dadosNota.ntf_cnpjcpfdest, '', true)}</b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Nº da Nota">
                        <b>{dadosNota.ntf_numero}</b>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                    <Descriptions.Item label="Série">
                        <b>{dadosNota.ntf_serie}</b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Modelo">
                        <b>{dadosNota.ntf_modelo}</b>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                    <Descriptions.Item label="Emitida em">
                        <b>{moment(dadosNota.ntf_dataemissao).format('DD/MM/YYYY')}</b>
                    </Descriptions.Item>
                </Descriptions>
                {dadosNota.ntf_modelo === "98" && (
                    <>
                        <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                            <Descriptions.Item label="Nº Lote">
                                <b>{dadosNota.clr_numerolote}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="Nº RPS">
                                <b>{dadosNota.clr_numerorps}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </>
                )
                }

                <Descriptions size="small">
                    <Descriptions.Item label="Valor Total da Nota (R$)">
                        <b> {FormatNumber(dadosNota.valor_total, true)} </b>
                    </Descriptions.Item>
                </Descriptions>
            </Form >
        </Modal >
    );
}