import React, { useState, useEffect } from "react";
import { Button, Card, Col, Divider, Form, Input, InputNumber, Modal, notification, Radio, Row, Select, Table, Tag } from "antd";

import api from "../../services/api";
import { InputPreco, SelectPaginacao, SelectProduto } from "../../components";
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { FormatNumber } from "../../ValueObjects";
import { useStateValue } from "../../state";

export default function TabDadosGerais({ formConhecimento, tipoCTe, modalCTe, tipoServico, dadosOperacao, listaDocs, dadosProdutos, unidadeCTe, componentesFrete, dadosContribuinte, dadosTomador }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [formProduto] = Form.useForm();
    const [exibirDetalhes, setExibirDetalhes] = useState(false);
    const [editarItem, setEditarItem] = useState(false);
    const [editandoComponente, setEditandoComponente] = useState(false);
    const [listaSelectProd, setListaSelectProd] = useState([]);
    const [dadosCfop, setDadosCfop] = useState([]);
    const [listaComponentes, setListaComponentes] = useState([]);
    const [dadosDetalhes, setDadosDetalhes] = useState({});
    const [componenteEditando, setComponenteEditando] = useState({});

    useEffect(() => {
        carregaDados();
    }, [])

    function carregaDados() {
        api.get(`Cfop/ListarCfopTipo?tipoCfopFim=8000&tipoCfopIni=1000`).then(
            (res) => {
                if (res.status === 200) {
                    setDadosCfop(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )

        api.get(`ComponentesFrete/Listar`).then(
            (res) => {
                if (res.status === 200) {
                    setListaComponentes(res.data.items);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )

        formConhecimento.setFieldsValue({
            cte_tipocte: tipoCTe[0].key,
            cte_modal: modalCTe[0].key,
            cte_tiposervico: tipoServico[0].key,
            cte_indglobalizado: 0,
            cic_qntcarga: 1
        })
    }

    function adicionarComponente() {
        let vlrProd = dadosProdutos.dadosProdutos[0]?.nfi_valorunitario || 0;

        if (vlrProd > 0) {
            let valorAtual = parseFloat(formProduto.getFieldValue()?.ccf_valor) || 0;
            let totalComponentes = (componentesFrete.componentesFrete || []).reduce((acc, item) => acc + (item.ccf_valor || 0), 0);
            let valorTotal = valorAtual + totalComponentes - (editandoComponente ? componenteEditando.ccf_valor : 0);

            let dados = {
                cmf_id: formProduto.getFieldValue()?.cmf_id,
                ccf_valor: valorAtual
            };

            if (dados.cmf_id && dados.ccf_valor > 0) {
                let dadosComp = listaComponentes.filter((item) => item.cmf_id === dados.cmf_id);
                dados.cmf_descricao = dadosComp[0].cmf_descricao;

                if (valorTotal <= vlrProd) {
                    let listaTemp = [...componentesFrete.componentesFrete];

                    if (editandoComponente) {
                        dados.cmf_sequencia = formProduto.getFieldValue().cmf_sequencia
                        let index = listaTemp.findIndex(comp => comp.cmf_sequencia === dados.cmf_sequencia);

                        if (index !== -1) {
                            listaTemp[index] = dados;
                        }
                        notification.success({ message: 'Aviso!', description: 'Componente editado com sucesso' });
                    } else {
                        dados.cmf_sequencia = 1 + componentesFrete.componentesFrete.length;
                        listaTemp.push(dados);
                        notification.success({ message: 'Aviso!', description: 'Componente de Frete adicionado com sucesso' });
                    }

                    componentesFrete.setComponentesFrete(listaTemp);
                    formProduto.setFieldsValue({
                        cmf_sequencia: null,
                        cmf_id: null,
                        ccf_valor: null
                    })
                    setEditandoComponente(false);
                    setComponenteEditando({});
                } else {
                    notification.warning({ message: 'Atenção!', description: 'Valor total dos componentes de frete maior do que o valor do produto' });
                }
            } else {
                notification.warning({ message: 'Atenção!', description: 'Preencha todos os campos para adicionar' });
            }
        } else {
            notification.warning({ message: 'Atenção!', description: 'Adicione um produto antes de incluir um componente de frete' });
        }
    }

    function editarComponente(record) {
        setEditandoComponente(true);
        formProduto.setFieldsValue({
            cmf_sequencia: record.cmf_sequencia,
            cmf_id: record.cmf_id,
            ccf_valor: record.ccf_valor
        })
        setComponenteEditando({
            ccf_valor: record.ccf_valor
        })
    }

    function cancelarEdit() {
        setEditandoComponente(false);
        formProduto.setFieldsValue({
            cmf_sequencia: null,
            cmf_id: null,
            ccf_valor: null
        })
    }

    function removerComponente(record) {
        let index = componentesFrete.componentesFrete.findIndex(comp => comp.cmf_sequencia === record.cmf_sequencia);
        let dadosTemp = [...componentesFrete.componentesFrete];

        dadosTemp.splice(index, 1)
        dadosTemp.forEach((item, index) => {
            item.cmf_sequencia = index + 1;
        })

        if (editandoComponente) {
            cancelarEdit();
        }
        componentesFrete.setComponentesFrete(dadosTemp);
    }

    function onClickBtnCancelar() {
        cancelar();
    }

    function onClickBtnAdicionar() {
        adicionarItem();
    }

    function cancelar() {
        setExibirDetalhes(false);
        setDadosDetalhes({});
        limparCampos();
        if (editarItem) {
            setEditarItem(false);
        }
    }

    function editarItemCTe(registro) {
        let dadosTemp = {};

        setEditarItem(true);
        formProduto.setFieldsValue({
            pro_id: registro.pro_id,
            nfi_qtde: registro.nfi_qtde,
            nfi_valorunitario: registro.nfi_valorunitario,
            numeroItem: registro.numeroItem,
            nfi_cfop: registro.nfi_cfop,
            nfi_unidademedida: registro.nfi_unidademedida,
            icm_id: registro.icm_id
        });
        dadosTemp = {
            pro_codigo: registro.pro_codigo,
            pro_descricao: registro.pro_descricao,
            prg_ean: registro.prg_ean,
        }
        setDadosDetalhes(dadosTemp);
        setExibirDetalhes(true);
    }

    function removerProdutoCTe(registro) {
        let dadosTemp = [...dadosProdutos.dadosProdutos];
        dadosTemp = dadosTemp.filter((dados) => dados !== registro);
        dadosTemp = dadosTemp
            .sort((a, b) => a.numeroItem - b.numeroItem)
            .map((dados, index) => ({
                ...dados,
                numeroItem: dadosTemp.length - index
            }));
        dadosProdutos.setDadosProdutos(dadosTemp);
        componentesFrete.setComponentesFrete([]);
        setEditandoComponente(false);
        cancelar();
    }

    function limparCampos() {
        formProduto.setFieldsValue({ pro_id: null, nfi_cfop: null, icm_id: null });
    };

    function validarItem(dados) {
        if (dados.nfi_qtde === 0 || !dados.nfi_qtde) {
            notification.warning({ message: 'Aviso!', description: 'Quantidade de Produto inválida!' });
            return false;
        }
        if (parseFloat(dados.valorTotalItem) === 0 || parseFloat(dados.nfi_valorunitario) === 0) {
            notification.warning({ message: 'Aviso!', description: 'Valor do Produto inválido!' });
            return false;
        }
        if (!dados.nfi_cfop) {
            notification.warning({ message: 'Aviso!', description: 'Campo CFOP é obrigatório!' });
            return false;
        }
        return true;
    }

    function popularItem(editando, item, dadosEditando) {
        let itemCTe = {};
        let formulario = formProduto.getFieldValue();

        if (editando) {
            itemCTe = {
                ...dadosEditando,
                nfi_qtde: formulario.nfi_qtde,
                nfi_valorunitario: parseFloat(formulario.nfi_valorunitario),
                nfi_cfop: formulario.nfi_cfop,
                icm_id: formulario.icm_id,
                nfi_unidademedida: formulario.nfi_unidademedida,
            };
        } else {
            let cfop = dadosCfop.filter((cfop) => (cfop.cfo_cfop === formulario.nfi_cfop))[0];
            itemCTe = {
                pro_codigo: item.pro_codigo,
                pro_descricao: item.pro_descricao,
                pro_id: item.pro_id,
                icm_id: item.icm_id ?? dadosDetalhes.icm_id,
                prg_ean: !!dadosDetalhes.prg_ean ? dadosDetalhes.prg_ean : dadosDetalhes.pro_ean,
                nfi_cfop: cfop.cfo_cfop,
                nfi_unidademedida: item.nfi_unidademedida,
                nfi_qtde: formulario.nfi_qtde,
                nfi_valorunitario: parseFloat(formulario.nfi_valorunitario ?? 0)
            };
        }
        return itemCTe;
    }

    function incluirItem(dados, listaItens) {
        listaItens.push(dados);
        dadosProdutos.setDadosProdutos(listaItens);
        notification.success({ message: 'Aviso', description: 'Produto incluído com sucesso!' });
    }

    function alterarItem(dados, listaItens, dadosEditando) {
        let indice = listaItens.findIndex((item) => (item === dadosEditando));
        listaItens.splice(indice, 1, { ...dados });
        dadosProdutos.setDadosProdutos(listaItens);
        notification.success({ message: 'Aviso', description: 'Produto editado com sucesso!' });
    }

    async function adicionarItem(item = {}) {
        let dados = {}
        let dadosForm = formProduto.getFieldValue();
        let listaItens = [...dadosProdutos.dadosProdutos];

        if (editarItem) {
            item = listaItens.filter((prod) => (prod.numeroItem === dadosForm.numeroItem))[0];
        } else {
            item = { ...item, ...dadosForm, ...dadosDetalhes }
        }

        if (!!validarItem(dadosForm)) {
            let dadosEditando = listaItens.filter((prod) => (prod.pro_id === item.pro_id))[0];
            if (!!dadosEditando) {
                dados = popularItem(true, item, dadosEditando);
                if (editarItem) {
                    setEditarItem(false);
                    alterarItem(dados, listaItens, dadosEditando)
                } else {
                    Modal.confirm({
                        title: 'Atualizar?',
                        icon: <ExclamationCircleOutlined />,
                        content: 'Produto já incluído no CTe, deseja atualizar suas informações?',
                        okText: 'Atualizar',
                        cancelText: 'Incluir',
                        centered: true,
                        onOk() {
                            alterarItem(dados, listaItens, dadosEditando)
                        },
                        onCancel() {
                            dados.numeroItem = listaItens.length + 1;
                            incluirItem(dados, listaItens);
                        }
                    })
                }
            } else {
                dados = popularItem(false, item);
                dados.numeroItem = listaItens.length + 1;
                incluirItem(dados, listaItens);
            }
            setExibirDetalhes(false);
            limparCampos();
        }
    }

    async function buscarProduto(produto) {
        let dadosDoc = listaDocs[0];
        let listaItens = [...listaSelectProd];
        let prod = listaItens.filter((item) => item.key === produto)[0];

        let destinoOperacao = (!!dadosTomador.cta_siglauf && !!dadosContribuinte.est_sigla && (dadosTomador.cta_siglauf === dadosContribuinte.est_sigla) ? '1' : dadosTomador.cta_siglauf === "EX" ? '3' : '2');
        let ufDest = !!dadosDoc.enderecosDest[0].est_sigla ? dadosDoc.enderecosDest[0].est_sigla : dadosContribuinte.est_sigla;
        let inscEstadual = !!dadosDoc.ie_destinatario ? `&inscEstadual=${dadosDoc.ie_destinatario}` : '';
        let objProd = (await api.get(`Produto/BuscarDadosProduto?IdProduto=${prod.pro_id}&ufDestino=${ufDest}`
            + `&operacaoCodigo=${dadosOperacao.ope_id}&ufOrigem=${dadosContribuinte.est_sigla}&tipoContribuinte=${dadosContribuinte.loc_tipocontribuinte}`
            + `&empresaEnquadramento=${dadosContribuinte.emp_regimetributario}&destinoOperacao=${destinoOperacao}${''}${inscEstadual}`
        )).data[0];

        if (objProd) {
            let valorVenda = (!!objProd.lpi_valorvenda ? objProd.lpi_valorvenda : 0);
            formProduto.setFieldsValue({
                nfi_cfop: objProd.cfo_cfop,
                nfi_qtde: 1,
                nfi_valorunitario: valorVenda,
                valorTotalItem: valorVenda,
                nfi_unidademedida: objProd.ump_id,
                icm_id: objProd.icm_id
            })
            setExibirDetalhes(true);
            setDadosDetalhes(objProd);
            setarCampoNoElemento("formProdutos_nfi_valorunitario");
        }
    }

    function setarCampoNoElemento(elemento, atraso = 500) {
        setTimeout(
            () => {
                try {
                    let element = document.getElementById(elemento);
                    if (element) {
                        element.focus();
                        element.select();
                    }
                } catch (error) {
                    console.log("error :>> ", error);
                }
            }, atraso);
    }

    return (
        <div className="pages-col">
            <Form layout="vertical" form={formConhecimento} name="formDadosGerais">
                <Row gutter={[8, 0]} style={{ marginTop: '10px' }}>
                    <Col xs={12} sm={12} md={6} lg={6} xl={7}>
                        <Form.Item label="Tipo do CTe" name="cte_tipocte" rules={[{ required: true, message: 'Informe o tipo do CTe' }]}>
                            <Select placeholder="Selecione o Tipo do CTe" allowClear disabled>
                                {tipoCTe.map((item) => (
                                    <Select.Option value={item.key} key={item.key}>{item.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={7}>
                        <Form.Item label="Modal" name="cte_modal" rules={[{ required: true, message: 'Informe o modal do CTe' }]}>
                            <Select placeholder="Selecione o Modal" allowClear disabled>
                                {modalCTe.map((item) => (
                                    <Select.Option value={item.key} key={item.key}>{item.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={7}>
                        <Form.Item label="Tipo Serviço" name="cte_tiposervico" rules={[{ required: true, message: 'Informe o tipo de serviço' }]}>
                            <Select placeholder="Selecione o Tipo de Serviço" allowClear defaultValue={0} disabled>
                                {tipoServico.map((item) => (
                                    <Select.Option value={item.key} key={item.key}>{item.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                        <Form.Item label="CTe Globalizado" name="cte_indglobalizado">
                            <Radio.Group defaultValue={0}>
                                <Radio value={1}>Sim</Radio>
                                <Radio value={0}>Não</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item label="Descrição do Produto Predominante" name="cte_prodpredominante" rules={[{ required: true }]}>
                            <Input placeholder="Informe o Produto Predominante" maxLength={255} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align="middle" style={{ marginTop: '30px' }}>
                    <Divider orientation="left">Informações Quantidade de Cargas</Divider>
                </Row>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={7} md={6} lg={10} xl={8}>
                        <Form.Item label="Unidade de Carga" name="cic_unit" rules={[{ required: true, message: 'Informe a unidade de carga' }]}>
                            <Select placeholder="Selecione a Unidade de Carga" allowClear>
                                {unidadeCTe.map((item) => (
                                    <Select.Option value={item.key} key={item.key}>{item.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={10} xl={8}>
                        <Form.Item label="Tipo de Medida" name="cic_tipomedida" rules={[{ required: true, message: 'Informe o tipo de medida' }]}>
                            <Input placeholder="Informe o Tipo de Medida. (Ex.: Peso Bruto, Caixas...)" maxLength={255} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={5} md={6} lg={4} xl={8}>
                        <InputPreco
                            name="cic_qntcarga"
                            label="Quantidade de Carga"
                            rules={[{ required: true, message: 'Informe a quantidade de carga' }]}
                            precision={4} maxLength={15}
                            placeholder="Informe a Quantidade de Carga"
                        />
                    </Col>
                </Row>
            </Form>
            <Form layout="vertical" form={formProduto} name="formProdutos">
                <Row align="middle" style={{ marginTop: '30px' }}>
                    <Divider orientation="left">Prestação de Serviço</Divider>
                </Row>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item label="Selecione o Produto" name="pro_id">
                            <SelectProduto valueKey={true} detalhes={true}
                                disabled={dadosProdutos.dadosProdutos.length === 1}
                                placeholder="Selecione o Produto"
                                name="pro_id"
                                form={formProduto}
                                onChange={(dados) => { buscarProduto(dados) }}
                                ordem={"pro_descricao"}
                                setListaDados={setListaSelectProd}
                                filtroExtra={`&ProdutoFrete=true`}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {exibirDetalhes &&
                    <div className="m-t-16">
                        <Card title={
                            <Row gutter={[8, 0]}>
                                <Col>
                                    Produto: <b>{dadosDetalhes.pro_descricao} {!!dadosDetalhes.descricaograde ? dadosDetalhes.descricaograde : ''}</b>
                                </Col>
                                <Col>
                                    Código Interno: <b>{dadosDetalhes.pro_codigo}</b>
                                </Col>
                                <Col>
                                    Código de Barras: <b>{!!dadosDetalhes.prg_ean ? dadosDetalhes.prg_ean : dadosDetalhes.pro_ean}</b>
                                </Col>
                            </Row>
                        }>
                            <Row gutter={[8, 0]} align="middle">
                                <Col xs={24} sm={24} md={17} lg={16} xl={12}>
                                    <Form.Item label="CFOP - Código Fiscal de Operação" name="nfi_cfop" rules={[{ required: true, message: 'Informe o CFOP' }]}>
                                        <Select showSearch allowClear optionFilterProp="children" placeholder="Selecione um CFOP">
                                            {dadosCfop.filter(cfop => { return formConhecimento.getFieldValue().destinoOperacao == 2 ? cfop.cfo_cfop.substring(0, 1) === "6" : formConhecimento.getFieldValue().destinoOperacao == 3 ? cfop.cfo_cfop.substring(0, 1) === "7" : cfop.cfo_cfop.substring(0, 1) !== "6" }).map((cfop) => (
                                                <Select.Option value={cfop.cfo_cfop} key={cfop.cfo_id}>{cfop.cfo_cfop} - {cfop.cfo_descricao}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={7} lg={8} xl={7}>
                                    <Form.Item label="ICMS" name="icm_id">
                                        <SelectPaginacao url="TributacaoIcms/Listar" placeholder="Selecione o ICMS" form={formProduto} nameLabel="icm_descricao" nameValue="icm_id" conteudo={
                                            icm => (<Select.Option value={icm.icm_id} key={icm.key}>{icm.icm_id} - {icm.icm_descricao}</Select.Option>)
                                        } />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} sm={4} md={3} lg={4} xl={2}>
                                    <Form.Item label="Unidade Medida" name="nfi_unidademedida">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} sm={8} md={4} lg={4} xl={3}>
                                    <InputPreco label="Valor da Prestação (R$)" name="nfi_valorunitario" />
                                </Col>
                                <Form.Item name="nfi_qtde" hidden></Form.Item>
                            </Row>
                            <Row align="middle" justify="end" gutter={[8, 0]} className="m-t-8">
                                <Col>
                                    <Button icon={<CloseOutlined />} onClick={() => (onClickBtnCancelar())}>
                                        Cancelar
                                    </Button>
                                </Col>
                                <Col>
                                    <Button icon={<PlusOutlined />} type="primary" onClick={() => onClickBtnAdicionar()}>
                                        Adicionar
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                }
                <div className="tabela mt-dif">
                    <Table columns={[
                        {
                            title: 'Produto',
                            width: 220,
                            render: ({ pro_codigo, pro_descricao }) => (
                                <div>
                                    <Row align="middle" gutter={[5, 0]}>
                                        <Col>
                                            <Tag color="processing" className="w-75">
                                                <b>{pro_codigo}</b>
                                            </Tag>
                                            <b> {pro_descricao}</b>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        },
                        {
                            title: 'CFOP',
                            align: 'center',
                            width: 40,
                            render: ({ nfi_cfop }) => (
                                <div>
                                    <b>{nfi_cfop}</b>
                                </div>
                            )
                        },
                        {
                            title: 'ICMS',
                            align: 'center',
                            width: 40,
                            render: ({ icm_id }) => (
                                <div>
                                    <b>{icm_id}</b>
                                </div>
                            )
                        },
                        {
                            title: 'Unid.',
                            width: 40,
                            align: 'center',
                            render: ({ nfi_unidademedida }) => (
                                <div>
                                    <b>{nfi_unidademedida}</b>
                                </div>
                            )
                        },
                        {
                            title: 'Qtde.',
                            width: 40,
                            align: 'center',
                            render: ({ nfi_qtde }) => (
                                <div>
                                    <b>{FormatNumber(nfi_qtde, true)}</b>
                                </div>
                            )
                        },
                        {
                            title: 'Vlr Unt.(R$)',
                            align: 'right',
                            width: 40,
                            render: ({ nfi_valorunitario }) => (
                                <div>
                                    <b>{FormatNumber(nfi_valorunitario, true)}</b>
                                </div>
                            )
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            key: 'x',
                            align: 'center',
                            width: 40,
                            fixed: 'right',
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button icon={<EditOutlined />} onClick={() => editarItemCTe(record)} />
                                        </Col>
                                        <Col>
                                            <Button icon={<DeleteOutlined />} onClick={() => removerProdutoCTe(record)} />
                                        </Col>
                                    </Row>
                                </div>
                            )
                        }
                    ]}
                        pagination={false}
                        dataSource={dadosProdutos.dadosProdutos}
                        locale={{
                            emptyText: (
                                <Row>
                                    <Col span={24}>
                                        <Divider orientation="center">
                                            <ExclamationCircleOutlined /> Não há Dados
                                        </Divider>
                                    </Col>
                                </Row>
                            )
                        }}
                    />
                </div>
                <Row align="middle" style={{ marginTop: '30px' }}>
                    <Divider orientation="left">Componentes de Frete</Divider>
                </Row>
                <Row gutter={[8, 0]}>
                        <Form.Item hidden label="Sequência" name="cmf_sequencia">
                            <InputNumber disabled />
                        </Form.Item>
                    <Col xs={24} sm={11} md={12} lg={12} xl={8}>
                        <Form.Item label="Componente" name="cmf_id">
                            <Select placeholder="Selecione o Componente de Frete" allowClear>
                                {listaComponentes.map((item) => (
                                    <Select.Option value={item.cmf_id} key={item.cmf_id}>{item.cmf_descricao}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={5} md={4} lg={4} xl={4}>
                        <InputPreco label="Valor do Componente" name="ccf_valor" />
                    </Col>
                    <Col xs={12} sm={4} md={4} lg={4} xl={3} className="add-button">
                        {editandoComponente ?
                            <Button icon={<CheckOutlined />} type="primary" onClick={() => adicionarComponente()} >
                                Salvar
                            </Button> :
                            <Button icon={<PlusOutlined />} type="primary" onClick={() => adicionarComponente()} >
                                Adicionar
                            </Button>
                        }
                    </Col>
                    <Col xs={12} sm={4} md={4} lg={4} xl={3} className="add-button">
                        {editandoComponente &&
                            <Button icon={<CloseOutlined />} onClick={() => cancelarEdit()} >
                                Cancelar
                            </Button>
                        }
                    </Col>
                </Row>
                <div className="tabela mt-dif">
                    <Table columns={[
                        {
                            title: '',
                            align: 'center',
                            width: 75,
                            render: ({ cmf_sequencia }) => (
                                <Tag color="processing" className="w-100">
                                    <b>{cmf_sequencia}</b>
                                </Tag>
                            )
                        },
                        {
                            title: 'Descrição',
                            render: ({ cmf_descricao }) => (
                                <b>{cmf_descricao}</b>
                            )
                        },
                        {
                            title: 'Valor (R$)',
                            align: 'right',
                            width: 100,
                            render: ({ ccf_valor }) => (
                                <b>{FormatNumber(ccf_valor, true)}</b>
                            )
                        },
                        {
                            title: 'Ações',
                            align: 'center',
                            width: 65,
                            dataIndex: '',
                            key: 'x',
                            fixed: 'right',
                            render: (record) => {
                                return (
                                    <div>
                                        <Row align="middle" justify="center" gutter={[5, 0]}>
                                            <Col>
                                                <Button icon={<EditOutlined />} onClick={() => editarComponente(record)} />
                                            </Col>
                                            <Col>
                                                <Button icon={<DeleteOutlined />} onClick={() => removerComponente(record)} />
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            }
                        }
                    ]}
                        locale={{
                            emptyText: (
                                <Row>
                                    <Col span={24}>
                                        <Divider orientation="center">
                                            <ExclamationCircleOutlined /> Não há Dados
                                        </Divider>
                                    </Col>
                                </Row>
                            )
                        }}
                        dataSource={componentesFrete.componentesFrete}
                        pagination={false}
                        scroll={{ y: 100 }}
                    />
                </div>
            </Form>
        </div>
    )
}