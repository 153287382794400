import React, { useEffect } from "react";
import { Card, Col, Divider, Form, Input, Row, Typography } from "antd";

import { FormasPagamento, InputPreco } from "../../components";

export default function TabFaturamentoCTe({ formConhecimento, dadosOperacao, listaFormaPagamento, idTomador, setListaFormaPagamento, nota }) {

    useEffect(() => {
        if (nota) {
            formConhecimento.setFieldsValue({
                peso: nota.cid_peso,
                icms: nota.cid_valoricms,
                icmsst: nota.cid_valoricmsst,
                produtos: nota.cid_valorprodutos,
                valorcte: nota.cid_valornf,
            })
        }
    }, [])

    return (
        <div className="m-t-16 faturar">
            <Form layout="vertical" form={formConhecimento} name="formComponentesFrete">
                <Row align="middle" justify="center" gutter={[8, 24]}>
                    <Col xs={24} sm={24} md={24} lg={16} xl={14} xxl={12}>
                        <Card extra={
                            <Row justify="center">
                                <Col>
                                    <Typography.Title level={3}>
                                        Totais do Conhecimento de Transporte
                                    </Typography.Title>
                                </Col>
                            </Row>
                        }>
                            <Row gutter={[8, 0]}>
                                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                    <InputPreco label="Peso" name="peso" disabled precision={3} />
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                    <InputPreco label="Produtos (R$)" name="produtos" disabled />
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                    <InputPreco label="ICMS (R$)" name="icms" disabled />
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                    <InputPreco label="ICMS ST (R$)" name="icmsst" disabled />
                                </Col>
                            </Row>
                            <Divider />
                            <Row justify="end">
                                <Col>
                                    <Typography.Title level={4} className="m-0">
                                        Total do CTe (R$)
                                    </Typography.Title>
                                </Col>
                            </Row>
                            <Row justify="end" className="inpDis">
                                <InputPreco className="f-18" name="valorcte" disabled />
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item label="Observação" name="cte_observacao">
                            <Input.TextArea maxLength={2000} autoSize={{ maxRows: 3 }} placeholder="Digite aqui..." />
                        </Form.Item>
                    </Col>
                </Row>
                <FormasPagamento form={formConhecimento} dadosOperacao={dadosOperacao} listaFormaPagamento={listaFormaPagamento} setListaFormaPagamento={setListaFormaPagamento}
                    idTomador={idTomador} nota={nota} />
            </Form>
        </div>
    )
}