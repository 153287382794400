import React, { useState } from 'react';
import { Row, Col, Tag, Table, Button, Tooltip, Dropdown, Menu } from "antd";

import { FormatNumber } from '../../ValueObjects';
import { FileSearchOutlined, MoreOutlined } from '@ant-design/icons';
import ModalDetalheLoteNfi from '../modals/modalDetalhesLoteNfi';

export default function TabDetalheItens({ itensNF }) {

    const [percentualDesc, setPercentualDesc] = useState(true);
    const [exibirModalLote, setExibirModalLote] = useState(false);
    const [dadosItem, setDadosItem] = useState([]);

    function retornarPercentual(perc_desconto, nfi_valordesconto) {
        if (percentualDesc) {
            return (
                <div>
                    <b>{perc_desconto}</b>%
                </div>
            )
        } else {
            return (
                <div>
                    <b>{FormatNumber(nfi_valordesconto, true)}</b>
                </div>
            )
        }
    };

    function menuTable(record) {
        const itemLote = record.itemLote && record.itemLote.length > 0;
        const tooltip = itemLote ? '' : 'Lotes do Produto não disponível';
        return (
            <Menu className='text-right'>
                <Menu.Item key="1">
                    <Tooltip title={tooltip}>
                        <Button icon={<FileSearchOutlined />} type='text'
                            onClick={() => { { setExibirModalLote(true); setDadosItem(record) } }}
                            disabled={!itemLote}
                        >Lotes</Button>
                    </Tooltip>
                </Menu.Item>
            </Menu>
        )
    }

    return (
        <div className="tabela">
            <Table columns={
                [
                    {
                        width: '4%',
                        align: 'center',
                        fixed: 'left',
                        render: ({ pro_codigo }) => (
                            <div>
                                <Row align="middle" justify="center">
                                    <Col>
                                        <Tag color="processing" className="w-75">
                                            <b>{pro_codigo}</b>
                                        </Tag>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: '',
                        fixed: 'left',
                        width: '20%',
                        render: ({ pro_codigo, pro_descricao, atributos, descricaoGrade }) => (
                            <div>
                                <Row align="middle" gutter={[5, 5]}>
                                    <Col style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        <b>{(!!pro_descricao ? pro_descricao : 'Débito/Crédito de imposto')}</b>
                                    </Col>
                                    {!!atributos &&
                                        <Col>
                                            <Tag color="default">
                                                <b>{atributos.mgp_descricao}</b>
                                            </Tag>
                                        </Col>
                                    }
                                    {!!descricaoGrade &&
                                        <Col>
                                            <Tag color="default">
                                                <b>{descricaoGrade}</b>
                                            </Tag>
                                        </Col>
                                    }
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'N°',
                        width: '1.5%',
                        align: 'center',
                        fixed: 'left',
                        render: ({ nfi_numeroitem }) => (
                            <div>
                                <Row align="middle" justify="center">
                                    <Col>
                                        <b>{(nfi_numeroitem)}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: `${!!itensNF && !!itensNF[0].nis_csosn ? 'CSOSN' : 'CST'}`,
                        width: '2.5%',
                        align: 'center',
                        render: ({ nis_origemmercadoria, nis_csosn, nig_cst, nig_origemmercadoria }) => (
                            <div>
                                <Row align="middle" justify="center">
                                    <Col>
                                        <b>{(!!nis_csosn ? nis_origemmercadoria + nis_csosn : nig_origemmercadoria + nig_cst)}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'CFOP',
                        width: '2%',
                        align: 'center',
                        render: ({ nfi_cfop }) => (
                            <div>
                                <Row align="middle" justify="center">
                                    <Col>
                                        <b>{nfi_cfop}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    }, {
                        title: 'NCM',
                        width: '3%',
                        align: 'center',
                        render: ({ nfi_ncm }) => (
                            <div>
                                <Row align="middle" justify="center">
                                    <Col>
                                        <b>{nfi_ncm}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'CEST',
                        width: '2.8%',
                        align: 'center',
                        render: ({ cet_id }) => (
                            <div>
                                <Row align="middle" justify="center">
                                    <Col>
                                        <b>{cet_id}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'C.Benef',
                        width: '3%',
                        align: 'center',
                        render: ({ cbf_codigo }) => (
                            <div>
                                <Row align="middle" justify="center">
                                    <Col>
                                        <b>{cbf_codigo}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'UM.',
                        width: '2%',
                        align: 'center',
                        render: ({ nfi_unidademedida }) => (
                            <div>
                                <Row align="middle" justify="center">
                                    <Col>
                                        <b>{nfi_unidademedida}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Qtde.',
                        width: '3%',
                        align: 'right',
                        render: ({ nfi_qtde }) => (
                            <div>
                                <Row align="middle" justify="end">
                                    <Col>
                                        <b>{nfi_qtde}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Vlr Unit.(R$)',
                        align: 'right',
                        width: '4%',
                        render: ({ nfi_valorunitario }) => (
                            <div>
                                <b>{FormatNumber(nfi_valorunitario, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Frete.(R$)',
                        align: 'right',
                        width: '3%',
                        render: ({ nfi_valorfrete }) => (
                            <div>
                                <Row align="middle" justify="end">
                                    <b>{FormatNumber(nfi_valorfrete, true)}</b>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Acrés.(R$)',
                        align: 'right',
                        width: '3%',
                        render: ({ nfi_valoroutros }) => (
                            <div>
                                <Row align="middle" justify="end">
                                    <b>{FormatNumber(nfi_valoroutros, true)}</b>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: () => {
                            return (
                                <div>
                                    <Row align="middle" justify="end" gutter={[4, 0]}>
                                        <Col>
                                            Desc.
                                        </Col>
                                        <Col>
                                            <Button size="small" onClick={() => { setPercentualDesc(!percentualDesc) }}>{(percentualDesc ? '%' : 'R$')}</Button>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        },
                        width: '3%',
                        align: 'right',
                        render: ({ perc_desconto, nfi_valordesconto }) => (
                            retornarPercentual(perc_desconto, nfi_valordesconto)
                        ),
                    },
                    {
                        title: 'BC Icms',
                        width: '3%',
                        align: 'right',
                        render: ({ nig_valorbc }) => (
                            <div>
                                <Row align="middle" justify="end">
                                    <Col>
                                        <b>{FormatNumber(nig_valorbc, true)}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: '% Icms',
                        width: '3%',
                        align: 'right',
                        render: ({ nig_percicms }) => (
                            <div>
                                <Row align="middle" justify="end">
                                    <Col>
                                        <b>{FormatNumber(nig_percicms, true)}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Vlr Icms',
                        width: '3%',
                        align: 'right',
                        render: ({ nig_valoricms }) => (
                            <div>
                                <Row align="middle" justify="end">
                                    <Col>
                                        <b>{FormatNumber(nig_valoricms, true)}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'BC Icms St',
                        width: '3%',
                        align: 'right',
                        render: ({ nis_valorbcst, nig_valorbcst }) => (
                            <div>
                                <Row align="middle" justify="end">
                                    <Col>
                                        <b>{FormatNumber(nis_valorbcst?? nig_valorbcst, true)}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: '% Icms St',
                        width: '3%',
                        align: 'right',
                        render: ({ nis_percicmsst,nig_percicmsst }) => (
                            <div>
                                <Row align="middle" justify="end">
                                    <Col>
                                        <b>{FormatNumber(nis_percicmsst?? nig_percicmsst, true)}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Vlr Icms St',
                        width: '3%',
                        align: 'right',
                        render: ({ nis_valoricmsst, nig_valoricmsst }) => (
                            <div>
                                <Row align="middle" justify="end">
                                    <Col>
                                        <b>{FormatNumber(nis_valoricmsst ?? nig_valoricmsst, true)}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: '% MVA',
                        width: '3%',
                        align: 'right',
                        render: ({ nis_percmvast, nig_percmvast }) => (
                            <div>
                                <Row align="middle" justify="end">
                                    <Col>
                                        <b>{FormatNumber(nis_percmvast ?? nig_percmvast, true)}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'BC IPI',
                        width: '3%',
                        align: 'right',
                        render: ({ npi_valorbcipi }) => (
                            <div>
                                <Row align="middle" justify="end">
                                    <Col>
                                        <b>{FormatNumber(npi_valorbcipi, true)}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: '% IPI',
                        width: '3%',
                        align: 'right',
                        render: ({ npi_percaliquotaipi }) => (
                            <div>
                                <Row align="middle" justify="end">
                                    <Col>
                                        <b>{FormatNumber(npi_percaliquotaipi, true)}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Vlr IPI',
                        width: '3%',
                        align: 'right',
                        render: ({ npi_valoripi }) => (
                            <div>
                                <Row align="middle" justify="end">
                                    <Col>
                                        <b>{FormatNumber(npi_valoripi, true)}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Vlr Total (R$)',
                        align: 'right',
                        fixed: 'right',
                        width: '5%',
                        render: ({ nfi_valorunitario, nfi_qtde }) => (
                            <div>
                                <Row align="middle" justify="end">
                                    <b>{FormatNumber(nfi_qtde * nfi_valorunitario, true)}</b>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Lotes',
                        align: 'center',
                        fixed: 'right',
                        key: 'x',
                        width: '3%',
                        render: (record) => {
                            return (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Dropdown overlay={menuTable(record)} trigger={['click']}>
                                                <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        }
                    }
                ]
            } dataSource={itensNF}
                scroll={{
                    x: 2500,
                    y: 300,
                }} />
            <ModalDetalheLoteNfi exibirModal={exibirModalLote} fecharModal={() => setExibirModalLote(false)} item={dadosItem} />
        </div>
    );
}