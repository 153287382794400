import React, { useState, useEffect } from "react";
import { Form, Row, Col, Typography, Input, Button, Radio, Divider, message, notification, Modal, Select } from "antd";
import { CloseOutlined, CloudUploadOutlined, ContainerOutlined, SaveOutlined, UploadOutlined, ExclamationCircleOutlined, EyeOutlined, EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import Dragger from "antd/lib/upload/Dragger";
import moment from "moment";

import api from "../../services/api";
import { getLocal, linkApi } from "../../services/auth";
import { getBase64 } from "../../services/funcoes";
import { BreadcrumbAbrirChamado } from "../../components";

export default function ConfiguracaoDFe() {
    const [formulario] = Form.useForm();
    const [config, setConfig] = useState({});
    const [exibeMotivoContingencia, setExibeMotivoContingencia] = useState(false);
    const [exibeMotivoContingenciaNFCe, setExibeMotivoContingenciaNFCe] = useState(false);
    const [certificadoValido, setCertificadoValido] = useState(false);
    const [ambienteEmissao, setAmbienteEmissao] = useState(1);
    const [formaEmissaoNormal, setFormaEmissaoNormal] = useState([]);
    useEffect(() => {
        carregarDados();
    }, []);

    useEffect(() => {
        if (!!formulario.getFieldValue().lcn_ambienteemissao) {
            setAmbienteEmissao(parseInt(formulario.getFieldValue().lcn_ambienteemissao));
        }
    }, [formulario])


    // Gerado em https://designer.mocky.io/manage
    const props = {
        name: 'file',
        multiple: false,
        action: `${linkApi}LocalConfigNfe/RetornaOk`,
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                getBase64(info.file.originFileObj).then(
                    base64 => {
                        let dados = { ...config };
                        dados.conteudo = base64;
                        dados.nome = info.file.name;
                        dados.loc_id = getLocal();
                        setConfig(dados);
                        message.success(`${info.file.name} arquivo enviado com sucesso.`);
                    }
                );
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} falha no envio do arquivo.`);
            }
        },
    };

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                window.location = '/Home#/'
            }
        });
    };
    function salvarConf(valores) {
        let dados = { ...valores, ...config };
        if (!(!!!config.conteudo) && certificadoValido == false) {
            notification.warning({ description: "Certificado ou senha informada inválido!", message: "Aviso" });
        } else {

            let enderecoUrl = 'LocalConfigNfe/';
            if (dados.lcn_id) {
                enderecoUrl += `Editar`;
                api.put(enderecoUrl, dados).then(
                    res => {
                        notification.success({ message: 'Operação concluída', description: "Configuração do envio de NF-e salvos com sucesso!" });
                    }).catch(
                        error => {
                            console.log(error);
                        }).finally(
                            () => {
                            });
            } else {
                enderecoUrl += `Incluir`;
                api.post(enderecoUrl, dados).then(
                    res => {
                        notification.success({ message: 'Operação concluída', description: "Configuração do envio de NF-e salvos com sucesso!" });
                    }
                ).catch(
                    erro => {
                        console.log(erro);
                    }
                ).finally(
                    () => {
                    }
                );
            }
        }
    };

    function carregarDados() {
        api.get(`Enum/Listar?nome=FormaRetornoEmissaoNormalSefaz`).then(res => {
            setFormaEmissaoNormal(res.data);
        });

        api.get(`LocalConfigNfe/Listar`).then(
            res => {
                if (!!res.data) {
                    let dados = { ...config };
                    dados.lcn_validadecertificado = res.data.lcn_validadecertificado;
                    setAmbienteEmissao(res.data.lcn_ambienteemissao);
                    if (res.data.lcn_tipoemissao > 1) {
                        setExibeMotivoContingencia(true);
                    }
                    if (res.data.lcn_tipoemissaonfce > 1) {
                        setExibeMotivoContingenciaNFCe(true);
                    }
                    setConfig(dados);
                    formulario.setFieldsValue(res.data);
                } else {
                    let conf = {};
                    conf.lcn_ambienteemissao = 2;
                    conf.lcn_tipoemissao = 1;
                    setAmbienteEmissao(conf.lcn_ambienteemissao);

                    formulario.setFieldsValue(conf);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function consultaStatusSefaz() {
        api.get(`Nfe/ConsultaStatus`).then(
            res => {
                if (res.data) {
                    if (res.data.retorno.cStat === 107)
                        notification.success({ message: res.data.retorno.cStat, description: res.data.retorno.xMotivo });
                    else
                        notification.warning({ message: res.data.retorno.cStat, description: res.data.retorno.xMotivo });
                } else {
                    notification.warning({ description: "Erro ao consultar status sefaz !", message: "Aviso" });
                }
                console.log("RES", res.data);
            }
        ).catch(
            erro => {
                if (erro.response?.data) {
                    notification.warning({ description: (<> Erro ao consultar Status. <br /> {erro.response.data} </>), message: "Aviso" });
                } else {
                    notification.warning({ description: "Erro ao consultar Status. Verifique o Certificado.", message: "Aviso" });
                }
            }
        );
    };
    function validarCertificado() {
        if (!!config && JSON.stringify(config) !== '{}') {
            let dados = { ...config, ...formulario.getFieldValue() };
            api.post(`LocalConfigNfe/RetornaValidadeCertificado`, dados).then(
                res => {
                    if (res.data.validaCnpj) {
                        setCertificadoValido(true);
                        dados = { ...config, ...formulario.getFieldValue() };
                        dados.lcn_validadecertificado = res.data.dataValidade;
                        setConfig(dados);
                    } else {
                        notification.warning({ description: "CNPJ do certificado não é compativel com o CNPJ do local !", message: "Aviso" });
                    }
                    console.log("RES", res.data);
                }

            ).catch(
                erro => {
                    if (erro.response?.data) {
                        notification.warning({ description: (<> Erro ao validar Certificado.<br /> {erro.response.data} </>), message: "Aviso" });
                    } else {
                        notification.warning({ description: "A senha especificada não está correta.", message: "Aviso" });
                    }

                }
            );
        }
    };

    const exibeContingencia = tipoEmissao => {
        tipoEmissao = tipoEmissao.target.value;
        if (tipoEmissao > 1) {
            setExibeMotivoContingencia(true);
        } else {
            setExibeMotivoContingencia(false);
        }
    };
    const exibeContingenciaNFCe = tipoEmissao => {
        tipoEmissao = tipoEmissao.target.value;
        if (tipoEmissao == 1) {
            setExibeMotivoContingenciaNFCe(false);
        } else {
            setExibeMotivoContingenciaNFCe(true);
        }
    };

    function trocarAmbiente(event) {
        setAmbienteEmissao(event.target.value);
    };

    return (
        <div className="p-10">
            <BreadcrumbAbrirChamado />
            <Form layout="vertical" name="formConfiguracaoNfeNfce" form={formulario} onFinish={salvarConf}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={5}>
                        <Form.Item label="Ambiente de Emissão" name="lcn_ambienteemissao">
                            <Radio.Group onChange={(value) => { trocarAmbiente(value) }}>
                                <Radio value={1}> Produção</Radio>
                                <Radio value={2}> Homologação</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    {ambienteEmissao === 1 && <>
                        <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
                            <Form.Item label="Id do Token" name="lcn_idtokennfceprod">
                                <Input placeholder="Informe o ID do Token" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
                            <Form.Item label="Token" name="lcn_tokennfceprod">
                                <Input placeholder="Informe o Token" />
                            </Form.Item>
                        </Col>
                    </>}
                    {ambienteEmissao === 2 && <>
                        <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
                            <Form.Item name="lcn_id" hidden><Input /></Form.Item>
                            <Form.Item label="Id do Token (Homologação)" name="lcn_idtokennfcehom">
                                <Input placeholder="Informe o ID do Token" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
                            <Form.Item label="Token (Homologação)" name="lcn_tokennfcehom">
                                <Input placeholder="Informe o Token" />
                            </Form.Item>
                        </Col>
                    </>}
                    <Col xs={24} sm={12} md={7} lg={7} xl={6} xxl={7}>
                        <Form.Item label="Token IBPT" name="lcn_tokenibpt">
                            <Input placeholder="Informe o Token da Tabela IBPT"
                                autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label="Forma de Emissão de NF-e" name="lcn_tipoemissao">
                            <Radio.Group onChange={(value) => (exibeContingencia(value))}>
                                <Radio value={1}>Emissão Normal</Radio>
                                <Radio value={2}>Contingência FS-IA</Radio>
                                <Radio value={3}>Contingência SCAN</Radio>
                                <Radio value={4}>Contingência EPEC</Radio>
                                <Radio value={5}>Contingência FS-DA</Radio>
                                <Radio value={6}>Contingência SVC-AN</Radio>
                                <Radio value={7}>Contingência SVC-RS</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8} hidden={!exibeMotivoContingencia}>
                        <Form.Item label="Motivo da entrada em contingência" name="lcn_motivocontingencia" rules={exibeMotivoContingencia ? [{ required: true, message: 'Informe o motivo da entrada em contigência' }] : null}>
                            <Input minLength={15} placeholder="Adicione aqui o motivo da entrada em contingência" />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label="Forma de Emissão de NFC-e" name="lcn_tipoemissaonfce">
                            <Radio.Group onChange={(value) => (exibeContingenciaNFCe(value))}>
                                <Radio value={1}>Emissão Normal</Radio>
                                <Radio value={9}>Contingência off-line da NFC-e</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6} hidden={!exibeMotivoContingenciaNFCe}>
                        <Form.Item label="Forma de Retorno Emissão NFC-e" name="lcn_retornoemissaonormal" rules={exibeMotivoContingencia ? [{ required: true, message: 'Informe a Forma de Retorno Emissão NFC-e' }] : null}>
                            <Select showSearch optionFilterProp="children" placeholder="Selecione a Forma de Retorno Emissão NFC-e">
                                {formaEmissaoNormal?.map(item => (
                                    <Select.Option key={item.key} value={item.key}>{item.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={6} hidden={!exibeMotivoContingenciaNFCe}>
                        <Form.Item label="Motivo da entrada em contingência" name="lcn_motivocontingencia" rules={exibeMotivoContingenciaNFCe ? [{ required: true, message: 'Informe o motivo da entrada em contigência' }] : null}>
                            <Input minLength={15} placeholder="Adicione aqui o motivo da entrada em contingência" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
                        <Form.Item label="Usuário Prefeitura(NFS-e)" name="lcn_usuarioprefeitura">
                            <Input
                                placeholder="Usuário na Prefeitura"
                                autoComplete="off" // Desativa o preenchimento automático
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
                        <Form.Item label="Senha Prefeitura(NFS-e)" name="lcn_senhaprefeitura">
                            <Input.Password
                                placeholder="Senha"
                                autoComplete="new-password" // Evita preenchimento automático de senha
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[8, 0]} className="m-t-16 pad-page-detalhe">
                    <Col span={24}>
                        <Typography.Title level={3}>
                            <img src={require("../../assets/i-info.png").default} alt="Informe os dados do certificado a ser utilizado" /> Informe os dados do certificado a ser utilizado
                        </Typography.Title>
                        <Divider orientation="left" plain> * Caso nenhum certificado seja informado, será utilizado o certificado da Matriz como Padrão! </Divider>
                    </Col>
                    <Col span={24}>
                        <Row align="middle" justify="center" gutter={[16, 8]}>
                            <Col xs={24} sm={16} md={16} lg={14} xl={10} xxl={6}>
                                <Form.Item label="Certificado Digital">
                                    <div className="uP">
                                        <Dragger {...props}>
                                            {!!!config.conteudo && <div>
                                                <Row gutter={[0, 5]}>
                                                    <Col span={24}>
                                                        <p className="ant-upload-drag-icon"><CloudUploadOutlined /></p>
                                                    </Col>
                                                    <Col span={24}>
                                                        <p className="ant-upload-text">Arraste o certificado digital aqui!</p>
                                                    </Col>
                                                    <Col span={24}>
                                                        <p className="ant-upload-text">(o arquivo deve estar no formato .pfx, modelo A1)</p>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Button block icon={<UploadOutlined />}>
                                                            Importar o Certificado Digital
                                                        </Button>
                                                    </Col>
                                                    <Col span={24}>
                                                        {!!config.lcn_validadecertificado &&
                                                            <span className="ant-upload-text">Certificado Válido até: {moment(config.lcn_validadecertificado).format('DD/MM/YYYY')}</span>
                                                        }
                                                    </Col>
                                                </Row>
                                            </div>}
                                            {!!config.conteudo && <div>
                                                <Row>
                                                    <Col span={24}>
                                                        <p className="ant-upload-drag-icon"><ContainerOutlined /></p>
                                                    </Col>
                                                    <Col span={24}>
                                                        <p className="ant-upload-text">{config.nome}</p>
                                                    </Col>
                                                    <Col span={24}>
                                                        {!!config.lcn_validadecertificado &&
                                                            <span className="ant-upload-text">Certificado Válido até: {moment(config.lcn_validadecertificado).format('DD/MM/YYYY')}</span>
                                                        }
                                                    </Col>
                                                </Row>
                                            </div>}
                                        </Dragger>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={8} lg={10} xl={6} xxl={4}>
                                <Form.Item label="Senha do Certificado Digital" name="lcn_senhacertificado">
                                    <Input.Password placeholder="Informe aqui a Senha" onBlur={() => { validarCertificado() }} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={8} lg={10} xl={6} xxl={4}>
                                <Row justify="end">
                                    <Col xs={24} sm={24} md={24} lg={19} xl={24} xxl={18}>
                                        <Button icon={<EyeOutlined />} className="t-mob-573" onClick={() => { consultaStatusSefaz() }} block>
                                            Consulta Status SEFAZ
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="ant-drawer-footer footer-recebimento">
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button icon={<SaveOutlined />} type="primary" size="large" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        </div>
    );
}
