import React, { useEffect, useState } from "react";
import { Form, Row, Col, Input, Select, Checkbox, Button, notification } from "antd";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";

import { BreadcrumbPage, Data, GerarEfdIcmsIpi } from "../../components";
import api from "../../services/api";
import moment from "moment";
import maskFormat from "../../ValueObjects/maskFormat";

export default function EfdIcmsIpi() {

    const [formulario] = Form.useForm();
    const [dadosLocais, setDadosLocais] = useState([]);
    const [listaApuracaoICMS, setListaApuracaoICMS] = useState([]);
    const [listaCodigoGuiaRecolhimento, setListaCodigoGuiaRecolhimento] = useState([]);
    const [listaCodigoReceitaDifal, setListaCodigoReceitaDifal] = useState([]);
    const [listaFinalidadeArquivo, setListaFinalidadeArquivo] = useState([]);
    const [listaPerfilContribuinte, setListaPerfilContribuinte] = useState([]);
    const [listaMotivoInventario, setListaMotivoInventario] = useState([]);
    const [listaCustoInventario, setListaCustoInventario] = useState([]);
    const [dataInventirioInformada, setDataInventirioInformada] = useState(false);
    const [carregando, setCarregando] = useState(false);

    useEffect(() => {
        carregarDados();
        setMonthDates();
    }, [])

    const setMonthDates = () => {
        formulario.setFieldsValue({
            dataInicial: moment().startOf('month'), // Primeiro dia do mês
            dataFinal: moment().endOf('month') // Último dia do mês
        });
    };


    function carregarDados() {
        api.get('Local/Listar?Ativo=true').then(
            res => {
                setDadosLocais(res.data?.items);
            }
        ).catch(
            error => {
                console.log(error);
            }
        )

        api.get('Enum/Listar?nome=TipoApuracaoIcms').then(
            (res) => {
                if (res.status === 200) {
                    setListaApuracaoICMS(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )

        api.get('Enum/Listar?nome=CodigoGuiaRecolhimento').then(
            (res) => {
                if (res.status === 200) {
                    setListaCodigoGuiaRecolhimento(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )

        api.get('Enum/Listar?nome=CodigoReceitaDifal').then(
            (res) => {
                if (res.status === 200) {
                    setListaCodigoReceitaDifal(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )

        api.get('Enum/Listar?nome=FinalidadeArquivo').then(
            (res) => {
                if (res.status === 200) {
                    setListaFinalidadeArquivo(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )

        api.get('Enum/Listar?nome=PerfilContribuinte').then(
            (res) => {
                if (res.status === 200) {
                    setListaPerfilContribuinte(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )

        api.get('Enum/Listar?nome=MotivoInventario').then(
            (res) => {
                if (res.status === 200) {
                    setListaMotivoInventario(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )

        api.get('Enum/Listar?nome=IndCustoInventario').then(
            (res) => {
                if (res.status === 200) {
                    setListaCustoInventario(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )
    }


    function gerarEfdIcmsIpi() {
        let sql = "";
        sql += !!formulario.getFieldValue().local ? `Local=${formulario.getFieldValue().local}` : ``;
        sql += !!formulario.getFieldValue().dataInicial ? `&DataInicial=${moment(formulario.getFieldValue().dataInicial).format('YYYY-MM-DD')}` : `&dataInicial=${moment(new Date()).format('YYYY-MM-DD')}`;
        sql += !!formulario.getFieldValue().dataFinal ? `&DataFinal=${moment(formulario.getFieldValue().dataFinal).format('YYYY-MM-DD')}` : `&dataFinal=${moment(new Date).format('YYYY-MM-DD')}`;
        sql += !!formulario.getFieldValue().dataInventario ? `&DataInventario=${moment(formulario.getFieldValue().dataInventario).format('YYYY-MM-DD')}` : "";
        sql += !!formulario.getFieldValue().perfil ? `&PerfilContribuinte=${formulario.getFieldValue().perfil}` : `&PerfilContribuinte=0`;
        sql += !!formulario.getFieldValue().finalidade ? `&FinalidadeArquivo=${formulario.getFieldValue().finalidade}` : `&FinalidadeArquivo=0`;
        sql += !!formulario.getFieldValue().apurIcms ? `&ApuracaoICMS=${formulario.getFieldValue().apurIcms}` : `&ApuracaoICMS=0`;
        sql += !!formulario.getFieldValue().codigoRecDifal ? `&CodigoReceitaDifal=${formulario.getFieldValue().codigoRecDifal}` : `&CodigoReceitaDifal=0`;
        sql += !!formulario.getFieldValue().codigoRecolhimento ? `&CodigoGuiaRecolhimento=${formulario.getFieldValue().codigoRecolhimento}` : `&CodigoGuiaRecolhimento=0`;
        sql += !!formulario.getFieldValue().codigoReceita ? `&CodigoReceita=${formulario.getFieldValue().codigoReceita}` : ``;
        sql += !!formulario.getFieldValue().gerarBlocoK ? `&GerarBlocoK=${formulario.getFieldValue().gerarBlocoK}` : ``;
        sql += !!formulario.getFieldValue().gerarC170 ? `&GerarC170=${formulario.getFieldValue().gerarC170}` : ``;
        sql += !!formulario.getFieldValue().gerar0150 ? `&Gerar0150=${formulario.getFieldValue().gerar0150}` : ``;
        sql += !!formulario.getFieldValue().gerarC176 ? `&GerarC176=${formulario.getFieldValue().gerarC176}` : ``;
        sql += !!formulario.getFieldValue().dispensaNFCe ? `&DispensaNFCe=${formulario.getFieldValue().dispensaNFCe}` : ``;

        setCarregando(true);
        api.get(`EfdIcmsIpi/GerarEfdIcmsIpi?` + sql, { responseType: 'blob' })
            .then(res => {
                // Log para verificar os headers
                console.log(res.headers);

                // Extrair o Content-Disposition
                const contentDisposition = res.headers['content-disposition'];
                let filename = 'SPED.txt'; // Nome padrão

                if (contentDisposition) {
                    // Tentar extrair o nome do arquivo da maneira mais robusta possível
                    const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                    if (filenameMatch && filenameMatch[1]) {
                        filename = filenameMatch[1].replace(/['"]/g, ''); // Remover aspas
                    } else {
                        // Tentar a variante filename*
                        const utfFilenameMatch = contentDisposition.match(/filename\*\s*=\s*UTF-8''(.+)/);
                        if (utfFilenameMatch && utfFilenameMatch[1]) {
                            filename = decodeURIComponent(utfFilenameMatch[1]);
                        }
                    }
                }

                // Cria o blob e dispara o download com o nome extraído
                const blob = new Blob([res.data], { type: 'application/txt' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                link.remove();
                setCarregando(false);
            })
            .catch(async (erro) => {
                console.log(erro);
                const responseData = erro.response.data;
                if (responseData instanceof Blob) {
                    // Convertendo o Blob para texto
                    const errorMessage = await responseData.text();
        
                    notification.error({ description: errorMessage, message: `Aviso` });
                } else if (typeof responseData === 'string') {
                    notification.error({ description: responseData, message: `Aviso` });
                } else {
                    notification.error({ description: 'Ocorreu um erro ao gerar o arquivo SPED.', message: `Aviso` });
                }
            }).finally(
                () => {
                    setCarregando(false);
                }
            );

    };

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" gutter={[8, 0]} className="col-pagina-breadcrumb">
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
            </Row>
            <Form layout="vertical" form={formulario} name="listaEfdIcmsIpi" onFinish={gerarEfdIcmsIpi} initialValues={{ remember: true }} onValuesChange={(changedValues, allValues) => {
                if ('dataInventario' in changedValues) {
                    setDataInventirioInformada(!!allValues.dataInventario);
                }
            }}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={14} lg={14} xl={6} xxl={6} >
                        <Form.Item
                            label="Local"
                            name="local"
                            rules={[{ required: true, message: 'Informe Local da Geração' }]}
                        >
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="label"
                                placeholder="Selecione uma Local"
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                optionLabelProp="label" // Define que o label será usado para filtragem e exibição no dropdown
                            >
                                {dadosLocais.map((l) => (
                                    <Select.Option
                                        key={l.loc_id}
                                        value={l.loc_id}
                                        label={l.loc_descricao} // Para filtragem e visualização no dropdown
                                    >
                                        {l.loc_descricao}  {/* Exibe o loc_id na interface */}
                                        {(!!l.loc_cnpj ? <div>{maskFormat(l.loc_cnpj.numero, '', true)}</div> : '')}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={5} lg={5} xl={3} xxl={3}>
                        <Data label="Data Inicial" name="dataInicial" rules={[{ required: true, message: 'Informe a Data Inicial da Geração' }]} />
                    </Col>
                    <Col xs={24} sm={12} md={5} lg={5} xl={3} xxl={3}>
                        <Data label="Data Final" name="dataFinal" rules={[{ required: true, message: 'Informe a Data Final da Geração' }]} />
                    </Col>
                    <Col xs={24} sm={12} md={9} lg={9} xl={6} xxl={6}>
                        <Form.Item label="Finalidade do Arquivo" name="finalidade" initialValue={0} rules={[{ required: true, message: 'Informe a Finalidade do Arquivo' }]}>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="label"
                                placeholder="Selecione a Finalidade do Arquivo"
                            >
                                {listaFinalidadeArquivo.map((c) => (
                                    <Select.Option
                                        key={c.key}
                                        value={c.key}
                                        label={c.value}
                                    >
                                        {c.value} </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={7} lg={7} xl={6} xxl={6}>
                        <Form.Item label="Perfil do Contribuinte" name="perfil" initialValue={0} rules={[{ required: true, message: 'Informe o Perfil do Contribuinte' }]}>
                            <Select
                                allowClear
                                placeholder="Selecione o Perfil"
                                optionFilterProp="label"
                            >
                                {listaPerfilContribuinte.map((c) => (
                                    <Select.Option
                                        key={c.key}
                                        value={c.key}
                                        label={c.value}
                                    >
                                        {c.value} </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={4}>
                        <Form.Item label="Apuração do ICMS" name="apurIcms" initialValue={1} rules={[{ required: true, message: 'Informe a Apuração do ICMS' }]}>
                            <Select
                                allowClear
                                placeholder="Selecione Apuração"
                                optionFilterProp="label"
                            >
                                {listaApuracaoICMS.map((c) => (
                                    <Select.Option
                                        key={c.key}
                                        value={c.key}
                                        label={c.value}
                                    >
                                        {c.value} </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={13} lg={12} xl={12} xxl={8}>
                        <Form.Item label="Código da Guia de Recolhimento" name="codigoRecolhimento" initialValue={0} rules={[{ required: true, message: 'Informe o Código de Recolhimento do ICMS' }]}>
                            <Select
                                allowClear
                                placeholder="Selecione Código da Guia de Recolhimento"
                                optionFilterProp="label"
                            >
                                {listaCodigoGuiaRecolhimento.map((c) => (
                                    <Select.Option
                                        key={c.key}
                                        value={c.key}
                                        label={c.value}
                                    >
                                        {c.value} </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={7} xl={6} xxl={3}>
                        <Form.Item label="Código da Receita" name="codigoReceita" rules={[{ required: false, message: 'Informe o Código da Receita' }]}>
                            <Input placeholder="Informe o código da receita" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={5} lg={5} xl={4} xxl={3}>
                        <Data label="Data do Inventário" name="dataInventario" />
                    </Col>
                    <Col xs={24} sm={12} md={9} lg={9} xl={6} xxl={6}>
                        <Form.Item label="Motivo do Inventário" name="motivoInventario" rules={dataInventirioInformada ? [{ required: true, message: 'Informe o motivo o inventário' }] : []}>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="label"
                                placeholder="Selecione o Motivo do Inventário"
                            >
                                {listaMotivoInventario.map((c) => (
                                    <Select.Option
                                        key={c.key}
                                        value={c.key}
                                        label={c.value}
                                    >
                                        {c.value} </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={9} lg={9} xl={8} xxl={6}>
                        <Form.Item label="Custo do Inventário" name="custoInventario" rules={dataInventirioInformada ? [{ required: true, message: 'Informe o custo o inventário' }] : []}>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="label"
                                placeholder="Selecione o Custo do Inventário"
                            >
                                {listaCustoInventario.map((c) => (
                                    <Select.Option
                                        key={c.key}
                                        value={c.key}
                                        label={c.value}
                                    >
                                        {c.value} </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={7} xl={6} xxl={3}>
                        <Form.Item label="Conta Contábil" name="contaContabil" rules={dataInventirioInformada ? [{ required: true, message: 'Informe a conta contábil' }] : []}>
                            <Input placeholder="Informe a Conta Contábil" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={14} xl={6} xxl={6}>
                        <Form.Item label="Código da Guia de Recolhimento do DIFAL Interestadual" name="codigoRecDifal" rules={[{ required: false, message: 'Informe o Código de Recolhimento do DIFAL Interestadual' }]}>
                            <Select
                                allowClear
                                placeholder="Selecione Código de Recolhimento"
                            >
                                {listaCodigoReceitaDifal.map((c) => (
                                    <Select.Option key={c.key} value={c.key}> {c.value} </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item name="gerarBlocoK" className="t-mob-573" rules={[{ required: false, message: 'Marque esta opção se deseja gerar o bloco K' }]}>
                            <Checkbox> Gerar Bloco K? </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item name="gerarC170" className="t-mob-573" rules={[{ required: false, message: 'Marque esta opção se deseja gerar o registro C170 para todos os modelos de documentos' }]}>
                            <Checkbox> Gerar registro C170 para todos os modelos de documentos? </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item name="gerar0150" className="t-mob-573" rules={[{ required: false, message: 'Marque esta opção se deseja gerar o registro 0150 para para documentos modelo 65' }]}>
                            <Checkbox> Gerar registro 0150 para documentos modelo 65? </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item name="gerarC176" className="t-mob-573" rules={[{ required: false, message: 'Marque esta opção se deseja gerar o registro C176 para vendas interestaduais' }]}>
                            <Checkbox> Gerar registro C176 para vendas interestaduais? </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item name="dispensaNFCe" className="t-mob-573" rules={[{ required: false, message: 'Marque esta opção se optante pela dispensa das informações das NFC-es no SPED' }]}>
                            <Checkbox> Empresa optante pela dispensa da informação das NFC-es - IN 48/20? </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row align="middle" justify="end" gutter={[8, 0]} className="ant-drawer-footer footer-recebimento">
                    <Col>
                        <Button icon={<CloseOutlined />} size="large">
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <GerarEfdIcmsIpi type="primary" icon={<SaveOutlined />} loading={carregando} size="large" htmlType="submit" onClick={gerarEfdIcmsIpi}>
                            Gerar
                        </GerarEfdIcmsIpi>
                    </Col>
                </Row>
            </Form>
        </div >
    );
}