import React from "react";
import { Col, Row, Table, Tag } from "antd";

import moment from "moment";

export default function TabDetalheHistorico ({ historico }) {
    
    return (
        <div className="tabela">
            <Table dataSource={historico} columns={
                [
                    {
                        title: 'Status',
                        width: 100,
                        align: 'center',
                        render: ({ stp_descricao, stp_corstatus}) => (
                            <div>
                                <Row align="middle" justify="center">
                                    <Col>
                                        <Tag color={stp_corstatus} className="w-100">
                                            <b>{stp_descricao}</b>
                                        </Tag>
                                    </Col>
                                </Row>
                            </div>
                        )
                    },
                    {
                        title: 'Data de Alteração',
                        width: 100,
                        render: ({ peh_datahora }) => (
                            <div>
                                <b>{moment(peh_datahora).format('DD/MM/YYYY HH:mm')}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Usuário',
                        width: 200,
                        render: ({usu_nome}) => (
                            <div>
                                <b>{usu_nome}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Observação',
                        width: 200,
                        render: ({peh_observacao}) => (
                            <div>
                                <b>{peh_observacao ?? '-'}</b>
                            </div>
                        )
                    },
                ]
            }/>
        </div>
    )
}