import React, { useEffect, useState } from "react";
import { Card, Col, Form, Input, Row, Select, Spin } from "antd";

import { FormGW, InputCpfCnpj, InputPhone, SelectPaginacao } from "../../components";
import { MaskFormat } from "../../ValueObjects";
import api from "../../services/api";
import { useStateValue } from "../../state";
import { manutencaoActions, selectPaginadoActions } from "../../actions";

export default function TabRemetenteCTe({ formAutores, tomador, listaDocs, setIdTomador }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [remetenteFisico, setRemetenteFisico] = useState(true);
    const [destinatarioFisico, setDestinatarioFisico] = useState(true);
    const [expedidorFisico, setExpedidorFisico] = useState(true);
    const [recebedorFisico, setRecebedorFisico] = useState(true);
    const [outrosFisico, setOutrosFisico] = useState(true);
    const [tomadorOutros, setTomadorOutros] = useState(false);
    const [carregarPagina, setCarregarPagina] = useState(false);
    const [enderecoRemetente, setEnderecoRemetente] = useState([]);
    const [enderecoDestinatario, setEnderecoDestinatario] = useState([]);
    const [enderecoExpedidor, setEnderecoExpedidor] = useState([]);
    const [enderecoRecebedor, setEnderecoRecebedor] = useState([]);
    const [enderecoOutros, setEnderecoOutros] = useState([]);
    const tipo_prefix = {
        0: 'remetente',
        1: 'expedidor',
        2: 'recebedor',
        3: 'destinatario',
        4: 'outros'
    }

    useEffect(() => {
        if (formAutores.getFieldValue().cte_tomador) {
            tratarTomador();
        }
    }, [])

    useEffect(() => {
        if (listaDocs.length > 0) {
            setCarregarPagina(true);
            const nota = listaDocs[0];
            let lista = [];

            if (nota.pes_destinatario) {
                lista.push({ name: "pes_destinatario", campo: "Filtro", value: nota.pes_destinatario });
                setEnderecoDestinatario(nota.enderecosDest);

                let dest = {
                    pes_id: nota.pes_destinatario,
                    pes_nome: nota.razao_destinatario,
                    enderecos: nota.enderecosDest,
                    pej_inscricaoestadual: nota.ie_destinatario,
                }

                if (nota.cpfCnpj_destinatario.length === 11) {
                    setDestinatarioFisico(true);
                    formAutores.setFieldsValue({ cpf_destinatario: nota.cpfCnpj_destinatario });
                    dest.pef_cpf = nota.cpfCnpj_destinatario
                } else {
                    setDestinatarioFisico(false);
                    formAutores.setFieldsValue({ cnpj_destinatario: nota.cpfCnpj_destinatario });
                    dest.pej_cnpj = nota.cpfCnpj_destinatario
                }

                dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ destinatario: dest } } } });
                formAutores.setFieldsValue({
                    est_destinatario: nota.enderecosDest[0].est_sigla,
                    razao_destinatario: nota.razao_destinatario,
                    fantasia_destinatario: nota.fantasia_destinatario,
                    logradouro_destinatario: nota.enderecosDest[0].log_logradouro
                })
            }
            if (nota.pes_remetente) {
                lista.push({ name: "pes_remetente", campo: "Filtro", value: nota.pes_remetente });
                setEnderecoRemetente(nota.enderecosRem);

                if (nota.cpfCnpj_remetente.length === 11) {
                    setRemetenteFisico(true);
                    formAutores.setFieldsValue({ cpf_remetente: nota.cpfCnpj_remetente });
                } else {
                    setRemetenteFisico(false);
                    formAutores.setFieldsValue({ cnpj_remetente: nota.cpfCnpj_remetente });
                }

                formAutores.setFieldsValue({
                    est_remetente: nota.enderecosRem[0].est_sigla,
                    razao_remetente: nota.razao_remetente,
                    fantasia_remetente: nota.fantasia_remetente,
                    logradouro_remetente: nota.enderecosRem[0].log_logradouro,
                })
            }

            if (nota.enderecosRem[0].cid_id) {
                lista.push({ name: 'cid_id_munenvio', campo: 'IdCidade', value: nota.enderecosRem[0].cid_id });
                lista.push({ name: 'cid_id_muninicial', campo: 'IdCidade', value: nota.enderecosRem[0].cid_id });
            }
            if (nota.enderecosDest[0].cid_id) {
                lista.push({ name: 'cid_id_munfinal', campo: 'IdCidade', value: nota.enderecosDest[0].cid_id });
            }

            if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
            formAutores.setFieldsValue(nota);

            setTimeout(() => {
                setCarregarPagina(false);
            }, 1400)
        }
    }, [listaDocs])

    function buscarDadosEndereco(pee_id, tipoAutor) {
        let enderecosMap = {
            0: enderecoRemetente,
            1: enderecoExpedidor,
            2: enderecoRecebedor,
            3: enderecoDestinatario,
            4: enderecoOutros
        };
        let prefix = tipo_prefix[tipoAutor];
        let enderecos = enderecosMap[tipoAutor];
        if (!prefix || !enderecos);

        let end = enderecos.find(end => end.pee_id === pee_id);
        if (!end) return;

        formAutores.setFieldsValue({
            [`cep_${prefix}`]: end.cep_cep,
            [`bai_${prefix}`]: end.bai_nome,
            [`num_${prefix}`]: end.pee_numero,
            [`complemento_${prefix}`]: end.pee_complemento,
            [`est_${prefix}`]: end.est_sigla
        })
        if (end.pie_inscricaoestadual) {
            formAutores.setFieldsValue({ [`ie_${prefix}`]: end.pie_inscricaoestadual });
        }
    }

    function limparPessoa(autor) {
        if (autor === "destinatario") {
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ destinatario: null } } } });
        }

        formAutores.setFieldsValue({
            [`cpf_${autor}`]: null,
            [`cnpj_${autor}`]: null,
            [`ie_${autor}`]: null,
            [`email_${autor}`]: null,
            [`telefone_${autor}`]: null,
            [`endereco_${autor}`]: null,
            [`bai_${autor}`]: null,
            [`num_${autor}`]: null,
            [`complemento_${autor}`]: null,
            [`cep_${autor}`]: null,
            [`est_${autor}`]: null,
            [`razao_${autor}`]: null,
            [`logradouro_${autor}`]: null
        })
    }

    function tratarTomador(dados) {
        if (!dados && formAutores.getFieldValue().cte_tomador) {
            dados = formAutores.getFieldValue().cte_tomador;
        }

        if (dados === 4) {
            setTomadorOutros(true);
        } else {
            setTomadorOutros(false);
        }

        if (dados === 0) {
            setIdTomador(formAutores.getFieldValue().pes_remetente);
        } else if (dados === 1) {
            setIdTomador(formAutores.getFieldValue().pes_expedidor);
        } else if (dados === 2) {
            setIdTomador(formAutores.getFieldValue().pes_recebedor);
        } else if (dados === 3) {
            setIdTomador(formAutores.getFieldValue().pes_destinatario);
        } else if (dados === 4) {
            setIdTomador(formAutores.getFieldValue().pes_outros);
        }
    }

    async function carregarDadosPessoa(codigo, tipoAutor) {
        let prefix = tipo_prefix[tipoAutor];

        if (!!codigo) {
            let pessoa = (await api.get(`Cliente/BuscarByIdPessoa/${codigo}`)).data;

            if (!!pessoa) {
                retornarDadosCPFCNPJ(pessoa, tipoAutor, prefix);
                formAutores.setFieldsValue({
                    [`ie_${prefix}`]: pessoa.pej_inscricaoestadual ?? pessoa.enderecos[0].pie_inscricaoestadual,
                    [`razao_${prefix}`]: pessoa.pes_nome,
                    [`fantasia_${prefix}`]: pessoa.pej_nomefantasia
                });

                if (tipoAutor === 3) {
                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ destinatario: pessoa } } } });
                }

                if (pessoa.emails.length > 0) {
                    let emailPricipal = pessoa.emails.filter(f => f.pem_emailprincipal == true);
                    formAutores.setFieldsValue({ [`email_${prefix}`]: emailPricipal[0].pem_email });
                }
                if (pessoa.telefones.length > 0) {
                    let telefonePrincipal = pessoa.telefones.filter(f => f.psc_principal == true);
                    formAutores.setFieldsValue({ [`telefone_${prefix}`]: telefonePrincipal[0].psc_numero });
                }
                if (pessoa.enderecos.length > 0) {
                    if (tipoAutor === 0) {
                        setEnderecoRemetente(pessoa.enderecos);
                    } else if (tipoAutor === 1) {
                        setEnderecoExpedidor(pessoa.enderecos);
                    } else if (tipoAutor === 2) {
                        setEnderecoRecebedor(pessoa.enderecos);
                    } else if (tipoAutor === 3) {
                        setEnderecoDestinatario(pessoa.enderecos);
                    } else if (tipoAutor === 4) {
                        setEnderecoOutros(pessoa.enderecos);
                    }

                    let enderecoPrincipal = pessoa.enderecos.filter(f => f.pee_enderecoprincipal == true);
                    formAutores.setFieldsValue({
                        [`endereco_${prefix}`]: enderecoPrincipal[0].pee_id,
                        [`bai_${prefix}`]: enderecoPrincipal[0].bai_nome,
                        [`num_${prefix}`]: enderecoPrincipal[0].pee_numero,
                        [`complemento_${prefix}`]: enderecoPrincipal[0].pee_complemento,
                        [`cep_${prefix}`]: enderecoPrincipal[0].cep_cep,
                        [`est_${prefix}`]: enderecoPrincipal[0].est_sigla,
                        [`logradouro_${prefix}`]: enderecoPrincipal[0].log_logradouro
                    })
                }
                tratarTomador();
            } else {
                limparPessoa(prefix);
            }
        } else {
            limparPessoa(prefix);
        }
    }

    async function retornarDadosCPFCNPJ(pessoa, autor, prefix) {
        let setFisicoMap = {
            0: setRemetenteFisico,
            1: setExpedidorFisico,
            2: setRecebedorFisico,
            3: setDestinatarioFisico,
            4: setOutrosFisico
        }

        let setFisico = setFisicoMap[autor];
        if (!setFisico) return;

        let isCPF = !!pessoa.pef_cpf;
        setFisico(isCPF);

        formAutores.setFieldsValue({
            [`${isCPF ? 'cpf' : 'cnpj'}_${prefix}`]: isCPF ? pessoa.pef_cpf : pessoa.pej_cnpj
        })
    }

    return (
        <div className="pages-col">
            <Spin spinning={carregarPagina} tip="Carregando...">
                <FormGW layout="vertical" form={formAutores} name="formularioAutores">
                    <Row gutter={[8, 0]} style={{ marginTop: '10px' }}>
                        <Col xs={24} sm={12} md={12} lg={6} xl={3}>
                            <Form.Item label="Tomador" name="cte_tomador" rules={[{ required: true }]}>
                                <Select placeholder="Selecione o Tomador do CTe" allowClear onChange={(value) => tratarTomador(value)}>
                                    {tomador.map((item) => (
                                        <Select.Option value={item.key} key={item.key}>{item.value}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6} xl={7}>
                            <Form.Item label="Município de Envio" name="cid_id_munenvio" rules={[{ required: true, message: 'Informe o município de envio' }]}>
                                <SelectPaginacao url="Cidade/Listar" allowClear placeholder="Selecione a Cidade de Envio" nameLabel="cid_descricao" nameValue="cid_id_munenvio" form={formAutores} selecionarRegUnico="cid_id"
                                    conteudo={
                                        cid => (<Select.Option value={cid.cid_id} key={cid.cid_id}>{cid.cid_descricao} - {cid.estado.est_sigla}</Select.Option>)
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6} xl={7}>
                            <Form.Item label="Município Inicial" name="cid_id_muninicial" rules={[{ required: true, message: 'Informe o município inicial' }]}>
                                <SelectPaginacao url="Cidade/Listar" allowClear placeholder="Selecione a Cidade de Início" nameLabel="cid_descricao" nameValue="cid_id_muninicial" form={formAutores} selecionarRegUnico="cid_id"
                                    conteudo={
                                        cid => (<Select.Option value={cid.cid_id} key={cid.cid_id}>{cid.cid_descricao} - {cid.estado.est_sigla}</Select.Option>)
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6} xl={7}>
                            <Form.Item label="Município Final" name="cid_id_munfinal" rules={[{ required: true, message: 'Informe o município final' }]}>
                                <SelectPaginacao url="Cidade/Listar" allowClear placeholder="Selecione a Cidade Final" nameLabel="cid_descricao" nameValue="cid_id_munfinal" form={formAutores} selecionarRegUnico="cid_id"
                                    conteudo={
                                        cid => (<Select.Option value={cid.cid_id} key={cid.cid_id}>{cid.cid_descricao} - {cid.estado.est_sigla}</Select.Option>)
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "20px" }}>
                        <Col span={24}>
                            <Card
                                title="Remetente"
                                bordered={false}
                                headStyle={{ borderBottom: "none", position: "absolute", top: "-19px", left: "30px", backgroundColor: "white", padding: "0 32px" }}
                                style={{ border: "2px solid rgb(209, 209, 209)" }}
                            >
                                <Row gutter={[8, 0]}>
                                    <Col xs={24} sm={18} md={20} lg={8} xl={8}>
                                        <Form.Item name="pes_remetente">
                                            <SelectPaginacao url="Cliente/Listar"
                                                allowClear
                                                placeholder="Selecione uma pessoa remetente"
                                                form={formAutores}
                                                nameLabel="pes_nome"
                                                nameValue="pes_remetente"
                                                onChangeFunction={(dados) => carregarDadosPessoa(dados, 0)}
                                                conteudo={
                                                    pc => (<Select.Option value={pc.pes_id} key={pc.key}>{pc.cli_codigo} - {pc.pes_nome} <br />
                                                        {(!!pc.pej_cnpj ? `CNPJ.: ${MaskFormat(pc.pej_cnpj, '', true)}` : !!pc.pef_cpf ? `CPF.: ${MaskFormat(pc.pef_cpf, '', true)}` : '')}
                                                    </Select.Option>)
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    {remetenteFisico && <Col xs={24} sm={6} md={4} lg={3} xl={3}>
                                        <InputCpfCnpj cpf name="cpf_remetente" />
                                    </Col>}
                                    {!remetenteFisico && <Col xs={24} sm={6} md={4} lg={3} xl={3}>
                                        <InputCpfCnpj name="cnpj_remetente" />
                                    </Col>}
                                    <Col xs={24} sm={8} md={8} lg={4} xl={4}>
                                        <Form.Item name="ie_remetente">
                                            <Input placeholder="Inscrição Estadual" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={8} md={8} lg={3} xl={3}>
                                        <InputPhone placeholder="N° Telefone" name="telefone_remetente" />
                                    </Col>
                                    <Col xs={24} sm={8} md={8} lg={6} xl={6}>
                                        <Form.Item name="email_remetente">
                                            <Input placeholder="E-mail" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[8, 0]}>
                                    <Col xs={24} sm={10} md={8} lg={9} xl={10}>
                                        <Form.Item name="endereco_remetente">
                                            <Select placeholder="Informe o Endereço" onChange={(value) => buscarDadosEndereco(value, 0)} >
                                                {enderecoRemetente.map(
                                                    (endereco) => (
                                                        <Select.Option value={endereco.pee_id} key={endereco.pee_id}>{endereco.log_logradouro}{', '}{endereco.cid_descricao}{' - '}{endereco.est_sigla}
                                                        </Select.Option>
                                                    )
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Form.Item name="cep_remetente" hidden></Form.Item>
                                    <Col xs={24} sm={5} md={6} lg={6} xl={5}>
                                        <Form.Item name="bai_remetente">
                                            <Input placeholder="Informe o Bairro" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={3} md={4} lg={3} xl={3}>
                                        <Form.Item name="num_remetente">
                                            <Input placeholder="Informe o Número" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={6} md={6} lg={6} xl={6}>
                                        <Form.Item name="complemento_remetente">
                                            <Input placeholder="Informe o Complemento" maxLength={60} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "35px" }}>
                        <Col span={24}>
                            <Card
                                title="Destinatário"
                                bordered={false}
                                headStyle={{ borderBottom: "none", position: "absolute", top: "-19px", left: "30px", backgroundColor: "white", padding: "0 32px" }}
                                style={{ border: "2px solid rgb(209, 209, 209)" }}
                            >
                                <Row gutter={[8, 0]}>
                                    <Col xs={24} sm={18} md={20} lg={8} xl={8}>
                                        <Form.Item name="pes_destinatario">
                                            <SelectPaginacao url="Cliente/Listar"
                                                allowClear
                                                placeholder="Selecione uma pessoa destinatário"
                                                form={formAutores}
                                                nameLabel="pes_nome"
                                                nameValue="pes_destinatario"
                                                onChangeFunction={(dados) => carregarDadosPessoa(dados, 3)}
                                                conteudo={
                                                    pc => (<Select.Option value={pc.pes_id} key={pc.key}>{pc.cli_codigo} - {pc.pes_nome} <br />
                                                        {(!!pc.pej_cnpj ? `CNPJ.: ${MaskFormat(pc.pej_cnpj, '', true)}` : !!pc.pef_cpf ? `CPF.: ${MaskFormat(pc.pef_cpf, '', true)}` : '')}
                                                    </Select.Option>)
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    {destinatarioFisico && <Col xs={24} sm={6} md={4} lg={3} xl={3}>
                                        <InputCpfCnpj cpf name="cpf_destinatario" />
                                    </Col>}
                                    {!destinatarioFisico && <Col xs={24} sm={6} md={4} lg={3} xl={3}>
                                        <InputCpfCnpj name="cnpj_destinatario" />
                                    </Col>}
                                    <Col xs={24} sm={8} md={8} lg={4} xl={4}>
                                        <Form.Item name="ie_destinatario">
                                            <Input placeholder="Inscrição Estadual" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={8} md={8} lg={3} xl={3}>
                                        <InputPhone placeholder="N° Telefone" name="telefone_destinatario" />
                                    </Col>
                                    <Col xs={24} sm={8} md={8} lg={6} xl={6}>
                                        <Form.Item name="email_destinatario">
                                            <Input placeholder="E-mail" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[8, 0]}>
                                    <Col xs={24} sm={10} md={8} lg={9} xl={10}>
                                        <Form.Item name="endereco_destinatario">
                                            <Select placeholder="Informe o Endereço" onChange={(value) => buscarDadosEndereco(value, 3)} >
                                                {enderecoDestinatario.map(
                                                    (endereco) => (
                                                        <Select.Option value={endereco.pee_id} key={endereco.pee_id}>{endereco.log_logradouro}{', '}{endereco.cid_descricao}{' - '}{endereco.est_sigla}
                                                        </Select.Option>
                                                    )
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Form.Item name="cep_destinatario" hidden></Form.Item>
                                    <Col xs={24} sm={5} md={6} lg={6} xl={5}>
                                        <Form.Item name="bai_destinatario">
                                            <Input placeholder="Informe o Bairro" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={3} md={4} lg={3} xl={3}>
                                        <Form.Item name="num_destinatario">
                                            <Input placeholder="Informe o Número" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={6} md={6} lg={6} xl={6}>
                                        <Form.Item name="complemento_destinatario">
                                            <Input placeholder="Informe o Complemento" maxLength={60} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "35px" }}>
                        <Col span={24}>
                            <Card
                                title="Expedidor"
                                bordered={false}
                                headStyle={{ borderBottom: "none", position: "absolute", top: "-19px", left: "30px", backgroundColor: "white", padding: "0 32px" }}
                                style={{ border: "2px solid rgb(209, 209, 209)" }}
                            >
                                <Row gutter={[8, 0]}>
                                    <Col xs={24} sm={18} md={20} lg={8} xl={8}>
                                        <Form.Item name="pes_expedidor">
                                            <SelectPaginacao url="Cliente/Listar"
                                                allowClear
                                                placeholder="Selecione uma pessoa expedidor"
                                                form={formAutores}
                                                nameLabel="pes_nome"
                                                nameValue="pes_expedidor"
                                                onChangeFunction={(dados) => carregarDadosPessoa(dados, 1)}
                                                conteudo={
                                                    pc => (<Select.Option value={pc.pes_id} key={pc.key}>{pc.cli_codigo} - {pc.pes_nome} <br />
                                                        {(!!pc.pej_cnpj ? `CNPJ.: ${MaskFormat(pc.pej_cnpj, '', true)}` : !!pc.pef_cpf ? `CPF.: ${MaskFormat(pc.pef_cpf, '', true)}` : '')}
                                                    </Select.Option>)
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    {expedidorFisico && <Col xs={24} sm={6} md={4} lg={3} xl={3}>
                                        <InputCpfCnpj cpf name="cpf_expedidor" />
                                    </Col>}
                                    {!expedidorFisico && <Col xs={24} sm={6} md={4} lg={3} xl={3}>
                                        <InputCpfCnpj name="cnpj_expedidor" />
                                    </Col>}
                                    <Col xs={24} sm={8} md={8} lg={4} xl={4}>
                                        <Form.Item name="ie_expedidor">
                                            <Input placeholder="Inscrição Estadual" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={8} md={8} lg={3} xl={3}>
                                        <InputPhone placeholder="N° Telefone" name="telefone_expedidor" />
                                    </Col>
                                    <Col xs={24} sm={8} md={8} lg={6} xl={6}>
                                        <Form.Item name="email_expedidor">
                                            <Input placeholder="E-mail" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[8, 0]}>
                                    <Col xs={24} sm={10} md={8} lg={9} xl={10}>
                                        <Form.Item name="endereco_expedidor">
                                            <Select placeholder="Informe o Endereço" onChange={(value) => buscarDadosEndereco(value, 1)} >
                                                {enderecoExpedidor.map(
                                                    (endereco) => (
                                                        <Select.Option value={endereco.pee_id} key={endereco.pee_id}>{endereco.log_logradouro}{', '}{endereco.cid_descricao}{' - '}{endereco.est_sigla}
                                                        </Select.Option>
                                                    )
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Form.Item name="cep_expedidor" hidden></Form.Item>
                                    <Col xs={24} sm={5} md={6} lg={6} xl={5}>
                                        <Form.Item name="bai_expedidor">
                                            <Input placeholder="Informe o Bairro" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={3} md={4} lg={3} xl={3}>
                                        <Form.Item name="num_expedidor">
                                            <Input placeholder="Informe o Número" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={6} md={6} lg={6} xl={6}>
                                        <Form.Item name="complemento_expedidor">
                                            <Input placeholder="Informe o Complemento" maxLength={60} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "35px" }}>
                        <Col span={24}>
                            <Card
                                title="Recebedor"
                                bordered={false}
                                headStyle={{ borderBottom: "none", position: "absolute", top: "-19px", left: "30px", backgroundColor: "white", padding: "0 32px" }}
                                style={{ border: "2px solid rgb(209, 209, 209)" }}
                            >
                                <Row gutter={[8, 0]}>
                                    <Col xs={24} sm={18} md={20} lg={8} xl={8}>
                                        <Form.Item name="pes_recebedor">
                                            <SelectPaginacao url="Cliente/Listar"
                                                allowClear
                                                placeholder="Selecione uma pessoa recebedor"
                                                form={formAutores}
                                                nameLabel="pes_nome"
                                                nameValue="pes_recebedor"
                                                onChangeFunction={(dados) => carregarDadosPessoa(dados, 2)}
                                                conteudo={
                                                    pc => (<Select.Option value={pc.pes_id} key={pc.key}>{pc.cli_codigo} - {pc.pes_nome} <br />
                                                        {(!!pc.pej_cnpj ? `CNPJ.: ${MaskFormat(pc.pej_cnpj, '', true)}` : !!pc.pef_cpf ? `CPF.: ${MaskFormat(pc.pef_cpf, '', true)}` : '')}
                                                    </Select.Option>)
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    {recebedorFisico && <Col xs={24} sm={6} md={4} lg={3} xl={3}>
                                        <InputCpfCnpj cpf name="cpf_recebedor" />
                                    </Col>}
                                    {!recebedorFisico && <Col xs={24} sm={6} md={4} lg={3} xl={3}>
                                        <InputCpfCnpj name="cnpj_recebedor" />
                                    </Col>}
                                    <Col xs={24} sm={8} md={8} lg={4} xl={4}>
                                        <Form.Item name="ie_recebedor">
                                            <Input placeholder="Inscrição Estadual" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={8} md={8} lg={3} xl={3}>
                                        <InputPhone placeholder="N° Telefone" name="telefone_recebedor" />
                                    </Col>
                                    <Col xs={24} sm={8} md={8} lg={6} xl={6}>
                                        <Form.Item name="email_recebedor">
                                            <Input placeholder="E-mail" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[8, 0]}>
                                    <Col xs={24} sm={10} md={8} lg={9} xl={10}>
                                        <Form.Item name="endereco_recebedor">
                                            <Select placeholder="Informe o Endereço" onChange={(value) => buscarDadosEndereco(value, 2)} >
                                                {enderecoRecebedor.map(
                                                    (endereco) => (
                                                        <Select.Option value={endereco.pee_id} key={endereco.pee_id}>{endereco.log_logradouro}{', '}{endereco.cid_descricao}{' - '}{endereco.est_sigla}
                                                        </Select.Option>
                                                    )
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Form.Item name="cep_recebedor" hidden></Form.Item>
                                    <Col xs={24} sm={5} md={6} lg={6} xl={5}>
                                        <Form.Item name="bai_recebedor">
                                            <Input placeholder="Informe o Bairro" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={3} md={4} lg={3} xl={3}>
                                        <Form.Item name="num_recebedor">
                                            <Input placeholder="Informe o Número" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={6} md={6} lg={6} xl={6}>
                                        <Form.Item name="complemento_recebedor">
                                            <Input placeholder="Informe o Complemento" maxLength={60} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    {tomadorOutros && <Row style={{ marginTop: "35px" }}>
                        <Col span={24}>
                            <Card
                                title="Outros"
                                bordered={false}
                                headStyle={{ borderBottom: "none", position: "absolute", top: "-19px", left: "30px", backgroundColor: "white", padding: "0 32px" }}
                                style={{ border: "2px solid rgb(209, 209, 209)" }}
                            >
                                <Row gutter={[8, 0]}>
                                    <Col xs={24} sm={18} md={20} lg={8} xl={8}>
                                        <Form.Item name="pes_outros">
                                            <SelectPaginacao url="Cliente/Listar"
                                                allowClear
                                                placeholder="Selecione uma pessoa outros"
                                                form={formAutores}
                                                nameLabel="pes_nome"
                                                nameValue="pes_outros"
                                                onChangeFunction={(dados) => carregarDadosPessoa(dados, 4)}
                                                conteudo={
                                                    pc => (<Select.Option value={pc.pes_id} key={pc.key}>{pc.cli_codigo} - {pc.pes_nome} <br />
                                                        {(!!pc.pej_cnpj ? `CNPJ.: ${MaskFormat(pc.pej_cnpj, '', true)}` : !!pc.pef_cpf ? `CPF.: ${MaskFormat(pc.pef_cpf, '', true)}` : '')}
                                                    </Select.Option>)
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    {outrosFisico && <Col xs={24} sm={6} md={4} lg={3} xl={3}>
                                        <InputCpfCnpj cpf name="cpf_outros" />
                                    </Col>}
                                    {!outrosFisico && <Col xs={24} sm={6} md={4} lg={3} xl={3}>
                                        <InputCpfCnpj name="cnpj_outros" />
                                    </Col>}
                                    <Col xs={24} sm={8} md={8} lg={4} xl={4}>
                                        <Form.Item name="ie_outros">
                                            <Input placeholder="Inscrição Estadual" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={8} md={8} lg={3} xl={3}>
                                        <InputPhone placeholder="N° Telefone" name="telefone_outros" />
                                    </Col>
                                    <Col xs={24} sm={8} md={8} lg={6} xl={6}>
                                        <Form.Item name="email_outros">
                                            <Input placeholder="E-mail" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[8, 0]}>
                                    <Col xs={24} sm={10} md={8} lg={9} xl={10}>
                                        <Form.Item name="endereco_outros">
                                            <Select placeholder="Informe o Endereço" onChange={(value) => buscarDadosEndereco(value, 4)} >
                                                {enderecoOutros.map(
                                                    (endereco) => (
                                                        <Select.Option value={endereco.pee_id} key={endereco.pee_id}>{endereco.log_logradouro}{', '}{endereco.cid_descricao}{' - '}{endereco.est_sigla}
                                                        </Select.Option>
                                                    )
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Form.Item name="cep_outros" hidden></Form.Item>
                                    <Col xs={24} sm={5} md={6} lg={6} xl={5}>
                                        <Form.Item name="bai_outros">
                                            <Input placeholder="Informe o Bairro" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={3} md={4} lg={3} xl={3}>
                                        <Form.Item name="num_outros">
                                            <Input placeholder="Informe o Número" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={6} md={6} lg={6} xl={6}>
                                        <Form.Item name="complemento_outros">
                                            <Input placeholder="Informe o Complemento" maxLength={60} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>}
                </FormGW>
            </Spin>
        </div>
    )
}