import React, { createRef } from 'react';
import moment from 'moment';
import { Form, Input, message } from 'antd';

export default function DataHora(props) {
    function subtrairSomarDias(data, dias, soma) {
        if (soma) {
            return new Date(data.getTime() + (dias * 24 * 60 * 60 * 1000));
        } else {
            return new Date(data.getTime() - (dias * 24 * 60 * 60 * 1000));
        }
    }

    class NovaDataHora extends React.Component {
        constructor(props) {
            super(props);
            this.inputRef = createRef();
        }

        // Função de validação para garantir que a data não seja menor que a atual
        validarData = (data) => {
            const { minDate, errorMessage } = this.props;
            const dataAtual = moment();
            
            if (data.isBefore(minDate)) {
                message.error(errorMessage || `A data não pode ser anterior a ${minDate.format('DD/MM/YYYY HH:mm')}`);
                return false; // Se a data for menor que a mínima, retorna falso
            }
            return true;
        };

        onChange = (e) => {
            const { value } = e.target;
            let data = moment(value, "YYYY-MM-DDTHH:mm", true); // Formato com data e hora

            if (data.isValid() && this.validarData(data)) {
                this.props.onChange(data);
            } else {
                this.props.onChange(null); // Ou você pode retornar uma mensagem de erro aqui
            }
        };

        onKeyUp = (e) => {
            let key = e.which || e.keyCode;
            let data = null;

            if (key === 68) { // Tecla 'D' - data atual
                data = new Date();
            }
            if (key === 79) { // Tecla 'O' - data anterior
                data = subtrairSomarDias(new Date(), 1, false);
            }
            if (key === 65) { // Tecla 'A' - data posterior
                data = subtrairSomarDias(new Date(), 1, true);
            }
            if (key === 73) { // Tecla 'I' - data inicial do mês
                let date = new Date();
                data = new Date(date.getFullYear(), date.getMonth(), 1);
            }
            if (key === 70) { // Tecla 'F' - data final do mês
                let date = new Date();
                data = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            }

            if (!!data && this.validarData(moment(data))) {
                this.props.onChange(moment(data));
            }

            if (this.props.onKeyUp) {
                this.props.onKeyUp();
            }
        };

        onPressEnter = () => {
            const inputElement = this.inputRef.current.input;
            const form = inputElement.closest('form');
            if (form) {
                const inputs = Array.from(form.querySelectorAll('input'));
                const currentIndex = inputs.indexOf(inputElement);
                const nextInput = inputs[currentIndex + 1];
                if (nextInput) {
                    nextInput.focus();
                }
            }
        };

        onBlur = () => {
            if (this.props.onBlur) {
                this.props.onBlur();
            }
        };
        

        render() {
            const { value, disabled, format } = this.props;
            let data = value ? moment(value, format) : null;

            // Corrigindo para aceitar a hora corretamente no formato
            return (
                <Input
                    ref={this.inputRef}
                    className="text-uppercase"
                    disabled={disabled}
                    type="datetime-local"
                    value={data ? data.format('YYYY-MM-DDTHH:mm') : ''} // Formato ISO
                    onChange={this.onChange}
                    onKeyUp={this.onKeyUp}
                    onPressEnter={this.onPressEnter}
                    onBlur={this.onBlur}
                />
            );
        }
    };

    class DataHoraInput extends React.Component {
        onChange = () => {
            if (this.props.onChange) {
                this.props.onChange();
            }
        };

        render() {
            const { name, label, initialValue, style, className, hidden, rules, minDate, errorMessage } = this.props;

            return (
                <div className="form-data">
                    <Form.Item
                        name={name}
                        label={label}
                        initialValue={initialValue}
                        style={style || {}}
                        className={className || ''}
                        hidden={hidden}
                        rules={rules || []}
                    >
                        <NovaDataHora 
                            {...this.props} 
                            onChange={this.onChange} 
                            minDate={minDate}
                            errorMessage={errorMessage}
                        />
                    </Form.Item>
                </div>
            );
        }
    };

    return (
        <DataHoraInput {...props} />
    );
}
