import React, { useEffect, useState } from "react";
import { Col, Divider, Form, Input, notification, Row, Select, Typography } from "antd";
import { SelectPaginacao, SelectPaginado } from "../../components";
import { selectPaginadoActions } from "../../actions";
import { getRegimeTributario } from "../../services/auth";
import { useStateValue } from "../../state";
import api from "../../services/api";

export default function TabFreteTributario({ formulario }) {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [regimeTrib, setRegimeTrib] = useState(parseInt(getRegimeTributario()));
   
    useEffect(() => carregarDados(), []);

    function carregarDados() {

        let lista = [];
        if (!!manutencao.dados?.icm_id)
            lista.push({ name: "icm_id", campo: "Codigo", value: manutencao.dados?.icm_id })

        if (!!manutencao.dados?.pis_id)
            lista.push({ name: "pis_id", campo: "Id", value: manutencao.dados?.pis_id });

        if (lista.length > 0) {
            dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
        }
    };

  
    return (
        <div>
            <Row align="middle" gutter={[0, 16]}>
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-bill.png").default} alt="Dados Tributários" /> Dados Tributários
                    </Typography.Title>
                    <Divider orientation="left" plain>
                        Defina as Tributações do Frete.
                    </Divider>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <Form.Item label="Classificação ICMS" name="icm_id" rules={[{ required: true, message: 'Selecione a Classificação ICMS' }]}>
                        <SelectPaginado url="tributacaoIcms/Listar"
                            name="icm_id"
                            form={formulario}
                            placeholder="Selecione o ICMS"
                            conteudo={
                                icms => (<Select.Option value={icms.icm_id} key={icms.icm_id}>{icms.icm_id} - {icms.icm_descricao}</Select.Option>)
                            }
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <Form.Item label="Classificação PIS / COFINS" name="pis_id" rules={[{ required: regimeTrib === 3 ? true : false, message: 'Selecione a Classificação PIS / COFINS' }]}>
                        <SelectPaginado url="tributacaoPisCofins/Listar"
                            name="pis_id"
                            form={formulario}
                            placeholder="Selecione o Pis/Cofins"
                            allowClear={true}
                            conteudo={
                                pis => (<Select.Option value={pis.pis_id} key={pis.pis_id}>{pis.pis_id} - {pis.pis_descricao}</Select.Option>)
                            }
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[0, 8]}>
                <Col span={24}>
                    <Form.Item label="Observações Complementares" name="pro_observacao">
                        <Input.TextArea placeholder="Informe Observações Referente o Frete" />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    )
}