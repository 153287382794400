import React, { useState, useEffect } from "react";
import { SelectPaginacao } from '../../components';
import { Form, Row, Col, Input, Button, Tooltip, Select, notification, Modal } from "antd";
import { CloseOutlined, SaveOutlined, QuestionCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { InputPreco } from "../../components";
import { BreadcrumbAbrirChamado } from "../../components";

export default function ParametrosLocal() {
    const [formulario] = Form.useForm();
    const [controleAvisoEstoque, setControleAvisoEstoque] = useState([]);
    const [tipoTef, setTipoTef] = useState([]);
    useEffect(() => {
        carregarDados();

        api.get('Enum/Listar?nome=ControleEstoque').then(res => {
            if (res.status === 200) {
                setControleAvisoEstoque(res.data);
            }
        }).catch((erro) => console.error(erro));
        api.get('Enum/Listar?nome=TipoTEF').then(res => {
            if (res.status === 200) {
                setTipoTef(res.data);
            }
        }).catch((erro) => console.error(erro));
    }, []);



    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                window.location = '/Home#/'
            }
        });
    };
    const salvarConf = valores => {
        let dados = { ...valores };

        api.put('ParametroLocal/Editar', dados).then(
            res => {
                notification.success({ message: 'Operação concluída', description: "Parâmetros do Local salvos com sucesso!" });
            }).catch(
                error => {
                    console.log(error);
                }).finally(
                    () => {
                    });
    };

    function carregarDados() {
        api.get(`ParametroLocal/BuscarParametros`).then(
            res => {
                if (!!res.data) {
                    formulario.setFieldsValue(res.data);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    return (
        <div className="p-10">
            <BreadcrumbAbrirChamado />
            <Form layout="vertical" name="formParametrosLocais" form={formulario} onFinish={salvarConf}>
                <Row gutter={[8, 0]}>
                    <Form.Item name="par_id" hidden><Input /></Form.Item>
                    <Col xs={24} sm={3} md={3} lg={3} xl={3}>
                        <InputPreco label={
                            <div>
                                % Máximo Desconto
                                <Tooltip title="Percentual  % máximo de desconto permitido no local">
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </div>
                        } placeholder="Informe o % desconto máximo " name="par_maximodesconto" cifrao={false}></InputPreco>
                    </Col>
                    <Col xs={24} sm={3} md={3} lg={3} xl={3}>
                        <InputPreco label={
                            <div>
                                Markup Local
                                <Tooltip title="Markup padrão do local para ser aplicado nos produtos">
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </div>
                        } placeholder="Informe o Markup Padrão" name="par_markupproduto" cifrao={false}></InputPreco>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={7} xl={6}>
                        <Form.Item name="ope_id" label="Operação Fiscal Padrão NFC-e">
                            <SelectPaginacao url="OperacaoFiscal/ListarOperacaoFiscal?SomenteAtivo=true&TipoOperacao=1" placeholder="Selecione uma operação para venda NFC-e" form={formulario} nameLabel="ope_id"
                                nameValue="ope_id" allowClear={true}
                                conteudo={
                                    op => (<Select.Option value={op.ope_id} key={op.key}>{op.ope_id} - {op.ope_descricao} <br />
                                    </Select.Option>)
                                } />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={4} xl={4}>
                        <Form.Item name="par_avisoestoqueinsuficiente" label={
                            <div>
                                Aviso Estoque Insuficiente
                                <Tooltip title="Selecione o tipo de controle para aviso de estoque insuficiente">
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </div>
                        }>
                            <Select placeholder="Selecione o tipo de aviso" showSearch optionFilterProp="children" allowClear={true}>
                                {controleAvisoEstoque.map((item) => (
                                    <Select.Option value={item.key} key={item.key}>{item.key} - {item.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={6} md={6} lg={3} xl={3}>
                        <Form.Item name="par_tipotef" label={
                            <div>
                                Tipo TEF
                                <Tooltip title="Selecione o tipo de TEF do local">
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </div>
                        }>
                            <Select placeholder="Selecione o tipo de TEF do local" showSearch optionFilterProp="children" allowClear={true}>
                                {tipoTef.map((item) => (
                                    <Select.Option value={item.key} key={item.key}>{item.key} - {item.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={4} xl={4}>
                        <Form.Item name="par_numerowhatsapp"
                            label={
                                <div>
                                    Número WhatsApp
                                    <Tooltip title="Este é o número telefone utilizado para integração WhatsApp e enviar documentos eletrônicos a seus parceiros.">
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </div>
                            }>
                            <Input placeholder="Ex: 55999887766" maxLength={15} />
                        </Form.Item>
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={6} xs={24}>
                        <Form.Item label="Código Empresa(Payer)" name="par_payeridempresa">
                            <Input placeholder="Id Empresa" maxLength={6} />
                        </Form.Item>
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={6} xs={24}>
                        <Form.Item label="Código Loja(Payer)" name="par_payeridloja">
                            <Input placeholder="Id Loja" maxLength={4} />
                        </Form.Item>
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={6} xs={24}>
                        <Form.Item label="Código Terminal(Payer)" name="par_payeridterminal">
                            <Input placeholder="Id Terminal" maxLength={2} />
                        </Form.Item>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={6} xs={24}>
                        <Form.Item label="Usúario (Payer)" name="par_payeruser">
                            <Input placeholder="Usúario Integração API Gateway" />
                        </Form.Item>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={6} xs={24}>
                        <Form.Item label="Senha (Payer)" name="par_payersenha">
                            <Input placeholder="Senha Integração API Gateway" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Form.Item label="Descritivo Para Documentos Fiscais" name="par_mensagemdocumentos">
                            <Input.TextArea 
                                 showCount 
                                 autoSize={{ minRows: 2, maxRows: 6 }}
                                 placeholder="Informe o Descritivo para Documentos Fiscais" maxLength={1000} />
                        </Form.Item>
                    </Col>
                </Row>
                <div className="ant-drawer-footer footer-recebimento">
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button icon={<SaveOutlined />} type="primary" size="large" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        </div>
    );
}
