import React, { useEffect, useState } from "react";
import { Col, Row, Table, Tag } from "antd";

import moment from "moment";
import { FormatNumber, MaskFormat } from "../../ValueObjects";

export default function TabDetalheRelacaoNota({ dadosNota, listaStatusNota }) {
    const [listaNotas, setListaNotas] = useState([]);

    useEffect(() => {
        if (dadosNota) {
            let listaTemp = dadosNota.map((item, index) => ({
                ...item,
                key: index
            }));

            setListaNotas(listaTemp);
        }
    }, [])

    function retornaDescricaoStatus(ntf_status) {
        if (!!listaStatusNota) {
            let status = listaStatusNota.filter((statusNota) => (statusNota.key === ntf_status))[0];
            return !!status ? status.value : "";
        }
    };

    return (
        <div>
            <Row>
                <Col>
                    {listaNotas.length > 0 ? <b>Notas emitidas pelo pedido atual:</b> : <b>Nota emitida pelo pedido atual:</b>}
                </Col>
            </Row>
            <div className="tabela">
                <Table dataSource={listaNotas} columns={
                    [
                        {
                            title: 'Número',
                            width: 50,
                            align: 'center',
                            render: ({ ntf_numero }) => (
                                <div>
                                    <Tag color="processing" className="w-75">
                                        <b>{ntf_numero}</b>
                                    </Tag>
                                </div>
                            )
                        },
                        {
                            title: 'Status',
                            width: 130,
                            align: 'center',
                            render: ({ ntf_status, tip_descricao }) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 5]}>
                                        <Col span={10}>
                                            <Tag color={((ntf_status === 0) ? 'default' : (ntf_status === 1 ? 'green' : (ntf_status === 2 ? 'gold' : (ntf_status === 3 ? 'orange' : (ntf_status === 4 ? 'red' : (ntf_status === 5 ? 'lime' : ''))))))}>
                                                <b>
                                                    {retornaDescricaoStatus(ntf_status)}
                                                </b>
                                            </Tag>
                                        </Col>
                                        <Col span={14}>
                                            <Tag color='processing'>
                                                <b>{tip_descricao}</b>
                                            </Tag>
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                        {
                            title: 'CPF/CNPJ',
                            width: 100,
                            render: ({ ntf_cnpjcpfdest }) => (
                                <div>
                                    <b>{MaskFormat(ntf_cnpjcpfdest, 'Não Informado', true)}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Cliente',
                            width: 250,
                            render: ({ ntf_nomedest }) => (
                                <div>
                                    <b>{ntf_nomedest}</b>
                                </div>
                            )
                        },
                        {
                            title: 'Emissão',
                            width: 100,
                            render: ({ ntf_dataemissao }) => (
                                <div>
                                    <b>{moment(ntf_dataemissao).format('DD/MM/YYYY HH:mm')}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Modelo',
                            width: 50,
                            align: 'center',
                            render: ({ ntf_modelo, ser_serie }) => (
                                <div>
                                    <Row justify="center" gutter={[8, 0]}>
                                        <Col>
                                            <b>{ntf_modelo}</b>
                                        </Col>
                                        <Col>
                                            <b>-</b>
                                        </Col>
                                        <Col>
                                            <b>{ser_serie}</b>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        },
                        {
                            title: "Total (R$)",
                            width: 70,
                            align: 'right',
                            render: ({ valortotalnota }) => (
                                <div>
                                    <b>{FormatNumber(valortotalnota, true)}</b>
                                </div>
                            )
                        },
                    ]
                } />
            </div>
        </div>
    )
}