import { useEffect, useState } from "react";
import { useStateValue } from "../../state";
import { manutencaoActions, selectPaginadoActions } from "../../actions";
import api from "../../services/api";
import { Checkbox, Col, Form, Input, InputNumber, notification } from "antd";
import { ForkOutlined } from "@ant-design/icons";

export default function ManutencaoComponentesFrete({ formulario, carregando, aoSalvar, editando }) {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [fileList, setFileList] = useState([]);

    useEffect(() => {

        if (ui.showDrawer && !!manutencao.dados && editando.editando) {
            let lista = [];
            if (!!manutencao.dados.cmf_descricao) {
                lista.push({ name: "cmf_descricao", campo: "Nome", value: manutencao.dados.cmf_id });
            }
            if (!!manutencao.dados.cmf_percentual) {
                lista.push({ name: "cmf_percentual", campo: "percentual", value: manutencao.dados.cmf_descricao });
            }
            if (lista.length > 0) {
                dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
                editando.setEditando(false);
            }
        }
    }, [ui.showDrawer, manutencao.dados]);

    const salvarManutencao = (values) => {
        carregando(true);
        let retorno;
        let enderecoUrl = 'ComponentesFrete/';

        if (values.cmf_id) {
            enderecoUrl += `Editar`;
            api.put(enderecoUrl, values).then(res => {
                if (res.status === 200) {
                    notification.success({ message: 'Operação concluída', description: "As novas configurações foram salvas com sucesso!" });
                    retorno = res.data;
                }
            }, err => {
                notification.error({ message: 'Erro ao processar requisição.', description: JSON.stringify(err.response) });
            }).finally(
                () => {
                    carregando(false);
                    aoSalvar(retorno);
                    setFileList([]);
                })
        } else {
            enderecoUrl += `Incluir`;
            api.post(enderecoUrl, values).then(res => {
                if (res.status === 200) {
                    notification.success({ message: 'Operação concluída', description: "As configurações foram incluídas com sucesso!" });
                    retorno = res.data;
                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: res.data } });
                }
            }, err => {
                notification.error({ message: 'Erro ao processar requisição.', description: JSON.stringify(err.response) });
            }).finally(
                () => {
                    carregando(false);
                    aoSalvar(retorno);
                    setFileList([]);
                });
        }
    }


    return (
        <Form layout="vertical" form={formulario} name="ManutencaoComponentesFrete" onFinish={salvarManutencao}>
            <Form.Item name="cmf_id" hidden={true}>
                <Input />
            </Form.Item>

            <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                <Form.Item label="Descrição componente de Frete" name="cmf_descricao">
                    <Input placeholder="Descrição do componente" />
                </Form.Item>
            </Col>

            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <Form.Item label="Percentual %" name="cmf_percentual" >
                    <InputNumber
                        min={0}
                        max={100}
                        controls={false}
                        defaultValue={0}
                        formatter={value => `${value}%`}
                        parser={value => value.replace('%', '')} />
                </Form.Item>
            </Col>
            <Col>
                <Form.Item name="cmf_principal" valuePropName='checked' initialValue={false}>
                    <Checkbox> Componente principal? </Checkbox>
                </Form.Item>
            </Col>

        </Form>
    );
}