import { useEffect, useState } from "react";
import { BreadcrumbIncluirDados, BreadcrumbPage, Editar, Excluir, FiltroOrdenacao, PesquisaOrdenacao, SelectPaginacao, TabelaDados } from "../../components";
import api from "../../services/api";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { Col, Form, Input, Modal, notification, Radio, Row, Select } from "antd";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useStateValue } from "../../state";
import DrawerCorrelacaoCfop from './drawer'


export default function CorrelacaoCfop() {

    const [{ ui, manutencao, listagem }, dispatch] = useStateValue();
    const [form] = Form.useForm();
    const [editando, setEditando] = useState(false);
    const [listaTipo, setListaTipo] = useState([]);



    useEffect(() => dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: '', filtro: '', ordem: '+crr_id' } }), [listagemActions]);


    useEffect(() => {
        api.get(`Enum/Listar?nome=TipoNotaCorrelacaoCFOP`).then(res => {
            if (res.status === 200) {
                setListaTipo(res.data);
            }
        }).catch((erro) => console.error(erro));

    }, []);

    function retornaDescricaoTipoCorrelacao(crr_tiponota) {
        if (!!listaTipo) {
            let tipo = listaTipo.filter((pro) => (pro.key === crr_tiponota))[0];
            return !!tipo ? tipo.value : "";
        }
    };

    function excluirRegistro(dados) {
        api.delete(`CorrelacaoCfop/Excluir?id=${dados.crr_id}`).then(
            (res) => {
                notification.success({ message: `Sucesso!`, description: `Correlação excluida com sucesso!` });
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
            }).catch(
                (erro) => {
                    notification.error({ message: "erro", description: `${erro}` })
                }
            ).finally(
                () => {
                    dispatch({ type: listagemActions.CHANGE, data: { ordem: '-crr_id' } });
                });

    }


    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir a correlação do CFOP?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(dados);
            }
        });
    };

    function editandoConfig(dados) {
        setEditando(true);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    };

    function filtro(valor) {
        let sql = "";
        sql += form.getFieldsValue().crr_tiponota ? `&TipoCorrelacao=${form.getFieldsValue().crr_tiponota}` : "";
        sql += form.getFieldValue().cfop_saida ? `&cfop_saida=${form.getFieldValue().cfop_saida}` : "";
        sql += form.getFieldValue().cfop_entrada ? `&cfop_entrada=${form.getFieldValue().cfop_entrada}` : "";
        dispatch({ type: listagemActions.CHANGE, data: { filtro: `${!!valor ? valor : !!form.getFieldValue().Filtro ? form.getFieldValue().Filtro : ''}${sql}`, pagina: 1 } });
    };



    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <Form layout="vertical" name="Filtro" form={form} onFinish={filtro}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={12} lg={14} xl={14}>
                        <Form.Item label={"Pesquisar por:"} name="Filtro">
                            <Input.Search placeholder={"Pesquise por CFOPs ou descrição"} onSearch={valor => filtro(valor)}
                                allowClear />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={5} xl={5}>
                        <Form.Item label="Filtrar por Tipo de Nota:" name="crr_tiponota">
                            <Select showSearch options={listaTipo.map((item) => ({ label: item.value, value: item.key, key: item.key }))}
                                filterOption={(Input, option) => option?.label.toLowerCase().includes(Input.toLocaleLowerCase())}
                                placeholder={"Selecione Tipo de Nota"}
                                onChange={() => filtro()}
                                allowClear />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={5} xl={5}>
                        <Form.Item label="Ordenar por:" name="ordem">
                            <Select showSearch options={[
                                { label: "Descrição Saida Crescente", value: "+cfo_descricaosaida" },
                                { label: "Descrição Entrada Crescente", value: "+cfo_descricaoentrada" },
                                { label: "CFOP Saida Crescente", value: "+cfop_saida" },
                                { label: "CFOP Saida Decrescente", value: "-cfop_saida" },
                                { label: "CFOP Entrada Crescente", value: "+cfop_entrada" },
                                { label: "CFOP Entrada Decrescente", value: "-cfop_entrada" },
                            ]} placeholder={"Selecione a Ordenação"}  onChange={(val) => { console.log(val); dispatch({ type: listagemActions.CHANGE, data: { ordem: val } }) }} />
                        </Form.Item>
                    </Col>
                </Row>

            </Form>

            <div className="tabela">
                <TabelaDados url="CorrelacaoCfop/Listar"
                    colunas={[
                        {
                            title: 'Cfop Saida',
                            width: 200,
                            render: ({ cfop_saida, cfo_descricaosaida }) => (
                                <div>
                                    <b>{cfop_saida} - {cfo_descricaosaida}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Cfop Entrada',
                            width: 200,
                            render: ({ cfop_entrada, cfo_descricaoentrada }) => (
                                <div>
                                    <b>{cfop_entrada} - {cfo_descricaoentrada}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Tipo de Nota',
                            width: 50,
                            render: ({ crr_tiponota }) => {
                                return (
                                    <div>
                                        <b>
                                            {retornaDescricaoTipoCorrelacao(crr_tiponota)}
                                        </b>
                                    </div>
                                )
                            }
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            key: 'x',
                            align: 'center',
                            width: 35,
                            fixed: 'right',
                            render: (dados) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Editar
                                                onClick={() => {
                                                    editandoConfig(dados);
                                                }}

                                                disabled={dados.loc_id == null ? true : false}
                                                icon={<EditOutlined />}
                                            />
                                        </Col>
                                        <Col>
                                            <Excluir icon={<DeleteOutlined />} onClick={() => { modalExcluir(dados) }}
                                                disabled={dados.loc_id == null ? true : false} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]} />
            </div>
            <DrawerCorrelacaoCfop cadCfop={true} editando={{ editando, setEditando }} />
        </div>
    );
}