import React, { useEffect } from 'react';
import { Row, Col, Drawer, Button, Divider } from "antd";
import { CloseOutlined } from '@ant-design/icons';

import LinkNav from "../linkNav";
import { getNavegacao } from '../../services/auth';
import { limparTela } from '../../services/navigation';

export default function DrawerPaginasAbertas({ exibirPagina, paginas, fecharPagina }) {

    const listaPag = [
        { patch: "/", label: "Home" },

         /*** Pessoas ***/
        { patch: "/cliente", label: "Clientes" },
        { patch: "/fornecedor", label: "Fornecedores" },
        { patch: "/transportador", label: "Transportadores" },
        { patch: "/funcionario", label: "Funcionários" },
        { patch: "/vendedor", label: "Vendedores" },

         /*** Tributações ***/
        { patch: "/tributacaoIcms", label: "Tributação ICMS" },
        { patch: "/tributacaoPisCofins", label: "Tributação PIS/COFINS" },
        { patch: "/ncm", label: "NCM - Substituição Tributária" },
        { patch: "/aliquotaSimplesNacional", label: "Alíquota Simples Nacional" },

        /*** Produto ***/
        { patch: "/marca", label: "Marca" },
        { patch: "/grupoProduto", label: "Grupo de Produtos" },
        { patch: "/subGrupo", label: "Subgrupo de Produtos" },
        { patch: "/produto", label: "Cadastro de Produtos" },
        { patch: "/gradeProduto", label: "Modalidade Grade" },
        { patch: "/preco", label: "Preços" },
        { patch: "/etiqueta", label: "Gerar Etiquetas" },
        { patch: "/relacionamentoFornecedorProduto", label: "Relacionamento Produto x Fornecedor" },
        { patch: "/servico", label: "Cadastro de Serviço" },
        { patch: "/frete", label: "Cadastro de Frete" },

         /*** Cadastro Gerais ***/
        { patch: "/contaCorrente", label: "Conta Corrente" },
        { patch: "/contaGerencial", label: "Conta Gerencial" },
        { patch: "/operacaoFiscal", label: "Operações Fiscais" },
        { patch: "/operacaoDebitoCredito", label: "Operação Débito e Crédito" },
        { patch: "/formaPagamento", label: "Forma de Pagamento" },
        { patch: "/condicaoPagamento", label: "Condição de Pagamento" },
        { patch: "/serieFiscal", label: "Série Fiscal" },
        { patch: "/historico", label: "Históricos" },
        { patch: "/correlacaoCfop", label: "Correlação CFOP" },

         /*** Nota Fiscal ***/
        { patch: "/notaFiscal", label: "Emissão de Nota Fiscal" },
        { patch: "/monitoramentoNfe", label: "Monitoramento NF-e" },
        { patch: "/notaFiscalTransferencia", label: "Notas Fiscais de Transferência" },
        { patch: "/cartaCorrecaoNotaFiscal", label: "Carta de Correção" },
        { patch: "/inutilizacaoNfe", label: "Inutilizar Nº de NF-e/NFC-e" },
        { patch: "/ordemCompra", label: "Ordem de Compra" },

        /*** Orçamento ***/
        { patch: "/orcamento", label: "Orçamento" },

        /*** Pedidos Cliente ***/
        { patch: "/pedidos", label: "Pedidos" },
        { patch: "/statusPedido", label: "Status Pedido" },

        /*** Vendas ***/
        { patch: "/condicional", label: "Condicional" },
        { patch: "/valePresente", label: "Vale Presente" },
        { patch: "/metas", label: "Metas de Vendas" },
        { patch: "/comissao", label: "Comissão" },
        { patch: "/consultaComissao", label: "Consulta Comissão" },
        { patch: "/antecipacao", label: "Antecipação Cliente/Fornecedor" },

        /*** Financeiro ***/
        { patch: "/contaReceber", label: "Contas a Receber" },
        { patch: "/recebimentoParcela", label: "Recebimento de Parcelas" },
        { patch: "/contaPagar", label: "Contas a Pagar" },
        { patch: "/pagamentoParcela", label: "Pagamento de Parcelas" },
        { patch: "/lancamentoCaixa", label: "Lançamentos de Caixa/Bancos" },
        { patch: "/fluxoCaixa", label: "Fluxo de Caixa" },
        { patch: "/conciliacaoCartoes", label: "Conciliação de Cartões" },
        { patch: "/cheque", label: "Controle de Cheques" },

        /*** Boletos ***/
        { patch: "/boleto", label: "Boleto" },
        { patch: "/remessa", label: "Gerar Remessa Bancária" },
        { patch: "/retornoBancario", label: "Processar Retorno Bancário" },

        /*** Estoque ***/
        { patch: "/armazemEstoque", label: "Armazém de Estoque" },
        { patch: "/estoque", label: "Consulta Estoque" },
        { patch: "/estoqueManutencao", label: "Manutenção de Estoque" },
        { patch: "/inventario", label: "Inventário" },

        /*** Gerencial ***/
        { patch: "/matrizFilial", label: "Matriz Filial" },
        { patch: "/configuracaoDFe", label: "Configuração de DF-e" },
        { patch: "/cidade", label: "Configurações Nfse" },
        { patch: "/gerenciarPdv", label: "Gerenciar PDVs" },
        { patch: "/limiteCreditoCliente", label: "Definição de Limite de Crédito" },
        { patch: "/contador", label: "Dados Contador" },
        { patch: "/plano", label: "Plano Contratado" },
        { patch: "/bandeiraCartaoEmpresa", label: "Cartão Crédito/Débito" },
        { patch: "/centroDistribuicao", label: "Centro de Distribuição" },
        { patch: "/acaoUsuario", label: "Ações Usuário" },
       

        /*** Relatórios ***/
        { patch: "/relatorios", label: "Relatórios" },
        { patch: "/analiseInadimplencia", label: "Análise de Inadimplência" },

        /*** Personalização ***/
        { patch: "/personalizacao", label: "Formulário" },
        { patch: "/camposPersonalizados", label: "Campos Personalizados" },

        /*** Usuários e Grupos ***/
        { patch: "/usuario", label: "Usuários" },
        { patch: "/grupoUsuario", label: "Grupo Usuários" },

        /*** Arquivos Fiscais ***/
        { patch: "/efdIcmsIpi", label: "EFDICMS/IPI" },

        /*** Emissão de CT-e ***/
        { patch: "/componentesFrete", label: "Cadastro Componentes Frete" }
    ]

    const onClosePaginaAbertas = () => {
        fecharPagina()
    };

    useEffect(() => {
        let dados = JSON.parse(getNavegacao());
        let paginasAbertas = [];
        if (!!dados && !!dados.geral) {
            let dadosGeral = dados.geral;
            for (let key in dadosGeral) {
                paginasAbertas.push(key);
            }
            paginas.setPaginasAbertas(listaPag.filter(pag => paginasAbertas.includes(pag.patch)))
        }
    }, [getNavegacao()])

    function limparTelas() {
        limparTela('', true);
        paginas.setPaginasAbertas([]);
        onClosePaginaAbertas();
    };

    function excluirPagina(pagSelec) {
        let pagAbertas = [...paginas.paginasAbertas];
        pagAbertas = pagAbertas.filter((pag) => (pag.patch !== pagSelec.patch));
        paginas.setPaginasAbertas(pagAbertas);
    }

    function AtzBreadcrumb(item) {
        localStorage.setItem('@GestorWeb-Breadcrumb', '|' + listaPag.filter(pag => pag.patch === item.currentTarget.getAttribute('href').replace('#', ''))[0].label);
    };

    return (
        <Drawer title="Páginas Abertas"
            placement="right"
            onClose={onClosePaginaAbertas}
            visible={exibirPagina}
            className="drawer-pagina-aberta">
            <Row align="middle">
                {
                    paginas.paginasAbertas.map((pag) => (
                        <Col span={24} className="link-pag">
                            <Row align="middle" justify="space-between">
                                <Col>
                                    <LinkNav patch={pag.patch} label={pag.label} onClick={(item) => { AtzBreadcrumb(item); onClosePaginaAbertas(); }} />
                                </Col>
                                <Col>
                                    <Button type="link" size="size" icon={<CloseOutlined />} onClick={() => excluirPagina(pag)} />
                                </Col>
                                <Col span={24}>
                                    <Divider />
                                </Col>
                            </Row>
                        </Col>
                    ))
                }
                {paginas.paginasAbertas.length > 0 &&
                    <Col span={24} className="m-t-16">
                        <Button size="size" icon={<CloseOutlined />} onClick={() => { limparTelas() }} block>
                            Fechar Todas
                        </Button>
                    </Col>
                }
            </Row>
        </Drawer>

    )
};