import { useEffect, useState } from "react";
import { selectPaginadoActions } from "../../actions";
import api from "../../services/api";
import DrawerGrupoProduto from "../grupoProdutos/drawer";
import DrawerSubGrupo from "../subGrupo/drawer";
import { Checkbox, Col, Form, Input, notification, Row, Select, Tabs } from "antd";
import { Data, FormGW, InputPreco, SelectPaginacao } from "../../components";
import { useStateValue } from "../../state";
import TabFreteTributario from "./tabFretetributario";

export default function ManutencaoFrete({ formulario, salvandoDados, aoSalvar, editando }) {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [listaUnidadeMedida, setListaUnidadeMedida] = useState([]);
    const [filtroGrupo, setFiltroGrupo] = useState(null);
    const [grupoSelect, setGrupoSelect] = useState([]);
    const [openDrawerGrupo, setOpenDrawerGrupo] = useState(false);
    const [openDrawerSubGp, setOpenDrawerSubGp] = useState(false);
    const [foundGroup, setFoundGroup] = useState([]);
    const [elementosFormulario, setElementosFormulario] = useState([]);
    const [listaDados, setListaDados] = useState([]);

    const trocaAbas = (pag) => {
        setAbaSelecionada(pag);
    }
    useEffect(() => {
        if (!!manutencao.dados && !!editando.editando) {
            let lista = [];
            if (!!manutencao.dados?.icm_id) {
                lista.push({ name: "icm_id", campo: "Codigo", value: manutencao.dados.icm_id });
            }
            if (!!manutencao.dados?.pis_id) {
                lista.push({ name: "pis_id", campo: "Id", value: manutencao.dados.pis_id });
            }
            if (!!manutencao.dados?.gru_id) {
                lista.push({ name: "gru_id", campo: "CodigoGrupo", value: manutencao.dados.gru_id });
            }
            if (!!manutencao.dados?.sgp_id) {
                lista.push({ name: "sgp_id", campo: "Codigo", value: manutencao.dados.sgp_id });
            }
            if (lista.length > 0) {
                editando.setEditando(false);
                dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
            }
            formulario.setFieldsValue(manutencao.dados);
        }
    }, [manutencao.dados]);

    const listaValidacoes = [
        { nome: 'pro_descricao', obrigatorio: true, label: 'Descrição' },
    ];

    useEffect(() => {
        if (!!manutencao.dados && !!editando) {
            formulario.setFieldsValue(manutencao.dados);
        } else if (manutencao.dados !== null && !editando) {
            formulario.setFieldsValue(manutencao.dados);
        }
    }, [manutencao.dados])

   

    useEffect(() => {
        const found = grupoSelect.find(element => element.gru_id === formulario.getFieldValue(['gru_id']));
        if (!!found) {
            setFoundGroup(found);
            carregarSubGrupos(found.gru_id);
        }
    }, [formulario.getFieldValue(['gru_id'])]);


    function carregarSubGrupos(CodigoGrupo) {
        if (!!CodigoGrupo) {
            setFiltroGrupo(`&CodigoGrupo=${CodigoGrupo}`);
            let lista = [];
            if (!!formulario.getFieldValue().gru_id) {
                lista.push({ name: 'sgp_id', campo: 'CodigoGrupo', value: formulario.getFieldValue().gru_id });
            }
            dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
        }
    }

    function salvandoDadosGrupo(retorno) {
        let lista = [];
        if (retorno) {
            lista.push({ name: 'gru_id', campo: 'CodigoGrupo', value: retorno });
            dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
        }
    }

    function salvandoDadosSubGrupo(retorno) {
        let lista = [];
        if (retorno) {
            lista.push({ name: 'gru_id', campo: 'CodigoGrupo', value: retorno });
            dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
        }
    }

    async function salvarDados(values) {
        let dadosFrete = { ...values, ...formulario.getFieldValue() };

        if (dadosFrete.lpi_valorvenda === 0) {
            notification.warning({ message: 'Atenção!', description: 'Informe o valor do Frete' });
            return;
        }
        if (validarForm(dadosFrete, listaValidacoes)) {
            if (dadosFrete.pro_id) {
                editarManutencao(dadosFrete);
            } else {
                incluirDados(dadosFrete);
            }
        }
    }

    function validarForm(values, listaValidacoes) {
        let retorno = false;
        if (!!listaValidacoes) {
            let msgRetorno = [];
            listaValidacoes.forEach(
                (validacao) => {
                    if (validacao.obrigatorio && (!!values[validacao.nome] === false && values[validacao.nome] !== 0)) {
                        msgRetorno.push('O campo ' + validacao.label + ' é obrigatório!');
                    }
                }
            );
            if (msgRetorno.length > 0) {
                notification.warning({ message: 'Aviso', description: msgRetorno.join('\n') })
            } else {
                retorno = true;
            }

        }
        return retorno;
    }

    async function editarManutencao(dadosFrete) {
        api.put(`Produto/EditarFrete`, dadosFrete).then(
            res => {
                notification.success({ message: 'Operação concluída', description: "Os dados do Frete foram salvos com sucesso!" });
            }).catch(
                error => {
                    console.log(error);
                }
            ).finally(
                () => {
                    aoSalvar();
                    salvandoDados(false);
                }
            );
    }

    async function incluirDados(dadosFrete) {
        api.post(`Produto/IncluirFrete`, dadosFrete).then(
            res => {
                notification.success({ message: 'Operação concluída', description: 'Os dados do Frete foram salvos com sucesso!' });
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        ).finally(
            () => {
                aoSalvar();
                salvandoDados(false);
            }
        )
    }

    return (
        <div className="pages-col">
            <FormGW layout="vertical" form={formulario} name="manutencaoFrete" onFinish={(values) => salvarDados(values)}>
                <Row gutter={[8, 0]} align="bottom">
                    <Col xs={24} sm={24} md={13} lg={13} xl={13}>
                        <Form.Item hidden label="pro_id" name="pro_id">
                            <Input placeholder="pro_id" />
                        </Form.Item>
                        <Form.Item label="Descrição" name="pro_descricao" rules={[{ required: true, message: 'Informe a descrição do Frete' }]}>
                            <Input placeholder="Informe a Descrição do Frete" maxLength={120} />
                        </Form.Item>
                    </Col>
                    {!!manutencao.dados?.pro_id && <Col xs={24} sm={12} md={3} lg={3} xl={3}>
                        <Form.Item label="Código" name="pro_codigo">
                            <Input placeholder="Código Frete" maxLength={50} disabled />
                        </Form.Item>
                    </Col>}
                    <Col span={24} className="m-t-16">
                        <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => trocaAbas(tab)} >
                            <Tabs.TabPane tab="Dados Gerais" key="1">
                                <Row gutter={[8, 0]}>
                                    <Form.Item name="pro_frete" initialValue={true} hidden></Form.Item>
                                    <Col xs={24} sm={12} md={12} lg={6} xl={editando ? 8 : 12}>
                                        <Form.Item label="Grupo" name="gru_id">
                                            <SelectPaginacao url="grupoproduto/Listar?ApenasAtivos=true"
                                                selecionarRegUnico={"gru_id"}
                                                nameLabel="gru_nome"
                                                nameValue="gru_id"
                                                form={formulario}
                                                placeholder="Selecione o Grupo do Frete"
                                                allowClear={true}
                                                onChangeFunction={(IdGrupo) => carregarSubGrupos(IdGrupo)}
                                                onClearFunction={(IdGrupo) => carregarSubGrupos(IdGrupo)}
                                                setListaDados={setGrupoSelect}
                                                funcaoIncluir={() => setOpenDrawerGrupo(true)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={6} xl={editando ? 8 : 12}>
                                        <Form.Item label="Subgrupo" name="sgp_id">
                                            <SelectPaginacao url="subgrupo/Listar"
                                                placeholder="Selecione o Subgrupo do Frete"
                                                selecionarRegUnico="sgp_id"
                                                nameLabel="sgp_nome"
                                                nameValue="sgp_id"
                                                form={formulario}
                                                filtroExtra={filtroGrupo}
                                                funcaoIncluir={formulario.getFieldValue(['gru_id']) > 0 && (() => setOpenDrawerSubGp(true))}
                                                allowClear={true}
                                            />
                                        </Form.Item>
                                    </Col>
                                    {editando && <Col xs={24} sm={8} md={3} lg={3} xl={3} xxl={4}>
                                        <Data label="Data de Cadastro" name="pro_datainclusao" disabled />
                                    </Col>}
                                    {editando && <Col xs={24} sm={8} md={3} lg={3} xl={3} xxl={4}>
                                        <Data label="Data de Alteração" name="data_sincro" disabled />
                                    </Col>}
                                    <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                        <InputPreco label="Valor padrão de Frete (R$)" name="lpi_valorvenda" rules={[{ required: true, message: 'Informe o valor padrão do Frete' }]} />
                                    </Col>
                                </Row>
                                <Row align="middle" gutter={[8, 0]}>
                                    <Col>
                                        <Form.Item name="pro_ativo" valuePropName="checked" initialValue={true}>
                                            <Checkbox> Frete Ativo? </Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Dados Tributários" key="2">
                                <TabFreteTributario formulario={formulario} />
                            </Tabs.TabPane>
                        </Tabs>
                    </Col>
                </Row>
                <DrawerGrupoProduto openDrawerGrupo={openDrawerGrupo} setOpenDrawerGrupo={setOpenDrawerGrupo} cadGrupo={false} retornoSalvar={salvandoDadosGrupo} />
                <DrawerSubGrupo openDrawerSubGp={openDrawerSubGp} setOpenDrawerSubGp={setOpenDrawerSubGp} cadSubGp={false} gruId={foundGroup} retornoSalvar={salvandoDadosSubGrupo} />
            </FormGW>
        </div>

    )
}