import React, { useState } from 'react';
import { Row, Col, Button, Modal, Form, Drawer } from "antd";
import { CloseOutlined, SaveOutlined, ExclamationCircleOutlined, MinusOutlined } from "@ant-design/icons";

import ManutencaoCorrelacaoCfop from './manutencao';
import { useStateValue } from '../../state';
import { drawerActions, listagemActions, manutencaoActions } from '../../actions';
import { DrawerNav } from '../../components';



export default function DrawerCorrelacaoCfop({ editando }) {


    const [formCorrelacaoCfop] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);

    if (manutencao.dados !== null && editando.editando) {
        formCorrelacaoCfop.setFieldsValue(manutencao.dados);
    }

    function fecharDrawer() {
        formCorrelacaoCfop.resetFields();
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: '', filtro: '', ordem: '-crr_id' }})
        
        editando.setEditando(false);
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    return (

        <DrawerNav title="Correlação de CFOPs"
            width="70%"
            visible={ui.showDrawer}
            limparAoEditar={true}
            fecharDrawer={() => { fecharDrawer() }}
            closeIcon={<MinusOutlined />}
           
            footer={
                <div>
                    <Row align='middle' justify='end' gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size='large' htmlType="button">
                                Cancelar
                            </Button>
                        </Col>

                        <Col>
                            <Button onClick={() => formCorrelacaoCfop.submit()} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <ManutencaoCorrelacaoCfop formulario={formCorrelacaoCfop} carregando={setCarregando} aoSalvar={fecharDrawer} editando={editando}/>
        </DrawerNav>
    )
}