import React, { useEffect, useState } from "react";
import { Col, Descriptions, Divider, Row, Table } from "antd";

import { FormatFone, MaskFormat } from "../../ValueObjects";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export default function TabDetalheAutorCte({ autores, listaAutor }) {
    const [listaAutores, setListarAutores] = useState([]);

    useEffect(() => {
        if (autores) {
            let listaTemp = autores.map((item, index) => ({
                ...item,
                key: index
            }));

            setListarAutores(listaTemp);
        }
    }, [])

    function retornaDescricaoAutor(cta_tiporautor) {
        if (!!listaAutor) {
            let autor = listaAutor.filter((aut) => (aut.key === cta_tiporautor))[0];
            return !!autor ? autor.value : "";
        }
    }

    return (
        <div className="tabela">
            <Table dataSource={listaAutores} columns={
                [
                    {
                        title: 'Tipo Autor',
                        align: 'center',
                        render: ({ cta_tiporautor }) => (
                            <div>
                                <b>{retornaDescricaoAutor(cta_tiporautor)}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Razão Social',
                        render: ({ cta_razaosocial }) => (
                            <div>
                                <b>{cta_razaosocial}</b>
                            </div>
                        )
                    },
                    {
                        title: 'CPF/CNPJ',
                        render: ({ cta_cnpjcpf }) => (
                            <div>
                                <b>{MaskFormat(cta_cnpjcpf, 'Não Informado', true)}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Insc. Estadual',
                        align: 'center',
                        render: ({ cta_inscricaoestadual }) => (
                            <div>
                                <b>{cta_inscricaoestadual || 'Não Informado'}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Telefone',
                        align: 'center',
                        render: ({ cta_telefone }) => (
                            <div>
                                <b>{FormatFone(cta_telefone) || 'Não Informado'}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Email',
                        align: 'center',
                        render: ({ cta_email }) => (
                            <div>
                                <b>{cta_email || 'Não Informado'}</b>
                            </div>
                        )
                    },
                ]
            }
                expandable={{
                    expandedRowRender: (record) => (
                        <div>
                            <Row align="middle" gutter={[8, 0]}>
                                <Col>
                                    <b> Logradouro: </b> {record.cta_Logradouro || 'Não Informado'} ,
                                </Col>
                                <Col>
                                    <b> Número: </b> {record.cta_numero || 'Não Informado'} ,
                                </Col>
                                <Col>
                                    <b> Bairro: </b> {record.cta_bairro || 'Não Informado'} ,
                                </Col>
                                <Col>
                                    <b> Complemento: </b> {record.cta_complemento || 'Não Informado'}
                                </Col>
                            </Row>
                        </div>
                    ),
                }}
                pagination={false}
                locale={{
                    emptyText: (
                        <Row>
                            <Col span={24}>
                                <Divider orientation="center">
                                    <ExclamationCircleOutlined /> Não há Dados
                                </Divider>
                            </Col>
                        </Row>
                    )
                }}
            />
        </div >
    )
}