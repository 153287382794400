import { Col, Form, Modal, notification, Popover, Radio, Row, Tag } from "antd";
import { useStateValue } from "../../state";
import { useEffect, useState } from "react";
import api from "../../services/api";
import { BotaoIncluirDados, BreadcrumbPage, Editar, Excluir, PesquisaOrdenacao, TabelaDados } from "../../components";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import moment from "moment";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import DrawerFrete from "./drawer";

export default function Frete() {

    const [{ manutencao, listagem }, dispatch] = useStateValue();
    const [formFiltros] = Form.useForm();
    const [editando, setEditando] = useState(false);


    useEffect(() => {
        let _ativo = !!!formFiltros.getFieldsValue().produtoAtivo ? 1 : formFiltros.getFieldsValue().produtoAtivo;
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&Ativo=${_ativo}&ProdutoFrete=true`, filtro: '', ordem: '+pro_descricao' } });
        formFiltros.setFieldsValue({ produtoAtivo: 1 });
    }, [])

    function showDrawer(frete) {
        let dados = {};
        if (!!frete.pro_id) {
            api.get(`produto/RetornaDadosProduto?ProdutoId=${frete.pro_id}`).then(res => {
                if (res.status === 200) {
                    dados = res.data;
                    dados.pro_datainclusao = !!dados.pro_datainclusao ? moment(dados.pro_datainclusao) : null;
                    dados.data_sincro = !!dados.data_sincro ? moment(dados.data_sincro) : null;
                    dados.orm_id = parseInt(dados.orm_id);
                    api.get(`tributacaoIcms/BuscarTributacaoPorCodigoProduto?IdProduto=${frete.pro_id}`).then(
                          res => {
                              if (res.status === 200) {
                                    dados.icm_id = res.data.icm_id;
                                }
                            }
                       ).catch(
                         (erro) => {
                              if (!!erro.response && !!erro.response.data && erro.response.data.Message) {
                                 notification.error({ description: erro.response.data.Message, message: 'Aviso' });
                                } else {
                                  console.error(erro);
                              }
                          }
                     ).finally(
                         () => {
                              dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });
                             dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                           }
                        );
                }
            }).catch((erro) => console.error(erro))
                .finally(
                    () => {
                        dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });
                        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                    }
                );
        }
    };

    const modalExcluir = (frete) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir o frete ${frete.pro_codigo}-${frete.pro_descricao} ?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(frete);
            }
        });
    };

    function excluirRegistro(frete) {
        api.delete(`produto/excluir/${frete.pro_id}`).then((res) => {
            if (res.data == true) {
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
            } else {
                modalInativar(frete);
            }
        }, (erro) => {
            console.error(erro);
            modalExcluir(frete);
        })
    };

    const modalInativar = (frete) => {
        Modal.confirm({
            title: 'Não foi possível excluir o frete!',
            icon: <ExclamationCircleOutlined />,
            content: `Deve conter referências com outros registros,
            Deseja inativar o frete ${frete.pro_descricao} ?`,
            okText: 'Inativar',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
                inativarFrete(frete);
            }
        });
    };

    function inativarFrete(frete) {
        api.put(`produto/AtivarInativar?IdProduto=${frete.pro_id}&Ativo=false`).then((res) => {
            if (res.status === 200)
                dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: '&ProdutoFrete=true', filtro: '', ordem: '+pro_descricao' } });
        })
    };

    function onClickEditar(frete) {
        setEditando(true);
        showDrawer(frete);
    };


    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" className="col-pagina-breadcrumb">
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <Row align="middle" justify="end">
                        <Col>
                            <BotaoIncluirDados />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Form layout="vertical" form={formFiltros}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={16}>
                        <PesquisaOrdenacao opcoesOrdenacao={[
                            { label: "Nome A - Z", value: "+pro_descricao" },
                            { label: "Nome Z - A", value: "-pro_descricao" },
                            { label: "Código Crescente", value: "+pro_codigo" },
                            { label: "Código Decrescente", value: "-pro_codigo" }
                        ]} />
                    </Col>
                    <Col xs={24} sm={24} md={9} lg={9} xl={6}>
                        <Form.Item label="Selecione Status do Frete" name="produtoAtivo">
                            <Radio.Group >
                                <Radio value={2}>Todos</Radio>
                                <Radio value={1}>Ativo</Radio>
                                <Radio value={0}>Inativo</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <div className="tabela">
                <TabelaDados url="produto/ListarTodas" colunas={[
                    {
                        title: 'Código',
                        width: 75,
                        align: 'center',
                        render: ({ pro_codigo, pro_ativo }) => (
                            <Popover content={pro_ativo ? 'Ativo' : 'Inativo'} placement="right">
                                <Tag color={pro_ativo ? 'processing' : 'default'} className="w-100">
                                    <b>{pro_codigo}</b>
                                </Tag>
                            </Popover>
                        ),
                    },
                    {
                        title: 'Descrição do Frete',
                        render: ({ pro_descricao }) => (
                            <div>
                                <b>{pro_descricao}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        width: 95,
                        fixed: 'right',
                        render: (frete) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Editar onClick={() => onClickEditar(frete)} icon={<EditOutlined />} />
                                    </Col>
                                    <Col>
                                        <Excluir icon={<DeleteOutlined />} onClick={() => excluirRegistro(frete)} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} />
            </div>
            <DrawerFrete editando={{ editando, setEditando }} />
        </div>
    )

}

