import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Form, Modal, notification, Row, Select, Steps, Table, Tag, Typography, Upload } from "antd";

import { useStateValue } from "../../state";
import { Data, FormGW, SelectPaginado } from "../../components";
import { getBase64, validaForm } from "../../services/funcoes";
import { DeleteOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { TabRemetenteCTe, TabDadosGerais, TabFaturamentoCTe } from "./tabPage";
import ModalDocsFiscaisCTe from "../../components/modals/modalDocsFiscaisCTe";
import moment from "moment";
import api from "../../services/api";
import formatNumber from "../../ValueObjects/formatNumber";
import FormaDePagamento from "../../components/enuns/formaDePagamento";

export default function ManutencaoConhecimentoTransporte({ formConhecimento, carregando, aoSalvar, pages, proximaPag, listaDocs, setListaDocs }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [form] = Form.useForm();
    const [newForm] = Form.useForm();
    const [arquivoBase64, setArquivoBase64] = useState("");
    const [arquivo, setArquivo] = useState(null);
    const [idTomador, setIdTomador] = useState(null);
    const [permiteValidar, setPermiteValidar] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [serie, setSerie] = useState([]);
    const [tomador, setTomador] = useState([]);
    const [tipoCTe, setTipoCTe] = useState([]);
    const [modalCTe, setModalCTe] = useState([]);
    const [tipoServico, setTipoServico] = useState([]);
    const [unidadeCTe, setUnidadeCTe] = useState([]);
    const [dadosProdutos, setDadosProdutos] = useState([]);
    const [componentesFrete, setComponentesFrete] = useState([]);
    const [listaFormaPagamento, setListaFormaPagamento] = useState([]);
    const [dadosTomador, setDadosTomador] = useState({});
    const [dadosContribuinte, setDadosContribuinte] = useState({});
    const [operacaoFiscal, setOperacaoFiscal] = useState({});
    const listaValidacoesAutores = [
        { nome: 'cte_tomador', obrigatorio: true, label: 'Tomador' },
        { nome: 'cid_id_munenvio', obrigatorio: true, label: 'Município de Envio' },
        { nome: 'cid_id_muninicial', obrigatorio: true, label: 'Município Inicial' },
        { nome: 'cid_id_munfinal', obrigatorio: true, label: 'Município Final' },
        { nome: 'pes_remetente', obrigatorio: true, label: 'Remetente' },
        { nome: 'pes_destinatario', obrigatorio: true, label: 'Destinatário' },
        { nome: 'endereco_remetente', obrigatorio: true, label: 'Endereço Remetente' },
        { nome: 'endereco_destinatario', obrigatorio: true, label: 'Endereço Destinatário' }

    ]
    const listaValidacosDadosGerais = [
        { nome: 'cte_tipocte', obrigatorio: true, label: 'Tipo do CTe' },
        { nome: 'cte_modal', obrigatorio: true, label: 'Modal' },
        { nome: 'cte_tiposervico', obrigatorio: true, label: 'Tipo de Serviço' },
        { nome: 'cte_prodpredominante', obrigatorio: true, label: 'Produto Predominante' },
        { nome: 'cic_unit', obrigatorio: true, label: 'Unidade de Carga' },
        { nome: 'cic_tipomedida', obrigatorio: true, label: 'Tipo de Medida' },
    ]
    const props = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        maxCount: 1,
        disabled: !!arquivo,
        beforeUpload(file) {
            getBase64(file).then(base64 => {
                setArquivoBase64(base64.split('base64,')[1]);
                setArquivo(file);
            });
        }
    }

    const modalExcluir = (record) => {
        Modal.confirm({
            title: 'Excluir',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja excluir o Documento Fiscal?',
            okText: 'Excluir',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
                removerDoc(record);
            }
        })
    }

    const removerDoc = (record) => {
        let dadosTemp = listaDocs.filter((doc) => doc.cid_numero !== record.cid_numero);
        setListaDocs(dadosTemp);
    }

    useEffect(() => {
        if (ui.showDrawer) {
            carregarDados();
            popularDadosContribuinte();
            if (!formConhecimento.getFieldValue().ntf_dataemissao) {
                formConhecimento.setFieldsValue({ ntf_dataemissao: moment(new Date()) });
            }
        }
    }, [ui.showDrawer])

    useEffect(() => {
        if ((!!proximaPag.proximaPag || proximaPag.proximaPag === 0) && permiteValidar) {
            validarTrocaPg(proximaPag.proximaPag);
        } else {
            setPermiteValidar(true);
        }
    }, [proximaPag.proximaPag])

    async function carregarDados() {
        let listaSerie = await api.get('SerieFiscal/Listar?EmitenteDocumento=0&CodigoModeloDocFiscal=57&SomenteAtivos=true');
        let serie = [];
        if (listaSerie.status === 200) {
            serie = listaSerie.data.items;
            setSerie(serie);
            if (serie.length > 0) {
                formConhecimento.setFieldsValue({ ser_id: serie[0].ser_id });
            }
        }

        api.get(`Enum/Listar?nome=toma`).then(res => {
            if (res.status === 200) {
                setTomador(res.data);
            }
        }).catch((erro) => console.error(erro));

        api.get(`Enum/Listar?nome=tpCTe`).then(res => {
            if (res.status === 200) {
                setTipoCTe(res.data);
            }
        }).catch((erro) => console.error(erro));

        api.get(`Enum/Listar?nome=modal`).then(res => {
            if (res.status === 200) {
                setModalCTe(res.data);
            }
        }).catch((erro) => console.error(erro));

        api.get(`Enum/Listar?nome=tpServ`).then(res => {
            if (res.status === 200) {
                setTipoServico(res.data);
            }
        }).catch((erro) => console.error(erro));

        api.get(`Enum/Listar?nome=cUnid`).then(res => {
            if (res.status === 200) {
                setUnidadeCTe(res.data);
            }
        })
    }

    async function popularDadosContribuinte(callback) {
        await api.get(`dadosContribuinte/Buscar`).then(
            (res) => {
                if (res.status === 200) {
                    setDadosContribuinte(res.data);
                    if (callback) {
                        callback();
                    }
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )
    }

    async function popularOperacaoFiscal(id) {
        if (!!id) {
            api.get(`OperacaoFiscal/Buscar/${id}`).then(
                res => {
                    if (res.status === 200) {
                        setOperacaoFiscal(res.data);
                    }
                }
            ).catch(
                error => { console.log(error) }
            )
        }
    }

    async function validarOperacao() {
        let dados = newForm.getFieldValue();
        let autores = preencherDadosAutores(dados);
        let tomador = autores.find(aut => aut.cta_tiporautor === dados.cte_tomador);
        let destinoOperacao = 2;
        setDadosTomador(tomador);

        if (tomador.cta_siglauf === dadosContribuinte.est_sigla) {
            destinoOperacao = 1;
        }
        if (tomador.cta_siglauf === "EX") {
            destinoOperacao = 3;
        }
        newForm.setFieldsValue({ destinoOperacao: destinoOperacao });
    }

    async function validarTrocaPg(pagina) {
        let paginaAtual = pages.tagPages;
        let dados = newForm.getFieldValue();
        let listaValidacoes = [];
        switch (paginaAtual) {
            case 0:
                if (paginaAtual < pagina) {
                    if (!formConhecimento.getFieldValue().ope_id) {
                        notification.warning({ message: 'Aviso', description: 'O campo Operação é obrigatório!' })
                        setPermiteValidar(false);
                        proximaPag.setProximaPag(paginaAtual);
                        return false;
                    }
                    if (listaDocs.length === 0) {
                        notification.warning({ message: 'Atenção!', description: 'Para prosseguir, deve ter pelo menos um Documento Fiscal.' })
                        setPermiteValidar(false);
                        proximaPag.setProximaPag(paginaAtual);
                        return false;
                    }
                }
                break;
            case 1:
                if (paginaAtual < pagina) {
                    listaValidacoes = listaValidacoesAutores;
                    if (dados.cte_tomador === 1 && (dados.pes_expedidor === undefined || dados.endereco_expedidor === undefined)) {
                        notification.warning({ message: 'Aviso', description: 'O campo Expedidor é obrigatório ao ser definido como Tomador' });
                        setPermiteValidar(false);
                        proximaPag.setProximaPag(paginaAtual);
                        return false;
                    }
                    if (dados.cte_tomador === 2 && (dados.pes_recebedor === undefined || dados.endereco_recebedor === undefined)) {
                        notification.warning({ message: 'Aviso', description: 'O campo Recebedor é obrigatório ao ser definido como Tomador' });
                        setPermiteValidar(false);
                        proximaPag.setProximaPag(paginaAtual);
                        return false;
                    }
                    if (dados.cte_tomador === 4 && (dados.pes_outros === undefined || dados.endereco_outros === undefined)) {
                        notification.warning({ message: 'Aviso', description: 'O campo Outros é obrigatório ao ser definido como Tomador' });
                        setPermiteValidar(false);
                        proximaPag.setProximaPag(paginaAtual);
                        return false;
                    }
                    validarOperacao();
                }
                break;
            case 2:
                if (paginaAtual < pagina) {
                    listaValidacoes = listaValidacosDadosGerais;
                    if (dadosProdutos.length === 0) {
                        notification.warning({ message: 'Aviso', description: 'Adicione os dados do Produto' });
                        setPermiteValidar(false);
                        proximaPag.setProximaPag(paginaAtual);
                        return false;
                    }
                    if (dados.cic_qntcarga <= 0) {
                        notification.warning({ message: 'Aviso', description: 'O campo Quantidade de Carga é obrigatório' });
                        setPermiteValidar(false);
                        proximaPag.setProximaPag(paginaAtual);
                        return false;
                    }
                }
                break;
        }
        if (validaForm(newForm, listaValidacoes)) {
            pages.setTagPages(pagina);
        } else {
            setPermiteValidar(false);
            proximaPag.setProximaPag(paginaAtual);
        }
    }

    async function emitirCTe(dados) {
        dados = await popularDados();
        dados.ntf_dhsaient = moment(new Date());

        incluirNota(dados);
    }

    async function popularDados() {
        let valores = formConhecimento.getFieldsValue();
        let dados = newForm.getFieldValue();

        valores.ntf_docfiscal = !!valores.ser_id;
        valores.cte_tipocte = dados.cte_tipocte;
        valores.cte_modal = dados.cte_modal;
        valores.cte_tiposervico = dados.cte_tiposervico;
        valores.cte_indglobalizado = dados.cte_indglobalizado;
        valores.cid_id_munenvio = dados.cid_id_munenvio;
        valores.cid_id_munfinal = dados.cid_id_munfinal;
        valores.cid_id_muninicial = dados.cid_id_muninicial;
        valores.cte_tomador = dados.cte_tomador;
        valores.cte_prodpredominante = dados.cte_prodpredominante;
        valores.cte_observacao = dados.cte_observacao;
        // valores.cte_chctesubcontratacao

        valores.ctenotafiscalitem = popularDadosItens();
        valores.notaFiscalFaturamento = preencherDadosFaturamento();
        valores.cteautores = preencherDadosAutores(dados);
        valores.cteinfodocumentos = preencherDadosDocumentos();
        valores.ctecomponentesfrete = preencherComponentesFrete();
        valores.cteinfocarga = [{
            cic_cunit: dados.cic_unit,
            cic_tipomedida: dados.cic_tipomedida,
            cic_qntcarga: parseFloat(dados.cic_qntcarga)
        }];

        return valores;
    }

    function popularDadosItens() {
        let item = dadosProdutos.find(() => true);
        if (!item) return null;

        return {
            pro_id: item.pro_id,
            icm_id: item.icm_id,
            nfi_cfop: item.nfi_cfop,
            nfi_unidademedida: item.nfi_unidademedida,
            nfi_qtde: item.nfi_qtde,
            nfi_valorunitario: item.nfi_valorunitario
        };
    }

    function preencherDadosFaturamento() {
        let dadosForma = [];
        let recebimentoCartao = !!manutencao.dados.recebimentoCartao ? manutencao.dados.recebimentoCartao[0] : null;
        listaFormaPagamento.forEach(forma => {
            let aux = { ...forma };
            aux.fpg_id = forma.fpg_id;
            aux.cpg_id = forma.cpg_id;
            if (forma.fpg_tipopagamento === FormaDePagamento.fpCheque) {
                aux.ctc_id = manutencao.dados.dadosModalCheque.contaCorrente;
                aux.cheques = manutencao.dados.dadosModalCheque.cheques;
            }
            aux.nfp_valor = parseFloat(forma.pfp_valor);
            aux.bce_id = !!recebimentoCartao ? recebimentoCartao.bce_id : null;
            aux.rcc_nsu = !!recebimentoCartao ? recebimentoCartao.rcc_nsu : null;
            if (forma.fpg_tipopagamento === FormaDePagamento.fpValePresente) {
                aux.antecipacoes = forma.formaPagamento.antecipacos;
            } else {
                aux.antecipacoes = [];
            }
            dadosForma.push(aux);
        });
        return dadosForma;
    }

    function preencherDadosAutores(dados) {
        let tiposAutores = [
            { tipo: 0, prefixo: 'remetente', condicao: dados.pes_remetente },
            { tipo: 3, prefixo: 'destinatario', condicao: dados.pes_destinatario },
            { tipo: 1, prefixo: 'expedidor', condicao: dados.pes_expedidor },
            { tipo: 2, prefixo: 'recebedor', condicao: dados.pes_recebedor },
            { tipo: 4, prefixo: 'outros', condicao: dados.pes_outros }
        ];

        let dadosAutores = tiposAutores
            .filter(autor => autor.condicao != null)
            .map(autor => {
                let prefixo = autor.prefixo;
                return {
                    pes_id: dados[`pes_${prefixo}`],
                    cta_tiporautor: autor.tipo,
                    cta_cnpjcpf: dados[`cnpj_${prefixo}`] || dados[`cpf_${prefixo}`],
                    cta_inscricaoestadual: dados[`ie_${prefixo}`],
                    cta_razaosocial: dados[`razao_${prefixo}`],
                    cta_nomefantasia: dados[`fantasia_${prefixo}`],
                    cta_telefone: dados[`telefone_${prefixo}`],
                    cta_Logradouro: dados[`logradouro_${prefixo}`],
                    cta_numero: dados[`num_${prefixo}`],
                    cta_complemento: dados[`complemento_${prefixo}`],
                    cta_bairro: dados[`bai_${prefixo}`],
                    cep_cep: dados[`cep_${prefixo}`],
                    cta_siglauf: dados[`est_${prefixo}`],
                    cta_email: dados[`email_${prefixo}`]
                };
            });

        return dadosAutores;
    }

    function preencherDadosDocumentos() {
        return listaDocs.map((item) => ({
            cid_modelo: parseFloat(item.cid_modelo),
            cid_serie: item.cid_serie.toString(),
            cid_dataemissao: item.cid_dataemissao,
            cid_numero: item.cid_numero.toString(),
            cid_chave: item.cid_chave,
            cid_valorbcicms: item.cid_valorbcicms,
            cid_valoricms: item.cid_valoricms,
            cid_valorbcicmsst: item.cid_valorbcicmsst,
            cid_valoricmsst: item.cid_valoricmsst,
            cid_valorprodutos: item.cid_valorprodutos,
            cid_valornf: item.cid_valornf,
            cid_cfoppred: item.cid_cfoppred,
            cid_peso: item.cid_peso,
            cid_pinsuframa: item.cid_pinsuframa,
            cid_datapreventrega: item.cid_datapreventrega
        }));
    }

    function preencherComponentesFrete() {
        return componentesFrete.map((item) => ({
            cmf_id: item.cmf_id,
            ccf_valor: item.ccf_valor
        }));
    }

    function incluirNota(dados) {
        carregando(true);
        api.post("ConhecimentoTransporte/Incluir", dados).then(
            res => {
                notification.success({ message: 'Sucesso' });
            }
        ).catch(
            erro => {
                if (!!erro.response && !!erro.response.data) {
                    Modal.warning({
                        content: erro.response.data,
                        title: 'Aviso',
                        onOk: () => {
                            aoSalvar();
                        }
                    })
                }
                console.log(erro);
            }
        ).finally(
            () => {
                carregando(false);
                aoSalvar();
            }
        )
    }

    return (
        <div className="pages-col">
            <FormGW layout="vertical" form={formConhecimento} name="formConhecimento" onFinish={emitirCTe}>
                <Row gutter={[8, 0]} hidden={pages.tagPages === 1 || pages.tagPages === 2}>
                    <Col xs={24} sm={3} md={3} lg={3} xl={3}>
                        <Form.Item label="Série" name="ser_id">
                            <Select allowClear placeholder="Selecione uma Série">
                                {serie.map((ser) => (
                                    <Select.Option key={ser.ser_id} value={ser.ser_id}>{ser.ser_serie}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={5} md={4} lg={4} xl={4}>
                        <Data label="Data de Emissão" name="ntf_dataemissao" />
                    </Col>
                    <Col xs={24} sm={16} md={11} lg={8} xl={8}>
                        <Form.Item label="Operação" name="ope_id" rules={[{ required: true, message: 'Selecione a Operação Fiscal' }]}>
                            <SelectPaginado url="OperacaoFiscal/ListarOperacaoFiscal?SomenteAtivo=true"
                                placeholder="Selecione a Operação Fiscal"
                                form={formConhecimento}
                                name="ope_id"
                                onChangeFunction={(idOperacao) => popularOperacaoFiscal(idOperacao)}
                                conteudo={
                                    op => (<Select.Option value={op.ope_id} key={op.key}>{op.ope_descricao}</Select.Option>)
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[0, 24]}>
                    <Col span={24}>
                        <Steps size="small" type="navigation" onChange={(e) => {
                            proximaPag.setProximaPag(e);
                        }} current={pages.tagPages}>
                            <Steps.Step title="Documentos Fiscais" />
                            <Steps.Step title="Remetente/Destinatário" />
                            <Steps.Step title="Dados Gerais" />
                            <Steps.Step title="Faturamento" />
                        </Steps>
                    </Col>
                </Row>
                <Row gutter={[8, 24]}>
                    <Col span={24}>
                        {pages.tagPages === 0 &&
                            <div className="m-t-16">
                                <Row align="middle">
                                    <Col span={24}>
                                        <Typography.Title level={3}>
                                            <img src={require("../../assets/i-info.png").default} alt="Adicione os Documentos Fiscais do CT-e" />
                                            Adicione os Documentos Fiscais do CT-e
                                        </Typography.Title>
                                        <Divider orientation="left" plain>Adicione os Documentos Fiscais do CT-e</Divider>
                                    </Col>
                                </Row>
                                <Form layout="vertical" form={form}>
                                    <Row align="middle" gutter={[8, 0]}>
                                        <Col style={{ marginTop: '14px' }}>
                                            <Upload {...props}>
                                                <Button
                                                    type="primary"
                                                    size="small"
                                                    icon={<PlusOutlined />}
                                                    onClick={() => { form.resetFields(); setOpenModal(true); }}
                                                    style={{ marginBottom: '-3px' }}
                                                >
                                                    ADICIONAR DOCUMENTO
                                                </Button>
                                            </Upload>
                                        </Col>
                                    </Row>
                                </Form>
                                <div className="tabela mt-dif">
                                    <Form form={form} component={false}>
                                        <Table dataSource={listaDocs} columns={[
                                            {
                                                title: 'Número',
                                                width: 50,
                                                align: 'center',
                                                render: ({ cid_numero }) => (
                                                    <Tag color="processing" className="w-100">
                                                        <b>{cid_numero}</b>
                                                    </Tag>
                                                )
                                            },
                                            {
                                                title: 'CHAVE',
                                                width: 80,
                                                align: 'center',
                                                render: ({ cid_chave }) => (
                                                    <b>{cid_chave}</b>
                                                )
                                            },
                                            {
                                                title: 'Data Emissão',
                                                width: 40,
                                                align: 'center',
                                                render: ({ cid_dataemissao }) => (
                                                    <b>{moment(cid_dataemissao).format('DD/MM/YYYY')}</b>
                                                )
                                            },
                                            {
                                                title: 'Modelo',
                                                width: 50,
                                                align: 'center',
                                                render: ({ cid_modelo }) => (
                                                    <b>{cid_modelo}</b>
                                                )
                                            },
                                            {
                                                title: 'Peso Bruto',
                                                width: 70,
                                                align: 'right',
                                                render: ({ cid_peso }) => (
                                                    <b>{cid_peso}</b>
                                                )
                                            },
                                            {
                                                title: 'Valor Total',
                                                width: 70,
                                                align: 'right',
                                                render: ({ cid_valornf }) => (
                                                    <b>R$ {formatNumber(cid_valornf, true)}</b>
                                                )
                                            },
                                            {
                                                title: 'Ações',
                                                dataIndex: '',
                                                align: 'center',
                                                key: 'x',
                                                width: 45,
                                                fixed: 'right',
                                                render: (record) => {
                                                    return (
                                                        <div>
                                                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                                                <Col>
                                                                    <Button onClick={() => modalExcluir(record)} icon={<DeleteOutlined />} />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    );
                                                },
                                            },
                                        ]} />
                                    </Form>
                                </div>
                                <ModalDocsFiscaisCTe form={form} exibirModal={openModal} fecharModal={() => setOpenModal(false)} listaDocs={listaDocs} setListaDocs={setListaDocs}
                                    arquivoBase64={arquivoBase64} setArquivoBase64={setArquivoBase64} arquivo={arquivo} setArquivo={setArquivo} />
                            </div>
                        }
                        {pages.tagPages === 1 &&
                            <TabRemetenteCTe formAutores={newForm} tomador={tomador} listaDocs={listaDocs} setIdTomador={setIdTomador} />
                        }
                        {pages.tagPages === 2 &&
                            <TabDadosGerais formConhecimento={newForm} tipoCTe={tipoCTe} modalCTe={modalCTe} tipoServico={tipoServico} dadosOperacao={operacaoFiscal} listaDocs={listaDocs}
                                dadosProdutos={{ dadosProdutos, setDadosProdutos }} unidadeCTe={unidadeCTe} componentesFrete={{ componentesFrete, setComponentesFrete }} dadosContribuinte={dadosContribuinte}
                                dadosTomador={dadosTomador} />
                        }
                        {pages.tagPages === 3 &&
                            <TabFaturamentoCTe formConhecimento={newForm} dadosOperacao={operacaoFiscal} listaFormaPagamento={listaFormaPagamento} idTomador={idTomador}
                                setListaFormaPagamento={setListaFormaPagamento} nota={listaDocs[0]} />
                        }
                    </Col>
                </Row>
            </FormGW>
        </div>
    )
}