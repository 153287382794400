import React from "react";
import { Modal } from "antd";

import DetalheCte from "../../detalheCte";

export default function ModalDetalhesCte({ exibirModal, fecharModal, dadosModal, listaTipoCte, listaTomador, listaModal }) {

    return (
        <div>
            <Modal centered
                title="Detalhes CT-e"
                visible={exibirModal}
                onCancel={fecharModal}
                width="70%"
                footer={null}
                maskClosable={false}
            >
                <DetalheCte dadosModal={dadosModal} listaTipoCte={listaTipoCte} listaTomador={listaTomador} listaModal={listaModal} />
            </Modal>
        </div>
    )
}