import React, { useEffect, useState } from "react";
import { Button, Col, Collapse, Dropdown, Form, Input, Menu, Row, Select, Tag } from "antd";

import { BreadcrumbPage, ComboData, Data, EmitirConhecimento, TabelaDados } from "../../components";
import { ArrowDownOutlined, ArrowUpOutlined, CloseOutlined, EyeOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useStateValue } from "../../state";
import { drawerActions, listagemActions } from "../../actions";
import moment from "moment";
import DrawerConhecimentoTransporte from "./drawer";
import { FormatNumber } from "../../ValueObjects";
import api from "../../services/api";
import ModalDetalhesCte from "../../components/modals/modalDetalheCte";

export default function ConhecimentoDeTransporte({ }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [form] = Form.useForm();
    const [formParam] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [openDetalhesCte, setOpenDetalhesCte] = useState(false);
    const [listaStatusNota, setListaStatusNota] = useState([]);
    const [listaTipoCte, setListaTipoCte] = useState([]);
    const [listaTomador, setListaTomador] = useState([]);
    const [listaModal, setListaModal] = useState([]);
    const [dadosDetalhesCte, setDadosDetalhesCte] = useState({});

    const togglePesquisa = () => {
        setOpen(!open);
    }

    useEffect(() => {
        filtrosAvancados();
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '-ntf_dataemissao' } });
        carregarDados();
    }, [])

    function filtrosAvancados(value) {
        let sql = "";
        sql += !!formParam.getFieldValue().status ? `&StatusNFe=${formParam.getFieldValue().status}` : "";
        sql += !!form.getFieldValue().dataInicial ? `&DataInicial=${moment(form.getFieldValue().dataInicial).format('YYYY-MM-DD')}` : `&dataInicial=${moment(new Date()).format('YYYY-MM-DD')}`;
        sql += !!form.getFieldValue().dataFinal ? `&DataFinal=${moment(form.getFieldValue().dataFinal).format('YYYY-MM-DD')}` : `&dataFinal=${moment(new Date).format('YYYY-MM-DD')}`;
        dispatch({ type: listagemActions.CHANGE, data: { filtro: `${!!value ? value : !!formParam.getFieldValue().filtroPor ? formParam.getFieldValue().filtroPor : ''}${sql}`, pagina: 1 } });
    };

    function carregarDados() {
        retornaStatusNF();
        retornaTipoCte();
        retornaTomador();
        retornaModal();
    }

    function retornaStatusNF() {
        api.get('Enum/Listar?nome=StatusNFe').then(
            (res) => {
                if (res.status === 200) {
                    setListaStatusNota(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )
    };

    function retornaTipoCte() {
        api.get('Enum/Listar?nome=tpCTe').then(
            (res) => {
                if (res.status === 200) {
                    setListaTipoCte(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )
    }

    function retornaTomador() {
        api.get('Enum/Listar?nome=toma').then(
            (res) => {
                if (res.status === 200) {
                    setListaTomador(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )
    }

    function retornaModal() {
        api.get('Enum/Listar?nome=modal').then(
            (res) => {
                if (res.status === 200) {
                    setListaModal(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )
    }

    function retornaDescricaoStatus(ntf_status) {
        if (!!listaStatusNota) {
            let status = listaStatusNota.filter((statusNota) => (statusNota.key === ntf_status))[0];
            return !!status ? status.value : "";
        }
    };

    function retornaDescricaoTipoCte(cte_tipocte) {
        if (!!listaTipoCte) {
            let tipo = listaTipoCte.filter((tipo) => (tipo.key === cte_tipocte))[0];
            return !!tipo ? tipo.value : "";
        }
    }

    function retornaDescricaoTomador(cte_tomador) {
        if (!!listaTomador) {
            let tomador = listaTomador.filter((toma) => (toma.key === cte_tomador))[0];
            return !!tomador ? tomador.value : "";
        }
    }

    function retornaDescricaoModal(cte_modal) {
        if (!!listaModal) {
            let modal = listaModal.filter((modal) => (modal.key === cte_modal))[0];
            return !!modal ? modal.value : "";
        }
    }

    function menuTableOutros(record) {
        return (
            <Menu className="text-right">
                <Menu.Item key="4">
                    <Button type="text" icon={<EyeOutlined />} onClick={() => { visualizarCte(record) }}> Visualizar </Button>
                </Menu.Item>
            </Menu>
        )
    }

    async function visualizarCte(record) {
        if (record.cte_id) {
            await api.get(`ConhecimentoTransporte/BuscarCte/${record.ntf_id}`).then(
                res => {
                    let retorno = res.data;
                    setDadosDetalhesCte({ ...retorno, ...record });
                    setOpenDetalhesCte(true);
                }
            ).catch(
                erro => {
                    console.log(erro);
                }
            )
        }
    }

    return (
        <div className="p-10">
            <div>
                <Row align="middle" justify="space-between" gutter={[8, 8]}>
                    <Col>
                        <BreadcrumbPage />
                    </Col>
                    <Col>
                        <Row align="middle" justify="end">
                            <Col>
                                <EmitirConhecimento size="middle" type="primary" onClick={() => {
                                    dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true, editItem: false } });
                                }} icon={<PlusOutlined />}>
                                    EMITIR
                                </EmitirConhecimento>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Form layout="vertical" form={formParam}>
                    <Row align="middle" gutter={[8, 0]}>
                        <Col xs={24} sm={17} md={17} lg={8} xl={8} xxl={8}>
                            <Form.Item label={"Pesquisar por"} name="filtroPor">
                                <Input.Search placeholder="Número, Chave.. " onSearch={valor => filtrosAvancados(valor)} allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={7} md={7} lg={4} xl={4} xxl={3}>
                            <ComboData form={form} eventoAoAlterar={() => {
                                form.setFieldsValue({ DataFinal: moment(new Date()) });
                                form.setFieldsValue({ DataInicial: moment(form.getFieldValue().dataPesquisa) });
                                form.setFieldsValue({ dataFinal: moment(new Date()) });
                                form.setFieldsValue({ dataInicial: moment(form.getFieldValue().dataPesquisa) });
                                filtrosAvancados();
                            }} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <Form.Item label="Status do Conhecimento de Transporte" name="status" initialValue={[0, 1, 2, 3, 4, 5, 6]}>
                                <Select
                                    onChange={() => filtrosAvancados()}
                                    mode="multiple"
                                    placeholder="Status CTe"
                                >
                                    {listaStatusNota.map((statusNota) => (
                                        <Select.Option key={statusNota.key} value={statusNota.key}>{statusNota.value}</Select.Option>
                                    ))}

                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div>
                <Form layout="vertical" form={form}>
                    <div className="page m-t-8 m-b-8">
                        <Row align="middle" gutter={[0, 8]}>
                            <Col span={24}>
                                <Collapse className="p-relative topDif" accordion ghost destroyInactivePanel={true}>
                                    <div className="colDet"></div>
                                    <Collapse.Panel key="1" showArrow={false} extra={
                                        <Button type="primary" onClick={togglePesquisa} block>
                                            {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                                        </Button>
                                    }>
                                        <Row gutter={[8, 0]}>
                                            <Col xs={24} sm={12} md={5} lg={3} xl={3}>
                                                <Data label="Data Inicial" name="dataInicial" />
                                            </Col>
                                            <Col xs={24} sm={12} md={5} lg={3} xl={3}>
                                                <Data label="Data Final" name="dataFinal" onBlur={() => filtrosAvancados()} />
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={5} className="t-mob t-5">
                                                <Row gutter={[8, 8]} justify="end" className="tt-5">
                                                    <Col xs={24} sm={12} md={6} lg={12} xl={12}>
                                                        <Button icon={<CloseOutlined />} block onClick={() => { form.resetFields() }}>
                                                            Remover Filtros
                                                        </Button>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={6} lg={12} xl={12}>
                                                        <Button type="primary" icon={<SearchOutlined />} block onClick={() => filtrosAvancados()}>
                                                            Aplicar Filtros
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Collapse.Panel>
                                </Collapse>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
            <div className="tabela">
                <TabelaDados url="ConhecimentoTransporte/Listar" colunas={[
                    {
                        title: 'N° CTe',
                        align: 'center',
                        width: 70,
                        render: ({ ntf_numero }) => (
                            <div>
                                <b>{ntf_numero}</b>
                            </div>
                        )
                    },
                    {
                        title: '',
                        width: 170,
                        render: ({ ntf_status, tip_descricao }) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 5]}>
                                    <Col span={10}>
                                        <Tag color={((ntf_status === 0) ? 'default' : (ntf_status === 1 ? 'green' : (ntf_status === 2 ? 'gold' : (ntf_status === 3 ? 'orange' : (ntf_status === 4 ? 'red' : (ntf_status === 5 ? 'lime' : ''))))))}>
                                            <b>
                                                {retornaDescricaoStatus(ntf_status)}
                                            </b>
                                        </Tag>
                                    </Col>
                                    <Col span={14}>
                                        <Tag color='processing'>
                                            <b>{tip_descricao}</b>
                                        </Tag>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Tipo',
                        width: 70,
                        align: 'center',
                        render: ({ cte_tipocte }) => (
                            <div>
                                <b>{retornaDescricaoTipoCte(cte_tipocte)}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Modal',
                        width: 100,
                        align: 'center',
                        render: ({ cte_modal }) => (
                            <div>
                                <b>{retornaDescricaoModal(cte_modal)}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Emissão',
                        width: 90,
                        render: ({ ntf_dataemissao }) => (
                            <div>
                                <b>{moment(ntf_dataemissao).format('DD/MM/YYYY HH:mm')}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Tomador',
                        width: 300,
                        render: ({ cte_tomador, cta_razaosocial }) => (
                            <div>
                                <b>{cta_razaosocial} ({retornaDescricaoTomador(cte_tomador)})</b>
                            </div>
                        )
                    },
                    {
                        title: 'Município Envio',
                        width: 200,
                        render: ({ descricao_munenvio, sigla_ufenvio }) => (
                            <div>
                                <b>{descricao_munenvio + " - " + sigla_ufenvio}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Município Final',
                        width: 200,
                        render: ({ descricao_munfinal, sigla_uffinal }) => (
                            <div>
                                <b>{descricao_munfinal + " - " + sigla_uffinal}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Valor (R$)',
                        align: 'right',
                        width: 100,
                        render: ({ valor_total }) => (
                            <div>
                                <b className="f-18">{FormatNumber(valor_total, true)}</b>
                            </div>
                        )

                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        fixed: 'right',
                        width: 65,
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Dropdown overlay={menuTableOutros(record)} trigger={['click']}>
                                            <Button icon={<EyeOutlined />} onClick={(e) => e.preventDefault()} />
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </div>
                        )
                    },
                ]} />
            </div>
            <DrawerConhecimentoTransporte />
            <ModalDetalhesCte exibirModal={openDetalhesCte} fecharModal={() => setOpenDetalhesCte(false)} dadosModal={dadosDetalhesCte} listaTipoCte={listaTipoCte} listaTomador={listaTomador}
                listaModal={listaModal} />
        </div>
    )
}