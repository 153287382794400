import React, { useState } from "react";
import { Button, Card, Col, Form, Modal, notification, Row, Upload } from "antd";

import { CloseOutlined, CloudUploadOutlined, DeleteOutlined, FileDoneOutlined } from "@ant-design/icons";
import { getBase64 } from "../../../services/funcoes";
import api from "../../../services/api";

export default function ModalDocsFiscaisCTe({ form, exibirModal, fecharModal, listaDocs, setListaDocs, arquivoBase64, setArquivoBase64, arquivo, setArquivo }) {
    const [carregando, setCarregando] = useState(false);
    const [atualizaBotao, setAtualizaBotao] = useState(false);
    const props = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        maxCount: 1,
        disabled: !!arquivo,
        beforeUpload(file) {
            getBase64(file).then(base64 => {
                setArquivoBase64(base64.split('base64,')[1]);
                setArquivo(file);
            });
        }
    }

    const onCloseModal = () => {
        form.resetFields();
        fecharModal();
        removerNota();
    };

    function removerNota() {
        setArquivoBase64("");
        setArquivo(null);
        setAtualizaBotao(true);
        form.setFieldsValue({
            cid_dataemissao: null,
            cid_peso: null,
            cid_valornf: null
        })
    }

    function salvarDocs() {
        setCarregando(true);
        api.post(`NotaFiscalEntrada/ImportarXML`, JSON.stringify(arquivoBase64), { headers: { 'Content-Type': 'application/json;charset=utf-8' } }).then(
            res => {
                let novaNota = { ...res.data };
                let notas = [...listaDocs];
                let liberado = verificaNotas(novaNota, notas);
                if (liberado) {
                    notas.push(novaNota);
                    setListaDocs(notas);
                    let dadosNota = [];
                    dadosNota.push({ nome: 'xmlBase64', valor: arquivoBase64 });
                    notification.success({ message: 'Atenção!', description: 'Nota vinculada com sucesso' });
                    fecharModal();
                    removerNota();
                } else if (!liberado) {
                    removerNota();
                    notification.warning({ message: 'Atenção!', description: 'Essa nota ja está vinculada ao CTe atual' });
                }
            }
        ).catch(
            erro => {
                notification.error({ description: 'Ocorreu um erro ao vincular a nota', message: 'Aviso!' });
                removerNota();
            }
        ).finally(
            () => {
                setCarregando(false);
            }
        )
    }

    function verificaNotas(novaNota, notas) {
        let chavesExistentes = notas.map((doc) => doc.cid_chave);
        let docsDuplicados = chavesExistentes.includes(novaNota.cid_chave);
        if (docsDuplicados) {
            return false;
        } else {
            return true;
        }
    }

    return (
        <div>
            <Modal centered
                confirmLoading={carregando}
                title="Importar Documento Fiscal"
                visible={exibirModal}
                onCancel={onCloseModal}
                width={480}
                onOk={salvarDocs}
                closable={false}
                okText={
                    <>
                        <FileDoneOutlined /> Vincular Documento
                    </>
                }
                cancelText={
                    <>
                        <CloseOutlined /> Cancelar
                    </>
                }
                maskClosable={false}
            >
                <div className="upload m-t-16">
                    <Form layout="vertical" name="formDocsFiscais" form={form}>
                        <Row gutter={[8, 0]} align="middle">
                            <Col span={24}>
                                <Upload {...props}>
                                    {!arquivo && <div>
                                        <Row align="middle" gutter={[0, 8]}>
                                            <Button className={`${atualizaBotao ? 'fade-in' : ''}`} type="primary" size="large" icon={<CloudUploadOutlined />} onClick={() => setAtualizaBotao(false)}>
                                                Importar XML
                                            </Button>
                                        </Row>
                                    </div>}
                                    <div className={`${arquivo ? 'fade-in' : ''}`}>
                                        {!!arquivo &&
                                            <Row align="middle" gutter={[8, 0]} >
                                                <Col span={24}>
                                                    <Card style={{ overflow: 'hidden', borderRadius: '10px' }}>
                                                        <Row align="middle" gutter={[8, 8]}>
                                                            <Col>
                                                                <svg
                                                                    t="1622205362360"
                                                                    class="icon"
                                                                    viewBox="0 0 1024 1024"
                                                                    version="1.1"
                                                                    xmlns="http://www.w3.org/2000/svg" p-id="2101" id="mx_n_1622205362361" data-spm-anchor-id="a313x.7781069.0.i7" width="40" height="40">
                                                                    <path
                                                                        d="M354.40128 0c-87.04 0-157.44 70.55872-157.44 157.59872v275.68128H78.72c-21.6576 0-39.36256 17.69984-39.36256 39.36256v236.31872c0 21.6576 17.69984 39.35744 39.36256 39.35744h118.24128v118.08256c0 87.04 70.4 157.59872 157.44 157.59872h472.63744c87.04 0 157.59872-70.55872 157.59872-157.59872V315.0336c0-41.74848-38.9888-81.93024-107.52-149.27872l-29.11744-29.12256L818.87744 107.52C751.5392 38.9888 711.39328 0 669.59872 0H354.4064z m0 78.72h287.20128c28.35456 7.0912 27.99616 42.1376 27.99616 76.8v120.16128c0 21.6576 17.69984 39.35744 39.36256 39.35744h118.07744c39.38816 0 78.87872-0.0256 78.87872 39.36256v512c0 43.32032-35.55328 78.87872-78.87872 78.87872H354.4064c-43.32544 0-78.72-35.5584-78.72-78.87872v-118.08256h393.91744c21.66272 0 39.36256-17.69472 39.36256-39.35744V472.64256c0-21.66272-17.69984-39.36256-39.36256-39.36256H275.68128V157.59872c0-43.32032 35.39456-78.87872 78.72-78.87872zM145.12128 507.36128h23.99744l39.36256 67.2 40.32-67.2h23.04l-50.88256 83.51744 54.72256 92.16h-24.96l-43.20256-75.83744-43.19744 75.83744h-23.04l54.71744-92.16-50.87744-83.51744z m154.55744 0h32.64l49.92 143.03744h0.96256l48.95744-143.03744h33.60256v175.67744h-22.08256v-106.55744c0-10.88 0.32256-26.56256 0.96256-47.04256h-0.96256l-52.79744 153.6h-19.2l-52.80256-153.6h-0.95744c1.28 22.4 1.92 38.72256 1.92 48.96256v104.63744H299.6736V507.36128z m214.08256 0h22.07744v155.52h69.12v20.15744h-91.19744V507.36128z" p-id="2102">
                                                                    </path>
                                                                </svg>
                                                            </Col>
                                                            <Col>
                                                                {arquivo.name}
                                                            </Col>
                                                            <Col>
                                                                <Button size="middle" icon={<DeleteOutlined />} onClick={() => { removerNota(); }} />
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        }
                                    </div>
                                </Upload>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </div>
    )
}